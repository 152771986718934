import { useCallback, useEffect, useState } from 'react';
import { ConfigObject } from '../_configs/types';
import { Car, Filter } from '../_types/Car';
import { checkUndefined } from '../utils/tools';

// ----------------------------------------------------------------------

const LOCAL_STORAGE_SETTINGS = 'hpt:storage';

type HptLocalStorageSettings = {
    allowMaps: boolean,
    auth: string | null,
    listMode: ConfigObject['fahrzeugsuche']['listView'],
};

export type HptSessionStorageSettings = {
    debug: boolean,
    lastViewedCar: Car<false>['id'] | null,
    search: Filter,
};

// ----------------------------------------------------------------------

export const getLocalStorage = <T>(key: string): T => {
    let value = null;

    try {
        const result = typeof window !== 'undefined' ? window.localStorage.getItem(key) : undefined;

        if (!checkUndefined(result)) {
            value = JSON.parse(result);
        }
    } catch (error) {
        console.error(error);
    }

    return value;
};

export const setLocalStorage = <T>(key: string, value: T) => {
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error(error);
    }
};

export const removeLocalStorage = (key: string) => {
    try {
        window.localStorage.removeItem(key);
    } catch (error) {
        console.error(error);
    }
};

// ----------------------------------------------------------------------

export const getSessionStorage = <T>(key: string): T => {
    let value = null;

    try {
        const result = typeof window !== 'undefined' ? window.sessionStorage.getItem(key) : undefined;

        if (!checkUndefined(result)) {
            value = JSON.parse(result);
        }
    } catch (error) {
        console.error(error);
    }

    return value;
};

export const setSessionStorage = <T>(key: string, value: T) => {
    try {
        window.sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error(error);
    }
};

export const removeSessionStorage = (key: string) => {
    try {
        window.sessionStorage.removeItem(key);
    } catch (error) {
        console.error(error);
    }
};

// ----------------------------------------------------------------------

const defaultLocalStorage: HptLocalStorageSettings = { allowMaps: false, auth: null, listMode: 'grid' };

export function useLocalStorage<F extends keyof HptLocalStorageSettings>(field: F) {
    const [state, setState] = useState<HptLocalStorageSettings>(defaultLocalStorage);

    useEffect(() => {
        const restored = getLocalStorage<HptLocalStorageSettings>(LOCAL_STORAGE_SETTINGS);

        if (restored) {
            setState(() => restored);
        }
    }, []);

    const updateState = (updateValue: Partial<HptLocalStorageSettings>) => {
        setState((prevValue: HptLocalStorageSettings) => {
            const newSettings = {
                ...prevValue,
                ...updateValue
            };
            setLocalStorage<HptLocalStorageSettings>(LOCAL_STORAGE_SETTINGS, newSettings);
            return newSettings;
        });
    };

    const update = useCallback(
        (updateValue: HptLocalStorageSettings[F]) => {
            updateState({
                [field]: updateValue || null,
            });
        },
        [field],
    );

    return {
        state: state?.[field],
        update,
    };
}


// ----------------------------------------------------------------------

const defaultSessionStorage: HptSessionStorageSettings = { debug: false, lastViewedCar: null, search: null };

export function useSessionStorage<F extends keyof HptSessionStorageSettings>(field: F) {
    const [state, setState] = useState<HptSessionStorageSettings>(defaultSessionStorage);

    useEffect(() => {
        const restored = getSessionStorage<HptSessionStorageSettings>(LOCAL_STORAGE_SETTINGS);

        if (restored) {
            setState(() => restored);
        }
    }, []);

    const updateState = (updateValue: Partial<HptSessionStorageSettings>) => {
        setState((prevValue: HptSessionStorageSettings) => {
            const newSettings = {
                ...prevValue,
                ...updateValue
            };
            setSessionStorage<HptSessionStorageSettings>(LOCAL_STORAGE_SETTINGS, newSettings);
            return newSettings;
        });
    };

    const update = useCallback(
        (updateValue: HptSessionStorageSettings[F]) => {
            updateState({
                [field]: updateValue || null,
            });
        },
        [field],
    );

    return {
        state: state?.[field],
        update,
    };
}

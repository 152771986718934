import { Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function checkbox(theme: Theme): Components<Theme> {
    return {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        'padding': theme.spacing(1),
                        '& > input.PrivateSwitchBase-input': {
                            opacity: '0 !important',
                        },
                    },
                },
            },
        },
    };
}

/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { getStyleQuery } from '../../utils/tools';

const configSchenkel: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54615,
        enable: true,
        projectName: 'Autohaus Schenkel',
        searchPath: '/fahrzeugsuche',
        showroooom: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: false,
        buttons: {
            ...configDefault.contact.buttons,
            offsetX: 3,
            offsetY: 13,
        },
    },
    api: {
        ...configDefault.api,
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    style: {
        ...configDefault.style,
        fontFamily: '"__Nunito_Sans_63b6cf", "__Nunito_Sans_Fallback_63b6cf", "Helvetica", "Arial", "sans-serif"',
        muiComponents: () => ({
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        [getStyleQuery()]: {
                            '& > legend > span': {
                                fontSize: '1.05em !important',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h1: {
                [getStyleQuery()]: {
                    fontWeight: 900,
                },
            },
            h2: {
                [getStyleQuery()]: {
                    fontWeight: 900,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontWeight: 900,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontWeight: 900,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontWeight: 900,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontWeight: 900,
                },
            },
        }),
        primaryColor: '#002c48',
    },
};

export default configSchenkel;

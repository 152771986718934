import { createSlice, SliceCaseReducers, SliceSelectors } from '@reduxjs/toolkit';
import { merge } from 'lodash-es';
import { checkUndefinedNullOrEmpty } from '../../utils/tools';
import { FluxStoreCommonContent } from '../types';

const commonSlice = createSlice<FluxStoreCommonContent, SliceCaseReducers<FluxStoreCommonContent>, 'common', SliceSelectors<FluxStoreCommonContent>>({
    name: 'common',
    initialState: { allFacets: undefined, settings: undefined, rootContainer: undefined },
    reducers: {
        setAllFacets(state, action) {
            if (!checkUndefinedNullOrEmpty(action.payload)) {
                state.allFacets = action.payload;
            }
        },
        setRootContainer(state, action) {
            if (!checkUndefinedNullOrEmpty(action.payload)) {
                state.rootContainer = action.payload;
            }
        },
        setSettings(state, action) {
            if (!checkUndefinedNullOrEmpty(action.payload)) {
                // Merge muss bleiben, da sich die Module sonst gegenseitig überschreiben!
                state.settings = merge(state.settings || {}, action.payload);
            }
        },
    },
});

export const actions = commonSlice.actions;
export const reducer = commonSlice.reducer;

/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configFeddersen: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 21700,
        enable: true,
        projectName: 'Feddersen Automobile',
        searchPath: '/gebrauchtwagen',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
    },
    module: {
        ...configDefault.module,
        erweiterteSchnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'standort', 'preis', 'rate', 'ez', 'aus'],
        },
        topFilter: true,
        tradeInLink: 'https://gib-uns-deinen-gebrauchten.de/feddersen',
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Abel", Helvetica, Arial, Lucida, sans-serif',
        fontSize: 16,
        scrollOffset: 100,
        muiComponents: (theme) => ({
            MuiCssBaseline: {
                styleOverrides: { // fix slow scrolling
                    'html, body': {
                        scrollBehavior: 'unset',
                    },
                    [getStyleQuery()]: {
                        '*': {
                            scrollBehavior: 'unset',
                        },
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                backgroundColor: '#7f9abb',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                defaultProps: {
                    disableRipple: true,
                    disableFocusRipple: true,
                    disableTouchRipple: true,
                },
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'transition': 'none',
                            '&:hover': {
                                transform: 'none',
                            },
                        },
                    },
                    containedPrimary: {
                        [getStyleQuery()]: {
                            'boxShadow': '0px 3px 0px 0px <colorSecondary>',
                            '&:hover': {
                                backgroundColor: '#7f9abb',
                                color: '#fff',
                            },
                        },
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            'backgroundColor': '<colorSecondary>',
                            'boxShadow': '0px 3px 0px 0px <colorPrimary>',
                            'color': '#fff !important',
                            // contact buttons next to fabs
                            '&[data-testid="UseContact_ContactTextButton_0"], &[data-testid="UseContact_ContactTextButton_20"], &[data-testid="UseContact_ContactTextButton_27"], &[data-testid="UseContact_ContactTextButton_tel"]': {
                                padding: '6px 8px',
                                fontSize: theme?.typography?.caption?.fontSize,
                            },
                            '&:hover': {
                                backgroundColor: '#7f9abb',
                                boxShadow: '0px 3px 0px 0px <colorSecondary>',
                                color: '#fff',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'backgroundColor': '<colorPrimary>',
                            'opacity': 1,
                            '&:hover': {
                                backgroundColor: '#7f9abb',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                },
            },
            body2: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                },
            },
            caption: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    paddingBottom: 0,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    color: '<colorPrimary>',
                    paddingBottom: 0,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    color: '<colorPrimary>',
                    paddingBottom: 0,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    color: '<colorPrimary>',
                    paddingBottom: 0,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    color: '<colorPrimary>',
                    paddingBottom: 0,
                },
            },
            overline: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                },
            },
            subtitle1: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    color: '<colorPrimary>',
                    paddingBottom: 0,
                },
            },
        }),
        primaryColor: '#00263e',
        secondaryColor: '#007cc9',
        // hover buttons: 7f9abb
    },
};

export default configFeddersen;

import { Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function timeline(theme: Theme): Components<Theme> {
    return {
        // @ts-expect-error ---
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiTimelineConnector: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        backgroundColor: theme.palette.divider,
                    },
                },
            },
        },
    };
}

/**
 * @file Handles all configurations for the homepage tools.
 * @author Daniel Rittrich
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configWalterSchneider: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54613,
        enable: true,
        projectName: 'Walter Schneider',
        searchPath: '/fahrzeuge/fahrzeugsuche',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutzerklaerung',
        buttons: {
            ...configDefault.contact.buttons,
            position: 'left',
        },
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'km', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus', 'tags'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'standort', 'km', 'preis', 'rate', 'ez', 'aus', 'tags'],
        },
        topFilter: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0.5,
        fontFamily: '"Montserrat"',
        fontSize: 14,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    'div.UseContact_ContactTextButtonContainer': { // anti-transparency box around contact buttons
                        borderRadius: '4px',
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        '&[data-testid^="UseContact_ContactButton_"]': {
                            'color': '#fff !important',
                            '& > svg': {
                                height: '21px !important',
                                width: '21px !important',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'webkitBoxShadow': '0 2px 2px 0 rgb(0 0 0 / 14%),0 1px 5px 0 rgb(0 0 0 / 12%),0 3px 1px -2px rgb(0 0 0 / 20%)',
                            'boxShadow': '0 2px 2px 0 rgb(0 0 0 / 14%),0 1px 5px 0 rgb(0 0 0 / 12%),0 3px 1px -2px rgb(0 0 0 / 20%)',
                            'border': '1px solid #fff',
                            'color': '#fff !important',
                            '&:hover': {
                                backgroundColor: '<colorSecondary> !important',
                                color: '#fff !important',
                            },
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            backgroundColor: '<colorSecondary>',
                            color: '#fff !important',
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                color: '<colorPrimary> !important',
                            },
                        },
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            background: 'transparent',
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'borderRadius': '50% !important',
                            'color': '#fff !important',
                            '& > svg': {
                                height: '21px',
                                width: '21px',
                            },
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'borderRadius': '50% !important',
                            'boxShadow': 'none',
                            '&:focus': {
                                outline: 'none',
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.FahrzeugSucheFilterSort_Container > button.MuiButton-text': {
                                textWrap: 'nowrap !important',
                            },
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid^="carTile_"]:hover': { // fix colored icons on hover
                                color: 'rgb(33, 43, 54) !important',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    color: '#333',
                    fontWeight: 500,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '<fontFamily>',
                    fontWeight: 700,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '<fontFamily>',
                    fontWeight: 700,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '<fontFamily>',
                    fontWeight: 700,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '<fontFamily>',
                    fontWeight: 700,
                },
            },
        }),
        primaryColor: '#132531',
        secondaryColor: '#9fb3b3',
    },
};

export default configWalterSchneider;

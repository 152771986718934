import { Components, Theme, alpha } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function paper(theme: Theme): Components<Theme> {
    return {
        MuiPaper: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        backgroundImage: 'none',
                    },
                },
                outlined: {
                    [getStyleQuery()]: {
                        borderColor: alpha(theme.palette.grey[500], 0.16),
                    },
                },
            },
        },
    };
}

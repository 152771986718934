import { Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

export function input(theme: Theme): Components<Theme> {
    return {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        '& > fieldset': {
                            margin: '0 !important',
                        },
                    },
                },
                input: {
                    [getStyleQuery()]: {
                        'border': 'none !important',
                        'boxShadow': 'none !important',
                        'color': theme?.palette?.text?.primary,
                        'fontSize': theme?.typography?.body1?.fontSize,
                        'margin': '0 !important',
                        'padding': '16.5px 14px !important',
                        'textAlign': 'left',
                        '&:-webkit-autofill, &:-webkit-autofill:active, &:-webkit-autofill:focus, &:-webkit-autofill:hover': {
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: theme?.palette?.text?.primary,
                            transition: 'background-color 5000s ease-in-out 0s',
                            boxShadow: 'inset 0 0 20px 20px #23232329',
                        },
                        '&:active, &:focus, &:hover': {
                            border: 'none',
                            boxShadow: 'none',
                        },
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        fontSize: theme.typography.body1.fontSize,
                    },
                },
            },
        },
    };
}

/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configBest: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30180,
        isGruppe: true,
        enable: true,
        projectName: 'Best',
        searchPath: '/best/fahrzeugarchiv',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/best/datenschutz',
    },
    module: {
        ...configDefault.module,
        erweiterteSchnellsuche: true,
        finanzierungsansicht: true,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'standort', 'preis', 'rate', 'ez', 'aus'],
        },
        titleWithDesign: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Lato", "Open Sans", "Arial", sans-serif',
        fontSize: 16,
        primaryColor: '#eeb312',
    },
};

export default configBest;

/**
 * @file Handles all configurations for the homepage tools.
 * @author Arne Rief
 */

import { breakpoints } from '../../theme/breakpoints';
import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const AVEMO_FILTERS = {
    akttypList: [1], // Neuwagen
    ignoreStandort: [
        85630,
        85652,
        85669,
        85722,
        85729,
        85668,
        85723,
        85671,
        85670,
        85631,
        85653,
        85643,
        85672,
        85632,
        85697,
        85666,
        85719,
        85667,
        85705,
        85704,
        85712,
        85700,
        85701,
        85628,
        85675,
        85674,
        85738,
        85676,
        85698,
        85707,
        85636,
        85654,
        85711,
        85699,
        85710,
        85732,
        85733,
        85730,
        85731,
        85736,
        85737,
        85673,
        85734,
        85735,
        85637,
        85638,
        85639,
        85656,
        85709,
        85728,
        85641,
        85642,
        85663,
        85664,
        85651,
        85660,
        85665,
        85691,
        85695,
        85657,
        85694,
        85662,
        85693,
        85715,
        85716,
        85688,
        85692,
        85690,
        85647,
        85713,
        85714,
        85629,
        85727,
        85725,
        85726,
        85739,
        85702,
        85644,
        85645,
        85681,
        85682,
        86176,
        85659,
        85708,
        85655,
        85679,
        85678,
        85680,
        85646,
        85696,
        85703,
        85683,
        85685,
        85706,
    ],
    nfzHersteller: 0, // remove VW NFZ from "Hersteller" filter options, because cars are nfz: false
    NFZ: false,
    sofort: true,
};

const configAvemoFleet: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30179,
        isGruppe: true,
        enable: true,
        projectName: 'AVEMO Fleet',
        searchPath: '/fahrzeugsuche',
        showFinanzierungen: false,
        showLeasing: false,
        showUpeWithTransferInsteadOfVk: true,
        showNettoUpeWithTransferInsteadOfVkn: true,
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            offsetY: 9,
        },
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
    },
    api: {
        ...configDefault.api,
        tracking: {
            ...configDefault.api.tracking,
            enabled: false,
        },
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugliste: {
        ...configDefault.fahrzeugliste,
        forcedFilter: {
            ...configDefault.fahrzeugliste.forcedFilter,
            ...AVEMO_FILTERS,
        },
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        customNameSofort: 'Schnell verfügbar', // overwrite "Sofort verfügbar" filter text
        filter: {
            sidebar: ['hersteller', 'modell', 'aufbau', 'standort', 'km', 'preis', 'ez', 'motor', 'getriebe', 'aus', 'tags'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]aufbau', 'motor', 'getriebe', 'standort', 'km', 'preis', 'ez', 'aus', 'tags'],
        },
        firstImageIndex: 2,
        forcedFilter: {
            ...configDefault.fahrzeugsuche.forcedFilter,
            ...AVEMO_FILTERS,
        },
        titleWithDesign: true,
        idExposee: 1700,
    },
    fahrzeugdetail: {
        ...configDefault.fahrzeugdetail,
        similarCarsFilter: { ...AVEMO_FILTERS },
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"AVEMO", sans-serif',
        fontSize: 18,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    '.ahdImageWrapper.lazy-load-image-background.blur.lazy-load-image-loaded': {
                        filter: 'blur(0) !important', // fix blurred images
                    },
                    'div.ahdContainer#ahdFahrzeugsuche': {
                        width: '100vw !important',
                    },
                    'div.UseContact_ContactTextButtonContainer': { // anti-transparency box around contact buttons
                        borderRadius: '50px !important',
                    },
                    [`@media (max-width: ${breakpoints.md}px)`]: {
                        'div.ahdContainer#ahdFahrzeugsuche': { // fix width & height oversized small screens
                            maxWidth: '90vw',
                        },
                        'div.brlbs-cmpnt-widget.brlbs-bottom-0.brlbs-left-0': { // cookies button
                            bottom: '50px',
                        },
                    },
                    [`@media (min-width: ${breakpoints.lg}px)`]: { // fix width & height oversized large screens
                        'div.ahdContainer#ahdFahrzeugsuche': {
                            maxWidth: '80vw',
                        },
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'color': '#fff',
                            'fontWeight': 600,
                            'transition': 'all 0.2s ease-in',
                            '&:hover': {
                                boxShadow: '0 8px 9px rgb(22, 21, 20, 25%)',
                                transform: 'translateY(-5px)',
                            },
                            '& > svg': {
                                height: '21px',
                                width: '21px',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'color': '#fff',
                            'borderRadius': '50px',
                            'padding': '1rem 2.8rem',
                            'transition': 'all 0.2s ease-in',
                            '&:hover': {
                                backgroundColor: '<colorPrimary>',
                                boxShadow: '0 8px 9px rgb(22, 21, 20, 25%)',
                                color: '#fff',
                                transform: 'translateY(-5px)',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            borderRadius: '50px',
                            padding: '1rem 2.8rem',
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            'background': '#000',
                            'color': '#fff !important',
                            'borderRadius': '50px',
                            'padding': '1rem 2.8rem',
                            'transition': 'all 0.2s ease-in',
                            '&:hover': {
                                background: '#000',
                                boxShadow: '0 8px 9px rgb(22, 21, 20, 25%)',
                                transform: 'translateY(-5px)',
                            },
                            // contact buttons next to fabs
                            '&[data-testid^="UseContact_ContactTextButton_"]': {
                                'background': 'rgba(75, 180, 230, 0.16)',
                                'color': '<colorPrimary> !important',
                                'fontSize': '14px',
                                'padding': '6px 8px',
                                '&:hover': {
                                    background: 'rgba(75, 180, 230, 0.32)',
                                    boxShadow: '0 8px 16px 0 rgba(75, 180, 230, 0.24)',
                                    transform: 'unset !important',
                                },
                            },
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            borderRadius: '50px',
                        },
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            borderRadius: '50px',
                        },
                    },
                    labelSmall: {
                        [getStyleQuery()]: {
                            fontSize: '16px',
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& > svg': {
                                height: '21px',
                                width: '21px',
                            },
                        },
                    },
                },
            },
            MuiListItemText: {
                styleOverrides: {
                    secondary: {
                        [getStyleQuery()]: {
                            '&[data-testid^="carTile_"]': { // fix Car Title + Description overflow showing in Cards
                                height: 'unset !important',
                                // -webkit-line-clamp: unset ----> for full Car Title + Description in Cards
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& div.MuiInputBase-root.MuiOutlinedInput-root': { // filter inputs
                                borderRadius: '50px',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            button: {
                [getStyleQuery()]: {
                    fontWeight: 600,
                    lineHeight: '27px !important',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontWeight: 600,
                    lineHeight: '1.3em',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontWeight: 600,
                    lineHeight: '1.3em',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontWeight: 600,
                    lineHeight: '1.3em',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontWeight: 600,
                    lineHeight: '1.3em',
                },
            },
        }),
        primaryColor: '#4bb4e6',
    },
};

export default configAvemoFleet;

import configDefault from './_defaultValues';
import configLocal from './_local';
import configLocalSr from './_localSr';
import configTest from './_test';
import configPerski from './kunden/10190';
import configNextCamper from './kunden/10200';
import configNextCamperMietenKaufen from './kunden/10200_kaufenmieten';
import configSpranger from './kunden/10210';
import configWeingartner from './kunden/10370';
import configAudiZwickau from './kunden/10930';
import configPirna from './kunden/10970';
import configVollmer from './kunden/11440';
import configManz from './kunden/11910';
import configMeinhold from './kunden/11970';
import configWittenberg from './kunden/11980';
import configWerner from './kunden/12040';
import configAMB from './kunden/12290';
import configGroeditzer from './kunden/13540';
import configLiliensiek from './kunden/13890';
import configFeddersen from './kunden/21700';
import configDieAutohausGruppeSR from './kunden/30008_SR';
import configOttoGrimm from './kunden/30009';
import configSchuler from './kunden/30046';
import configCampingSchuler from './kunden/30046_camping';
import configRudolph from './kunden/30150';
import configKuhnWitte from './kunden/30156';
import configHenke from './kunden/30156_Henke';
import configACC from './kunden/30159';
import configFugel from './kunden/30161';
import configDiehl from './kunden/30165';
import configBlockAmRing from './kunden/30167';
import configHofmann from './kunden/30169';
import configGlockner from './kunden/30170';
import configElmshorn from './kunden/30173';
import configDabag from './kunden/30174';
import configWoldegk from './kunden/30175';
import configThomasHeavyMetal from './kunden/30177';
import configNord from './kunden/30178';
import configAvemo from './kunden/30179';
import configAvemoFleet from './kunden/30179_fleet';
import configBest from './kunden/30180';
import configBestSR from './kunden/30180_SR';
import configThomasExclusive from './kunden/30181';
import configASS from './kunden/36189';
import configKießling from './kunden/36883';
import configLeoBelting from './kunden/46950';
import configGebrConrad from './kunden/48731';
import configNeumann from './kunden/48801';
import configSchulzStraube from './kunden/48921';
import configFranke from './kunden/49252';
import configWeber from './kunden/49612';
import configFeicht from './kunden/50860';
import configIhle from './kunden/51410';
import configSalzmann from './kunden/51440';
import configBoxhammer from './kunden/51460';
import configGrampp from './kunden/51530';
import configAlbertsmeyer from './kunden/52400';
import configErlenhoff from './kunden/52590';
import configBurgenland from './kunden/52960';
import configDippel from './kunden/53040';
import configArnold from './kunden/53180';
import configThomasExclusiveAstonMartin from './kunden/53410_astonMartin';
import configHoch from './kunden/53420';
import configBaumhoff from './kunden/53670';
import configFSN from './kunden/53760';
import configMeyer from './kunden/53770';
import configAvBeier from './kunden/53850';
import configWolf from './kunden/53860';
import configLamminger from './kunden/53890';
import configHoppe from './kunden/53910';
import configHoppeTestDomain from './kunden/53910_testDomain';
import configKoch from './kunden/53920';
import configSturm from './kunden/54040';
import configReim from './kunden/54090';
import configMueller from './kunden/54100';
import configDippoldiswalde from './kunden/54130';
import configElbtor from './kunden/54150';
import configEichsfelder from './kunden/54170';
import configBaumann from './kunden/54320';
import configSchaeferAutomobile from './kunden/54330';
import configRange from './kunden/54360';
import configSpindler from './kunden/54390';
import configBrass from './kunden/54440';
import configJosefGeers from './kunden/54565';
import configTiemeyer from './kunden/54587';
import configFrankenthal from './kunden/54599';
import configLich from './kunden/54600';
import configBadermainzl from './kunden/54610';
import configHoppmann from './kunden/54612';
import configWalterSchneider from './kunden/54613';
import configSchenkel from './kunden/54615';
import configMarnet from './kunden/54619';
import configKaesmann from './kunden/54622';
import configFink from './kunden/54623';
import configKuenzigBleuel from './kunden/54624';
import configKuenzigBleuelHyundai from './kunden/54624_hyundai';
import configZeilinger from './kunden/54625';
import configOutletCars from './kunden/54630';
import configSchmeink from './kunden/54632';
import configSchmeinkDev from './kunden/54632-dev';
import configNiggemeier from './kunden/54778';
import configAZF from './kunden/54781';
import configSteinhoff from './kunden/54782';
import configVogt from './kunden/54783';
import configVoelkel from './kunden/54785';
import configVoelkel_Dev from './kunden/54785_dev';
import configRabus from './kunden/54789';
import { ConfigObject } from './types';

export type ConfigHostsEntry = {
    config: ConfigObject,
    hideInListing: boolean,
    hosts: string[],
    isDev: boolean,
    name: string,
};

export const configHosts: ConfigHostsEntry[] = [
    { name: 'Local HPT Server', hosts: ['localhost'], isDev: true, hideInListing: true, config: configRabus },
    { name: 'ACC', hosts: ['acc-dev.autohaus-digital.de', 'acc-live.autohaus-digital.de', 'acc-next.autohaus-digital.de', 'acc-pre-release.autohaus-digital.de', 'acc-test.autohaus-digital.de', 'autocarl.de'], isDev: false, hideInListing: false, config: configACC },
    { name: 'Albertsmeyer', hosts: ['albertsmeyer-dev.autohaus-digital.de', 'albertsmeyer-live.autohaus-digital.de', 'albertsmeyer-pre-release.autohaus-digital.de', 'albertsmeyer-test.autohaus-digital.de', 'albertsmeyer-next.autohaus-digital.de'], isDev: false, hideInListing: false, config: configAlbertsmeyer },
    { name: 'AMB', hosts: ['amb-autowelt.de'], isDev: false, hideInListing: false, config: configAMB },
    { name: 'ASS Senftenberg', hosts: ['ass-dev.autohaus-digital.de', 'ass-live.autohaus-digital.de', 'ass-pre-release.autohaus-digital.de', 'ass-test.autohaus-digital.de', 'ass-next.autohaus-digital.de', 'ass-senftenberg.de'], isDev: false, hideInListing: false, config: configASS},
    { name: 'Auto Diehl', hosts: ['diehl-dev.autohaus-digital.de', 'diehl-live.autohaus-digital.de', 'diehl-pre-release.autohaus-digital.de', 'diehl-test.autohaus-digital.de', 'diehl-next.autohaus-digital.de'], isDev: false, hideInListing: false, config: configDiehl },
    { name: 'Autohaus-Digital HPT', hosts: ['hpt-dev.autohaus-digital.de', 'hpt-next.autohaus-digital.de', 'hpt-pre-release.autohaus-digital.de', 'hpt-test.autohaus-digital.de', 'hpt.autohaus-digital.de'], isDev: true, hideInListing: true, config: configLocal },
    {
        name: 'Autohaus-Digital S-R', hosts: [
            'showroooom-dev.autohaus-digital.de',
            'showroooom-next.autohaus-digital.de',
            'showroooom-pre-release.autohaus-digital.de',
            'showroooom-test.autohaus-digital.de',
            'showroooom.autohaus-digital.de',
            'ahd-dev.autohaus-digital.de',
            'ahd-next.autohaus-digital.de',
            'ahd-pre-release.autohaus-digital.de',
            'ahd-test.autohaus-digital.de',
            'ahd.autohaus-digital.de',
            'ahddemo-dev.autohaus-digital.de',
            'ahddemo-next.autohaus-digital.de',
            'ahddemo-pre-release.autohaus-digital.de',
            'ahddemo-test.autohaus-digital.de',
            'ahddemo.autohaus-digital.de',
        ], isDev: true, hideInListing: true, config: configLocalSr,
    },
    { name: 'Audi Zentrum Zwickau', hosts: ['az-zwickau.de', 'zwickau-dev.autohaus-digital.de', 'zwickau-live.autohaus-digital.de', 'zwickau-next.autohaus-digital.de', 'zwickau-pre-release.autohaus-digital.de', 'zwickau-test.autohaus-digital.de'], isDev: false, hideInListing: false, config: configAudiZwickau },
    { name: 'Autoagentur Wolf', hosts: ['wolf-dev.autohaus-digital.de', 'wolf-live.autohaus-digital.de', 'wolf-next.autohaus-digital.de', 'wolf-pre-release.autohaus-digital.de', 'wolf-test.autohaus-digital.de', 'autoagentur-wolf.de'], isDev: false, hideInListing: false, config: configWolf },
    { name: 'Autocenter Burgenland', hosts: ['ac-burgenland-dev.autohaus-digital.de', 'burgenland.autohaus-digital.de', 'ac-burgenland-next.autohaus-digital.de', 'ac-burgenland-pre-release.autohaus-digital.de', 'ac-burgenland-test.autohaus-digital.de'], isDev: false, hideInListing: false, config: configBurgenland },
    { name: 'Autohaus Baumann', hosts: ['autohaus-baumann.de'], isDev: false, hideInListing: false, config: configBaumann },
    { name: 'Autohaus Baumhoff', hosts: ['autohaus-baumhoff.de'], isDev: false, hideInListing: false, config: configBaumhoff },
    { name: 'Autohaus Dippoldiswalde S-R', hosts: ['autohaus-dippoldiswalde.de', 'dippoldiswalde-dev.autohaus-digital.de', 'dippoldiswalde-live.autohaus-digital.de', 'dippoldiswalde-next.autohaus-digital.de', 'dippoldiswalde-pre-release.autohaus-digital.de', 'dippoldiswalde-test.autohaus-digital.de'], isDev: false, hideInListing: false, config: configDippoldiswalde },
    { name: 'Autohaus Elmshorn S-R', hosts: ['autohaus-elmshorn.de', 'elmshorn-dev.autohaus-digital.de', 'elmshorn-live.autohaus-digital.de', 'elmshorn-next.autohaus-digital.de', 'elmshorn-pre-release.autohaus-digital.de', 'elmshorn-test.autohaus-digital.de'], isDev: false, hideInListing: false, config: configElmshorn },
    { name: 'Autohaus Erlenhoff', hosts: ['erlenhoff-dev.autohaus-digital.de', 'erlenhoff-live.autohaus-digital.de', 'erlenhoff-next.autohaus-digital.de', 'erlenhoff-pre-release.autohaus-digital.de', 'erlenhoff-test.autohaus-digital.de', 'erlenhoff.de'], isDev: false, hideInListing: false, config: configErlenhoff },
    { name: 'Autohaus Feicht', hosts: ['feicht.de'], isDev: false, hideInListing: false, config: configFeicht },
    { name: 'Autohaus Franke', hosts: ['autohaus-franke.com'], isDev: false, hideInListing: false, config: configFranke },
    { name: 'Autohaus G. Neumann', hosts: ['autohaus-neumann.de'], isDev: false, hideInListing: false, config: configNeumann },
    { name: 'Autohaus Hoch', hosts: ['autohaus-hoch.de'], isDev: false, hideInListing: false, config: configHoch },
    { name: 'Autohaus Hoppe', hosts: ['autohaus-hoppe.com'], isDev: false, hideInListing: false, config: configHoppe },
    { name: 'Autohaus Hoppe - Test Domain', hosts: ['em26171.vautron05.eventomaxx.de'], isDev: false, hideInListing: false, config: configHoppeTestDomain },
    { name: 'Autohaus Ihle', hosts: ['ihle-nortorf-dev.autohaus-digital.de', 'ihle-nortorf-live.autohaus-digital.de', 'ihle-nortorf-next.autohaus-digital.de', 'ihle-nortorf-pre-release.autohaus-digital.de', 'ihle-nortorf-test.autohaus-digital.de'], isDev: false, hideInListing: false, config: configIhle },
    { name: 'Autohaus Josef Geers', hosts: ['autohaus-geers.de'], isDev: false, hideInListing: false, config: configJosefGeers },
    { name: 'Autohaus Kießling', hosts: ['kiessling-zwickau.de'], isDev: false, hideInListing: false, config: configKießling },
    { name: 'Autohaus Koch', hosts: ['autohauskoch.com'], isDev: false, hideInListing: false, config: configKoch },
    { name: 'Autohaus Kuhn+Witte', hosts: ['autohaus.kuhn-witte.de'], isDev: false, hideInListing: false, config: configKuhnWitte },
    { name: 'Autohaus Lamminger', hosts: ['lamminger-dev.autohaus-digital.de', 'lamminger-live.autohaus-digital.de', 'lamminger-next.autohaus-digital.de', 'lamminger-pre-release.autohaus-digital.de', 'lamminger-test.autohaus-digital.de', 'autohaus-lamminger.de'], isDev: false, hideInListing: false, config: configLamminger },
    { name: 'Autohaus Lich', hosts: ['lich-dev.autohaus-digital.de', 'lich-live.autohaus-digital.de', 'lich-next.autohaus-digital.de', 'lich-pre-release.autohaus-digital.de', 'lich-test.autohaus-digital.de', 'autohaus-lich.de'], isDev: false, hideInListing: false, config: configLich },
    { name: 'Autohaus Manz', hosts: ['autohaus-manz.de'], isDev: false, hideInListing: false, config: configManz },
    { name: 'Autohaus Meinhold', hosts: ['autohaus-meinhold.de'], isDev: false, hideInListing: false, config: configMeinhold },
    { name: 'Autohaus Niggemeier', hosts: ['niggemeier.de'], isDev: false, hideInListing: false, config: configNiggemeier },
    { name: 'Autohaus Nord', hosts: ['guestrow-dev.autohaus-digital.de', 'guestrow-live.autohaus-digital.de', 'guestrow-next.autohaus-digital.de', 'guestrow-pre-release.autohaus-digital.de', 'guestrow-test.autohaus-digital.de', 'auto-guestrow.de'], isDev: false, hideInListing: false, config: configNord },
    { name: 'Autohaus Perski', hosts: ['autohaus-perski.de'], isDev: false, hideInListing: false, config: configPerski },
    { name: 'Autohaus Pirna', hosts: ['autohauspirna.de'], isDev: false, hideInListing: false, config: configPirna },
    { name: 'Autohaus Rabus', hosts: ['autohaus-rabus.de'], isDev: false, hideInListing: false, config: configRabus },
    { name: 'Autohaus Range', hosts: ['autohaus-range.de'], isDev: false, hideInListing: false, config: configRange },
    { name: 'Autohaus Rudolph', hosts: ['seat-rudolph.com', 'vw-rudolph.com'], isDev: false, hideInListing: false, config: configRudolph },
    { name: 'Autohaus Schenkel', hosts: ['schenkel-dev.autohaus-digital.de', 'schenkel-live.autohaus-digital.de', 'schenkel-next.autohaus-digital.de', 'schenkel-pre-release.autohaus-digital.de', 'schenkel-test.autohaus-digital.de', 'autohaus-schenkel.de'], isDev: false, hideInListing: false, config: configSchenkel },
    { name: 'Autohaus Schulz & Straube', hosts: ['schulz-straube.de'], isDev: false, hideInListing: false, config: configSchulzStraube },
    { name: 'Autohaus Spranger', hosts: ['autohaus-spranger.de'], isDev: false, hideInListing: false, config: configSpranger },
    { name: 'Autohaus Steinhoff', hosts: ['autohaus-steinhoff.de', 'steinhoff-live.autohaus-digital.de', 'steinhoff-next.autohaus-digital.de', 'steinhoff-dev.autohaus-digital.de'], isDev: false, hideInListing: false, config: configSteinhoff },
    { name: 'Autohaus Völkel', hosts: ['autohaus-voelkel.de'], isDev: false, hideInListing: false, config: configVoelkel },
    { name: 'Autohaus Völkel-Dev', hosts: ['autohaus-voelkel.de/dev'], isDev: false, hideInListing: false, config: configVoelkel_Dev },
    { name: 'Autohaus Vollmer', hosts: ['autohaus-vollmer.de'], isDev: false, hideInListing: false, config: configVollmer },
    { name: 'Autohaus Weber', hosts: ['autos-weber.de', 'autos-weber.com'], isDev: false, hideInListing: false, config: configWeber },
    { name: 'Autohaus Weingärtner', hosts: ['weingaertner-dev.autohaus-digital.de', 'weingaertner-live.autohaus-digital.de', 'weingaertner-next.autohaus-digital.de', 'weingaertner-pre-release.autohaus-digital.de', 'weingaertner-test.autohaus-digital.de', 'autohaus-weingaertner.de'], isDev: false, hideInListing: false, config: configWeingartner },
    { name: 'Autohaus Wittenberg', hosts: ['ah-wittenberg.de'], isDev: false, hideInListing: false, config: configWittenberg },
    { name: 'Automobile Werner', hosts: ['automobile-werner.de'], isDev: false, hideInListing: false, config: configWerner },
    { name: 'Autopark Dippel', hosts: ['autopark-dippel.de'], isDev: false, hideInListing: false, config: configDippel },
    { name: 'Autowelt Schuler', hosts: ['autowelt-schuler.de'], isDev: false, hideInListing: false, config: configSchuler },
    { name: 'Autozentrale Sturm', hosts: ['autozentrale-sturm.de'], isDev: false, hideInListing: false, config: configSturm },
    { name: 'Autozentrum Frankenthal', hosts: ['autozentrum-frankenthal.de'], isDev: false, hideInListing: false, config: configFrankenthal },
    { name: 'Auto Brass', hosts: ['auto-brass.com'], isDev: false, hideInListing: false, config: configBrass },
    { name: 'Auto Hofmann', hosts: ['auto-hofmann.de'], isDev: false, hideInListing: false, config: configHofmann },
    { name: 'Auto Meyer', hosts: ['automeyer-ochsenfurt.de', 'automeyer-dev.autohaus-digital.de', 'automeyer-live.autohaus-digital.de', 'automeyer-next.autohaus-digital.de', 'automeyer-pre-release.autohaus-digital.de', 'automeyer-test.autohaus-digital.de'], isDev: false, hideInListing: false, config: configMeyer },
    { name: 'Auto Müller', hosts: ['auto-mueller-online.de'], isDev: false, hideInListing: false, config: configMueller },
    { name: 'Auto Zeilinger', hosts: ['zeilinger-dev.autohaus-digital.de', 'zeilinger-live.autohaus-digital.de', 'zeilinger-next.autohaus-digital.de', 'zeilinger-pre-release.autohaus-digital.de', 'zeilinger-test.autohaus-digital.de', 'auto-zeilinger.de'], isDev: false, hideInListing: false, config: configZeilinger },
    { name: 'AV Beier', hosts: ['avbeier.de'], isDev: false, hideInListing: false, config: configAvBeier },
    { name: 'AVEMO Fleet', hosts: ['avemo-fleet.de'], isDev: false, hideInListing: false, config: configAvemoFleet },
    { name: 'AVEMO Gruppe', hosts: ['avemo-group.de'], isDev: false, hideInListing: false, config: configAvemo },
    { name: 'AZF Gruppe', hosts: ['azf-gruppe.de'], isDev: false, hideInListing: false, config: configAZF },
    { name: 'BaderMainzl', hosts: ['badermainzl.de', 'innsiders-projekte.de'], isDev: false, hideInListing: false, config: configBadermainzl },
    { name: 'Best', hosts: ['my-mobility.group'], isDev: false, hideInListing: false, config: configBest },
    { name: 'Best S-R', hosts: ['best-familie-dev.autohaus-digital.de', 'best-familie-live.autohaus-digital.de', 'best-familie-next.autohaus-digital.de', 'best-familie-pre-release.autohaus-digital.de', 'best-familie-test.autohaus-digital.de', 'autohaus-best.de'], isDev: false, hideInListing: false, config: configBestSR },
    { name: 'Block am Ring', hosts: ['blockamring.net'], isDev: false, hideInListing: false, config: configBlockAmRing },
    { name: 'Boxhammer', hosts: ['boxhammer.de'], isDev: false, hideInListing: false, config: configBoxhammer },
    { name: 'Campingwelt Schuler', hosts: ['campingwelt-schuler.de'], isDev: false, hideInListing: false, config: configCampingSchuler },
    { name: 'Dabag', hosts: ['dabag.de'], isDev: false, hideInListing: false, config: configDabag },
    { name: 'Dein Autozentrum Woldegk', hosts: ['woldegk-dev.autohaus-digital.de', 'woldegk-live.autohaus-digital.de', 'woldegk-next.autohaus-digital.de', 'woldegk-pre-release.autohaus-digital.de', 'woldegk-test.autohaus-digital.de', 'dein-gebrauchtwagen-zentrum.de'], isDev: false, hideInListing: false, config: configWoldegk },
    {
        name: 'Die Autohaus Gruppe S-R', hosts: [
            'die-autohaus-gruppe-dev.autohaus-digital.de',
            'die-autohaus-gruppe-live.autohaus-digital.de',
            'die-autohaus-gruppe-next.autohaus-digital.de',
            'die-autohaus-gruppe-pre-release.autohaus-digital.de',
            'die-autohaus-gruppe-test.autohaus-digital.de',
            'die-autohaus-gruppe.de',
            'wernigerode-dev.autohaus-digital.de',
            'wernigerode-live.autohaus-digital.de',
            'wernigerode-next.autohaus-digital.de',
            'wernigerode-pre-release.autohaus-digital.de',
            'wernigerode-test.autohaus-digital.de',
            'wernigerode.die-autohaus-gruppe.de',
            'autohaus-am-regenstein-dev.autohaus-digital.de',
            'autohaus-am-regenstein-live.autohaus-digital.de',
            'autohaus-am-regenstein-next.autohaus-digital.de',
            'autohaus-am-regenstein-pre-release.autohaus-digital.de',
            'autohaus-am-regenstein-test.autohaus-digital.de',
            'regenstein.die-autohaus-gruppe.de',
            'halbac-dev.autohaus-digital.de',
            'halbac-live.autohaus-digital.de',
            'halbac-next.autohaus-digital.de',
            'halbac-pre-release.autohaus-digital.de',
            'halbac-test.autohaus-digital.de',
            'halbac.die-autohaus-gruppe.de',
            'quedac-dev.autohaus-digital.de',
            'quedac-live.autohaus-digital.de',
            'quedac-next.autohaus-digital.de',
            'quedac-pre-release.autohaus-digital.de',
            'quedac-test.autohaus-digital.de',
            'quedac.die-autohaus-gruppe.de',
            'Osterwieck-dev.autohaus-digital.de',
            'Osterwieck-live.autohaus-digital.de',
            'Osterwieck-next.autohaus-digital.de',
            'Osterwieck-pre-release.autohaus-digital.de',
            'Osterwieck-test.autohaus-digital.de',
            'osterwieck.die-autohaus-gruppe.de',
            'bergmann-dev.autohaus-digital.de',
            'bergmann-live.autohaus-digital.de',
            'bergmann-next.autohaus-digital.de',
            'bergmann-pre-release.autohaus-digital.de',
            'bergmann-test.autohaus-digital.de',
            'bergmann.die-autohaus-gruppe.de',
            'lcw-dev.autohaus-digital.de',
            'lcw-live.autohaus-digital.de',
            'lcw-next.autohaus-digital.de',
            'lcw-pre-release.autohaus-digital.de',
            'lcw-test.autohaus-digital.de',
            'lcw-wernigerode.die-autohaus-gruppe.de',
            'nora-osterwieck-dev.autohaus-digital.de',
            'nora-osterwieck-live.autohaus-digital.de',
            'nora-osterwieck-next.autohaus-digital.de',
            'nora-osterwieck-pre-release.autohaus-digital.de',
            'nora-osterwieck-test.autohaus-digital.de',
            'nora-zentrum-osterwieck.die-autohaus-gruppe.de',
        ], isDev: false, hideInListing: false, config: configDieAutohausGruppeSR,
    },
    { name: 'Eichsfelder Autohaus', hosts: ['eichsfelder-dev.autohaus-digital.de', 'eichsfelder-live.autohaus-digital.de', 'eichsfelder-next.autohaus-digital.de', 'eichsfelder-pre-release.autohaus-digital.de', 'eichsfelder-test.autohaus-digital.de', 'eichsfelder-autohaus.de'], isDev: false, hideInListing: false, config: configEichsfelder },
    { name: 'Elbtor', hosts: ['elbtor.de'], isDev: false, hideInListing: false, config: configElbtor },
    { name: 'Feddersen', hosts: ['feddersen-automobile.de'], isDev: false, hideInListing: false, config: configFeddersen },
    { name: 'Fink', hosts: ['fink-dev.autohaus-digital.de', 'fink-live.autohaus-digital.de', 'fink-next.autohaus-digital.de', 'fink-pre-release.autohaus-digital.de', 'fink-test.autohaus-digital.de', 'audi-fink.de'], isDev: false, hideInListing: false, config: configFink },
    { name: 'FSN Autohaus', hosts: ['fsn-autohaus.de'], isDev: false, hideInListing: false, config: configFSN },
    { name: 'Fugel Gruppe', hosts: ['fugel-gruppe.de'], isDev: false, hideInListing: false, config: configFugel },
    { name: 'Gebr Conrad', hosts: ['gebr-conrad.de'], isDev: false, hideInListing: false, config: configGebrConrad },
    { name: 'Glöckner Automobile', hosts: ['gloeckner-automobile.de'], isDev: false, hideInListing: false, config: configGlockner },
    { name: 'Grampp', hosts: ['grampp-dev.autohaus-digital.de', 'grampp-live.autohaus-digital.de', 'grampp-next.autohaus-digital.de', 'grampp-pre-release.autohaus-digital.de', 'grampp-test.autohaus-digital.de', 'grampp.net'], isDev: false, hideInListing: false, config: configGrampp },
    { name: 'Gröditzer Autohaus', hosts: ['groeditzer-autohaus.de'], isDev: false, hideInListing: false, config: configGroeditzer },
    { name: 'Henke Automobile - SEAT Wedel', hosts: ['seat-wedel.de'], isDev: false, hideInListing: false, config: configHenke },
    { name: 'Hoppmann', hosts: ['hoppmann-autowelt.de', 'fahrzeugmarkt.hoppmann-autowelt.de'], isDev: false, hideInListing: false, config: configHoppmann },
    { name: 'Kaesmann', hosts: ['kaesmann.de'], isDev: false, hideInListing: false, config: configKaesmann },
    // DEV STAND ab hier
    { name: 'Kuenzig Bleuel', hosts: ['k-b-dev.autohaus-digital.de', 'k-b-live.autohaus-digital.de', 'k-b-next.autohaus-digital.de', 'k-b-pre-release.autohaus-digital.de', 'k-b-test.autohaus-digital.de', 'kuenzig-bleuel.de'], isDev: false, hideInListing: false, config: configKuenzigBleuel },
    { name: 'Kuenzig Bleuel Hyundai', hosts: ['k-b-h-dev.autohaus-digital.de', 'k-b-h-live.autohaus-digital.de', 'k-b-h-next.autohaus-digital.de', 'k-b-h-pre-release.autohaus-digital.de', 'k-b-h-test.autohaus-digital.de', 'hyundai.kuenzig-bleuel.de'], isDev: false, hideInListing: false, config: configKuenzigBleuelHyundai },
    { name: 'Leo Belting', hosts: ['leo-belting.de'], isDev: false, hideInListing: false, config: configLeoBelting },
    { name: 'Liliensiek', hosts: ['liliensiek.de', 'liliensiek-dev.autohaus-digital.de', 'liliensiek-live.autohaus-digital.de', 'liliensiek-next.autohaus-digital.de', 'liliensiek-pre-release.autohaus-digital.de', 'liliensiek-test.autohaus-digital.de'], isDev: false, hideInListing: false, config: configLiliensiek },
    { name: 'Marnet', hosts: ['marnet.de'], isDev: false, hideInListing: false, config: configMarnet },
    { name: 'Mieten Kaufen Reisen', hosts: ['mietenkaufenreisen.de'], isDev: false, hideInListing: false, config: configNextCamperMietenKaufen },
    { name: 'Next Camper', hosts: ['next-camper.de'], isDev: false, hideInListing: false, config: configNextCamper },
    { name: 'Otto Grimm', hosts: ['otto-grimm.de'], isDev: false, hideInListing: false, config: configOttoGrimm },
    { name: 'OutletCars', hosts: ['outletcars-dev.autohaus-digital.de', 'outletcars-live.autohaus-digital.de', 'outletcars-next.autohaus-digital.de', 'outletcars-pre-release.autohaus-digital.de', 'outletcars-test.autohaus-digital.de', 'outletcars.de'], isDev: false, hideInListing: false, config: configOutletCars },
    { name: 'Reim Automobile', hosts: ['reim-automobile.de', 'reim-dev.autohaus-digital.de', 'reim-live.autohaus-digital.de', 'reim-next.autohaus-digital.de', 'reim-pre-release.autohaus-digital.de', 'reim-test.autohaus-digital.de'], isDev: false, hideInListing: false, config: configReim },
    { name: 'Salzmann Automobile', hosts: ['salzmann-automobile.de'], isDev: false, hideInListing: false, config: configSalzmann },
    { name: 'Schäfer Automobile', hosts: ['schaeferautomobile.de'], isDev: false, hideInListing: false, config: configSchaeferAutomobile },
    { name: 'Schmeink-Gruppe-DEV', hosts: ['asw.hinsken-advertising.de'], isDev: false, hideInListing: false, config: configSchmeinkDev },
    { name: 'Schmeink-Gruppe', hosts: ['schmeink-gruppe.de'], isDev: false, hideInListing: false, config: configSchmeink },
    {
        name: 'Spindler', hosts: [
            'autohaus-rennsteig.de',
            'rennsteig-dev.autohaus-digital.de',
            'rennsteig-live.autohaus-digital.de',
            'rennsteig-next.autohaus-digital.de',
            'rennsteig-pre-release.autohaus-digital.de',
            'rennsteig-test.autohaus-digital.de',
            'spindler-gruppe.de',
            'spindler-dev.autohaus-digital.de',
            'spindler-live.autohaus-digital.de',
            'spindler-next.autohaus-digital.de',
            'spindler-pre-release.autohaus-digital.de',
            'spindler-test.autohaus-digital.de',
            'spindler-classics.de',
            'spindler-classix-dev.autohaus-digital.de',
            'spindler-classix-live.autohaus-digital.de',
            'spindler-classix-next.autohaus-digital.de',
            'spindler-classix-pre-release.autohaus-digital.de',
            'spindler-classix-test.autohaus-digital.de',
        ], isDev: false, hideInListing: false, config: configSpindler,
    },
    { name: 'Thomas Exclusive Cars', hosts: ['thomas.vollblut-digital.de', 'thomas-exclusive-cars.de'], isDev: false, hideInListing: false, config: configThomasExclusive },
    { name: 'Thomas Exclusive Cars - Aston Martin', hosts: ['aston-martin.devbite.me', 'astonmartin-dresden.de'], isDev: false, hideInListing: false, config: configThomasExclusiveAstonMartin },
    { name: 'Thomas Heavy Metal Bikes', hosts: ['harley-dresden.com', 'harley-leipzig.com', 'harley-chemnitz.de'], isDev: false, hideInListing: false, config: configThomasHeavyMetal },
    { name: 'Tiemeyer', hosts: ['tiemeyer.de', 'tiemeyer-dev.autohaus-digital.de', 'tiemeyer-live.autohaus-digital.de', 'tiemeyer-next.autohaus-digital.de', 'tiemeyer-pre-release.autohaus-digital.de', 'tiemeyer-test.autohaus-digital.de'], isDev: false, hideInListing: false, config: configTiemeyer },
    { name: 'Vogt Mobil', hosts: ['vogt-mobil.de'], isDev: false, hideInListing: false, config: configVogt },
    { name: 'VW Arnold', hosts: ['vw-arnold.de'], isDev: false, hideInListing: false, config: configArnold },
    { name: 'Walter Schneider GmbH & Co KG', hosts: ['walter-schneider.de'], isDev: false, hideInListing: false, config: configWalterSchneider },

    // FOR TESTS - DO NOT DELETE!
    { name: 'Test', hosts: ['test'], isDev: true, hideInListing: true, config: configTest },
    {
        name: 'Test - No idFirma', hosts: ['testnoidfirma'], isDev: true, hideInListing: true, config: {
            ...configDefault,
            contact: {
                ...configDefault.contact,
                isLeadSystem: false,
                linkDisclaimer: 'https://autohaus-digital.de',
            },
        },
    },
    {
        name: 'Test - No contactIsLeadSystem', hosts: ['testnocontactleadsystem'], isDev: true, hideInListing: true, config: {
            ...configDefault,
            general: {
                ...configDefault.general,
                idFirma: 9800,
            },
            contact: {
                ...configDefault.contact,
                linkDisclaimer: 'https://autohaus-digital.de',
            },
        },
    },
    {
        name: 'Test - No linkDisclaimer', hosts: ['testnolinkdisclaimer'], isDev: true, hideInListing: true, config: {
            ...configDefault,
            general: {
                ...configDefault.general,
                idFirma: 9800,
            },
            contact: {
                ...configDefault.contact,
                isLeadSystem: false,
            },
        },
    },
    {
        name: 'Test - Not enabled', hosts: ['testnotenabled'], isDev: true, hideInListing: true, config: {
            ...configDefault,
            general: {
                ...configDefault.general,
                idFirma: 9800,
            },
            contact: {
                ...configDefault.contact,
                isLeadSystem: false,
                linkDisclaimer: 'https://autohaus-digital.de',
            },
        },
    },
    {
        name: 'Test - Modules not enabled', hosts: ['testfinanzierungnotenabled', 'testaktionnotenabled', 'testschnellsuchenotenabled'], isDev: true, hideInListing: true, config: {
            ...configDefault,
            general: {
                ...configDefault.general,
                idFirma: 9800,
                enable: true,
            },
            contact: {
                ...configDefault.contact,
                isLeadSystem: false,
                linkDisclaimer: 'https://autohaus-digital.de',
            },
        },
    },
];

/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configBadermainzl: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54610,
        enable: true,
        metaTitleSuffix: 'BaderMainzl',
        metaTitleSeperator: '-',
        projectName: 'BaderMainzl',
        searchPath: '/gebrauchtwagen',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            offsetY: 8,
        },
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'preis', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'standort', 'preis', 'ez', 'aus'],
        },
    },
    style: {
        ...configDefault.style,
        background: '#f7f7f7',
        borderRadius: 0,
        fontFamily: '"Open Sans", "Helvetica", "Arial", "Verdana", sans-serif',
        fontSize: 16,
        scrollOffset: 180,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    'div.MuiStack-root[data-testid="Schnellsuche_Container"]': { // Schnellsuche
                        '& div.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
                            background: '#fff !important',
                        },
                        '& div.MuiFormControl-root.MuiFormControl-fullWidth': {
                            border: '1px solid #777 !important',
                        },
                        '& label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
                            background: '#fff !important',
                        },
                        '& span.MuiChip-label': {
                            color: '#000 !important',
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'backgroundColor': '<colorPrimary> !important',
                            'color': '#fff !important',
                            '&:hover': {
                                backgroundColor: '#333333 !important',
                                color: '#fff !important',
                            },
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        [getStyleQuery()]: {
                            background: 'transparent',
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h5: {
                [getStyleQuery()]: {
                    marginBottom: 0,
                },
            },
        }),
        primaryColor: '#000',
    },
};

export default configBadermainzl;

/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configFugel: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30161,
        isGruppe: true,
        enable: true,
        projectName: 'Fugel',
        searchPath: '/suche',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            position: 'left',
        },
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
    },
    module: {
        ...configDefault.module,
        aktionsslider: true,
        schnellsuche: true,
    },
    cartile: {
        ...configDefault.cartile,
        infoGrid: {
            ...configDefault.cartile.infoGrid,
            KRAD: ['ez', 'km', '[L]ident', 'engine.leistung', 'engine.motor', 'getriebe', '[L]farbe', '[L]antrieb', '[G]spacer'],
            default: ['ez', 'km', '[L]ident', 'engine.leistung', 'engine.motor', 'getriebe', '[L]farbe', '[L]antrieb', '[G]spacer', 'verbrauch.combined', 'verbrauch.emission', 'verbrauch.co2class'],
        },
    },
    fahrzeugdetail: {
        ...configDefault.fahrzeugdetail,
        infoGrid: {
            ...configDefault.fahrzeugdetail.infoGrid,
            KRAD: ['ident', 'typ', 'ez', 'km', 'engine.motor', 'getriebe', 'engine.leistung', 'farbe', 'engine.hubraum', 'standort', 'antrieb'],
            default: ['ident', 'typ', 'ez', 'km', 'engine.motor', 'getriebe', 'engine.leistung', 'farbe', 'tueren', 'sitze', 'antrieb', 'verbrauch.combined', 'verbrauch.emission', 'verbrauch.co2class'],
        },
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        idExposee: 1537,
        filter: {
            sidebar: ['hersteller', 'modell', 'aufbau', 'typ', 'preis', 'motor', 'getriebe', 'ez', 'km', 'standort', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]preis', 'aufbau', 'motor', 'getriebe', 'ez', 'km', 'standort', 'aus'],
        },
        hideHeading: false,
        topFilter: true,
    },
    schnellsuche: {
        ...configDefault.schnellsuche,
        filter: ['hersteller', 'modell', 'motor', 'ezVon'],
    },
    api: {
        ...configDefault.api,
        autoUncle: {
            ...configDefault.api.autoUncle,
            departmentIds: {
                // Chemnitz
                53290: 'FdGcAbTY9E',
                // Dresden
                53280: 'e6IJInhOY0',
                // Freiberg
                53270: 'ooXE6QGxmv',
                // Mittelbach
                53240: 'SIxNV5eRuG',
                // Oberlungwitz
                53300: 'WYt0dvVdDg',
            },
            id: 'HY1ejTkc9x',
            origin: 'fugel-gruppe.de',
            priceRating: true,
            sourceName: 'Fugel-gruppe.de',
            tradeIn: true,
        },
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Open Sans", sans-serif, Arial',
        fontSize: 15,
        scrollOffset: 90,
        muiComponents: () => ({
            MuiButton: {
                styleOverrides: {
                    outlined: {
                        [getStyleQuery()]: {
                            '&:focus': {
                                outline: 'none',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '<fontFamily>',
                    fontWeight: 700,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '<fontFamily>',
                    fontWeight: 700,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '<fontFamily>',
                    fontWeight: 700,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '<fontFamily>',
                    fontWeight: 700,
                },
            },
        }),
        primaryColor: '#D12910',
    },
};

export default configFugel;

import { alpha, darken, lighten, rgbToHex, ThemeOptions } from '@mui/material/styles';
import { ConfigObject } from '../../_configs/types';
import { checkUndefinedNullOrEmpty } from '../../utils/tools';
import { DeepPartial } from 'react-hook-form';

function generateColorPalette(color: string) {
    return {
        lighter: rgbToHex(lighten(color, 0.2)),
        light: rgbToHex(lighten(color, 0.1)),
        main: color,
        dark: rgbToHex(darken(color, 0.1)),
        darker: rgbToHex(darken(color, 0.2)),
        contrastText: '#fff',
    };
}

export function presets(primaryColor: ConfigObject['style']['primaryColor'], secondaryColor: ConfigObject['style']['secondaryColor'], backgroundColor: ConfigObject['style']['background']): DeepPartial<ThemeOptions> {
    const primary = generateColorPalette(primaryColor);
    const secondary = generateColorPalette(secondaryColor);

    const theme: DeepPartial<ThemeOptions> = {
        palette: {
            primary,
            secondary,
        },
        customShadows: {
            primary: `0 8px 16px 0 ${alpha(`${primary?.main}`, 0.24)}`,
            secondary: `0 8px 16px 0 ${alpha(`${secondary?.main}`, 0.24)}`,
        },
    };

    if (!checkUndefinedNullOrEmpty(backgroundColor)) {
        theme.palette.background = {
            default: backgroundColor,
        };
    }

    return {
        ...theme,
    };
}

/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Daniel Rittrich
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configFink: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54623,
        enable: true,
        projectName: 'Fink',
        searchPath: '/fahrzeugsuche',
        showroooom: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'typ', 'km', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]sofort', '[LG]motor', 'getriebe', 'aufbau', 'preis', 'rate', 'ez', 'aus'],
        },
        tradeInLink: 'https://audi-fink.de/inzahlungnahme',
    },
    style: {
        ...configDefault.style,
        fontFamily: '"vwtextweb-regular"',
        muiComponents: () => ({
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        [getStyleQuery()]: {
                            '& > legend > span': {
                                fontSize: '1.03em !important',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h1: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                    fontWeight: 900,
                },
            },
            h2: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                    fontWeight: 900,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                    fontWeight: 900,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                    fontWeight: 900,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                    fontWeight: 900,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                    fontWeight: 900,
                },
            },
        }),
        primaryColor: '#c00a26',
    },
};

export default configFink;

/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { getStyleQuery } from '../../utils/tools';

const configBoxhammer: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 51460,
        enable: true,
        metaTitleSeperator: ' - ',
        metaTitleSuffix: 'Autohaus Boxhammer',
        projectName: 'Autohaus Boxhammer',
        searchPath: '/gebrauchtwagen',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
        buttons: {
            ...configDefault.contact.buttons,
            offsetY: 8,
        },
    },
    module: {
        ...configDefault.module,
        erweiterteSchnellsuche: true,
        fahrzeugslider: true,
    },
    erweiterteSchnellsuche: {
        ...configDefault.erweiterteSchnellsuche,
        filter: ['hersteller', 'modell', 'motor', 'aktion'],
        limitCols: 3,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['aktion', 'hersteller', 'modell', 'aufbau', 'typ', 'preis', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]aktion', '[SM]hersteller', '[SM]modell', '[LG]typ', 'aufbau', 'motor', 'getriebe', 'preis', 'ez', 'aus'],
        },
        topFilter: true,
        tradeInLink: 'https://www.was-ist-mein-gebrauchter-wert.de/ti-48003',
    },
    style: {
        ...configDefault.style,
        borderRadius: 1,
        scrollOffset: 100,
        fontFamily: '"Roboto", Helvetica, Arial, Verdana, sans-serif',
        fontSize: 16,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    '&.auws-engagement-widget': {
                        '--bottom-offset': '60px !important',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                color: '#fff',
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.FahrzeugSucheFilterSort_Container > button.MuiButton-text': {
                                color: '#000',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            button: {
                [getStyleQuery()]: {
                    fontWeight: '700',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontWeight: '700',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontWeight: '700',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontWeight: '700',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontWeight: '700',
                },
            },
        }),
        primaryColor: '#1c8dd8',
    },
};

export default configBoxhammer;

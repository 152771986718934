import { forwardRef } from 'react';
import { disableCache, Icon } from '@iconify/react';
import Box, { BoxProps } from '@mui/material/Box';
import { IconifyProps as IconifyIconProps } from './types';
import { checkUndefinedNullOrEmpty, getStyleQuery } from '../../utils/tools';
import { Badge } from '@mui/material';

disableCache('all');

type IconifyProps = BoxProps & {
    icon: IconifyIconProps;
};

const Iconify = forwardRef<SVGElement, IconifyProps>(function Iconify({ icon, width = 20, sx, ...other }, ref) {
    if (typeof icon !== 'string' && !checkUndefinedNullOrEmpty((icon as any)?.main) && !checkUndefinedNullOrEmpty((icon as any)?.badge)) {
        return (
            <Badge
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                badgeContent={
                    <Box
                        component={Icon}
                        className="component-iconify"
                        icon={(icon as any)?.badge}
                        sx={{
                            [getStyleQuery()]: {
                                borderRadius: 100,
                                background: (theme) => theme?.palette?.background?.default,
                                width: '0.75em',
                                height: '0.75em',
                            },
                        }}
                        {...other}
                    />
                }
                sx={{
                    [getStyleQuery()]: {
                        fontSize: width,
                    },
                }}
            >
                <Box
                    ref={ref}
                    component={Icon}
                    className="component-iconify"
                    icon={(icon as any)?.main}
                    sx={{
                        [getStyleQuery()]: {
                            width,
                            height: width,
                            ...sx,
                        },
                    }}
                    {...other}
                />
            </Badge>
        );
    } else {
        return (
            <Box
                ref={ref}
                component={Icon}
                className="component-iconify"
                icon={icon}
                sx={{
                    [getStyleQuery()]: {
                        width,
                        height: width,
                        ...sx,
                    },
                }}
                {...other}
            />
        );
    }
});

export default Iconify;

import { Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function skeleton(theme: Theme): Components<Theme> {
    return {
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        backgroundColor: theme.palette.background.neutral,
                    },
                },
                rounded: {
                    [getStyleQuery()]: {
                        borderRadius: theme.shape.borderRadius * 2,
                    },
                },
            },
        },
    };
}

import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function appBar() {
    return {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        boxShadow: 'none',
                    },
                },
            },
        },
    };
}

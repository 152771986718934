/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configSalzmann: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 51440,
        metaTitleSuffix: 'Salzmann Automobile',
        projectName: 'Salzmann Automobile',
        searchPath: '/index.php',
        searchPathSearch: '?id=155',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/kontakt/datenschutz',
    },
    module: {
        ...configDefault.module,
        aktionsslider: true,
        schnellsuche: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 4,
        fontFamily: '"Roboto", "Open Sans", "Arial", sans-serif',
        fontSize: 15,
        oldMuiComponents: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            fontWeight: 400,
                            textTransform: 'none',
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            fontWeight: 400,
                            textTransform: 'none',
                        },
                    },
                },
            },
        },
        primaryColor: '#a12c37',
    },
};

export default configSalzmann;

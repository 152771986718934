/**
 * @file Handles all configurations for the homepage tools.
 * @author Arne Rief
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configPirna: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 10970,
        enable: true,
        metaTitleSuffix: 'Autohaus Pirna',
        metaTitleSeperator: ' - ',
        projectName: 'Autohaus Pirna',
        searchPath: '/gebrauchtwagen',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/impressum',
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        tradeInLink: 'https://www.autohauspirna.de/service/fahrzeugankauf/fahrzeugankauf.html',
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        darkMode: true,
        fontFamily: '"Montserrat", sans-serif',
        fontSize: 16,
        scrollOffset: 90,
        muiComponents: () => ({
            MuiButton: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            fontSize: '0.8rem',
                            fontWeight: 800,
                            lineHeight: 1.2,
                            fontStyle: 'normal',
                            letterSpacing: '0.03em',
                            padding: '1em 2.2em',
                            textSizeAdjust: '100%',
                            textTransform: 'uppercase',
                        },
                    },
                    contained: {
                        [getStyleQuery()]: {
                            'border': '2px solid <colorPrimary>',
                            '&:hover': {
                                backgroundColor: '#fff',
                                border: '2px solid #fff',
                                boxShadow: 'none',
                                color: '#1a1a1a !important',
                            },
                        },
                    },
                    text: { // "Standort" contact links
                        [getStyleQuery()]: {
                            'color': '<colorPrimary> !important',
                            'padding': '6px 8px',
                            '&:hover': {
                                color: '<colorPrimary> !important',
                            },
                        },
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            'backgroundColor': '#fff',
                            'border': '2px solid #fff',
                            'color': '#1a1a1a !important',
                            // contact buttons next to fabs
                            '&[data-testid^="UseContact_ContactTextButton_"]': {
                                'border': 'none !important',
                                'fontSize': '14px !important',
                                'height': '30px !important',
                                'padding': '13px 8px !important',
                                'textTransform': 'none !important',
                                '& > span': {
                                    height: '30px !important',
                                },
                                '&:hover': {
                                    border: 'none !important',
                                },
                            },
                            '&:hover': {
                                backgroundColor: '<colorPrimary>',
                                border: '2px solid <colorPrimary>',
                                boxShadow: 'none',
                                color: '#fff !important',
                            },
                        },
                    },
                },
            },
            MuiBottomNavigation: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& > button.MuiButtonBase-root.MuiBottomNavigationAction-root': {
                                'fontSize': '14.4px',
                                'fontWeight': 800,
                                '&:hover': {
                                    'backgroundColor': '#fff',
                                    'color': '#1a1a1a !important',
                                },
                                '& > svg': {
                                    width: '24px',
                                    height: '24px',
                                },
                                '& > span.MuiBottomNavigationAction-label': {
                                    fontSize: '12px',
                                },
                            },
                        },
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.LocationUserCard a.MuiLink-underlineHover:hover': { // for "Ansprechpartner" underline & icon
                                color: '#1b77fc !important',
                            },
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        '&.MuiOutlinedInput-input': {
                            backgroundColor: 'transparent !important',
                        },
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            background: 'transparent',
                        },
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& > .MuiTypography-body1': { // for "Ansprechpartner" link-text
                                '&:hover': {
                                    color: '#1b77fc !important',
                                },
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    lineHeight: '26px',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontStyle: 'italic',
                    fontWeight: 700,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
            subtitle1: {
                [getStyleQuery()]: {
                    paddingTop: 0,
                },
            },
        }),
        primaryColor: '#EB013E',
        secondaryColor: '#333333',
        // #1a1a1a black text color for white secondary/hover buttons
        // #1b77fc blue hover color for links
    },
};

export default configPirna;

import { Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function tooltip(theme: Theme): Components<Theme> {
    const lightMode = theme.palette.mode === 'light';

    return {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    [getStyleQuery()]: {
                        backgroundColor: theme.palette.grey[lightMode ? 800 : 700],
                        fontSize: '12px',
                    },
                },
                arrow: {
                    [getStyleQuery()]: {
                        color: theme.palette.grey[lightMode ? 800 : 700],
                        fontSize: '12px',
                    },
                },
            },
        },
    };
}

import { getStyleQuery } from '../../../utils/tools';

export function svgIcon() {
    return {
        MuiSvgIcon: {
            styleOverrides: {
                fontSizeLarge: {
                    [getStyleQuery()]: {
                        width: 32,
                        height: 32,
                        fontSize: 'inherit',
                    },
                },
            },
        },
    };
}

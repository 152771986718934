import { Components, ThemeProvider as MuiThemeProvider, Theme, ThemeOptions, createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import merge from 'lodash-es/merge';
import { ReactNode, useMemo } from 'react';
import { useSettings } from '../hooks/use-settings';
import useLocales from '../hooks/useLocales';
import { checkUndefinedNullOrEmpty, replaceStyleTexts } from '../utils/tools';
import { breakpoints } from './breakpoints';
import { customShadows } from './custom-shadows';
import { createContrast } from './options/contrast';
import { presets as createPresets } from './options/presets';
import RTL from './options/right-to-left';
import { componentsOverrides } from './overrides';
import { palette } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';
import { ConfigObject } from '../_configs/types';

type Props = {
    children: ReactNode;
    overwriteSettings?: ConfigObject;
};

export default function ThemeProvider({ children, overwriteSettings }: Props) {
    const { currentLang } = useLocales();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { settings } = !checkUndefinedNullOrEmpty(overwriteSettings) ? { settings: overwriteSettings } : useSettings();
    const themeMode = settings?.style?.darkMode ? 'dark' : 'light';
    const presets = createPresets(settings?.style?.primaryColor, settings?.style?.secondaryColor, settings?.style?.background);
    const contrast = createContrast('default', themeMode);

    const memoizedValue = useMemo<ThemeOptions>(
        () => ({
            breakpoints: {
                values: breakpoints,
            },
            newTheme: true,
            palette: {
                ...palette(themeMode),
                ...presets.palette,
                ...contrast.palette,
            } as ThemeOptions['palette'],
            customShadows: {
                ...customShadows(themeMode),
                ...presets.customShadows,
            },
            direction: 'ltr',
            shadows: shadows(themeMode),
            shape: { borderRadius: settings?.style?.borderRadius * 4 },
            typography: typography(settings?.style?.fontFamily, settings?.style?.fontSize),
        }),
        [themeMode, presets.palette, presets.customShadows, contrast.palette, settings?.style?.borderRadius, settings?.style?.fontFamily, settings?.style?.fontSize],
    );

    const theme = createTheme(memoizedValue);

    theme.containerQueries = {
        up: (b) => theme?.breakpoints?.up(b)?.replace('@media', '@container'),
        down: (b) => theme?.breakpoints?.down(b)?.replace('@media', '@container'),
        between: (b1, b2) => theme?.breakpoints?.between(b1, b2)?.replace('@media', '@container'),
        not: (b) => theme?.breakpoints?.not(b)?.replace('@media', '@container'),
        only: (b) => theme?.breakpoints?.only(b)?.replace('@media', '@container'),
    };

    theme.components = merge(
        componentsOverrides(theme),
        contrast.components,
        replaceStyleTexts<Components<Theme>>(settings?.style?.muiComponents(theme), { colorPrimary: settings?.style?.primaryColor, colorSecondary: settings?.style?.secondaryColor, fontFamily: settings?.style?.fontFamily, fontSize: settings?.style?.fontSize || 16, textPrimary: memoizedValue?.palette?.text?.primary }),
    );

    theme.typography = merge(
        theme.typography,
        replaceStyleTexts<TypographyOptions>(settings?.style?.muiTypography(theme), { colorPrimary: settings?.style?.primaryColor, colorSecondary: settings?.style?.secondaryColor, fontFamily: settings?.style?.fontFamily, fontSize: settings?.style?.fontSize || 16, textPrimary: memoizedValue?.palette?.text?.primary }),
    );

    const themeWithLocale = useMemo(
        () => createTheme(theme, currentLang.systemValue),
        [currentLang.systemValue, theme],
    );

    return (
        <MuiThemeProvider theme={themeWithLocale}>
            <RTL themeDirection={'ltr'}>
                <CssBaseline />
                {children}
            </RTL>
        </MuiThemeProvider>
    );
}

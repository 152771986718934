import { ThemeOptions } from '@mui/material/styles';
import { customShadows } from '../custom-shadows';
import { grey } from '../palette';

// ----------------------------------------------------------------------

export function createContrast(contrast: 'default' | 'bold', mode: 'light' | 'dark'): Partial<ThemeOptions> {
    const theme: Partial<ThemeOptions> = {
        ...(contrast === 'bold' && mode === 'light' && {
            palette: {
                background: {
                    default: grey[200],
                },
            },
        }),
    };

    const components: ThemeOptions['components'] = {
        ...(contrast === 'bold' && {
            MuiCard: {
                styleOverrides: {
                    root: {
                        boxShadow: customShadows(mode).z1,
                    },
                },
            },
        }),
    };

    return {
        ...theme,
        components,
    };
}

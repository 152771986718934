import { sliderClasses } from '@mui/material/Slider';
import { Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function slider(theme: Theme): Components<Theme> {
    const lightMode = theme.palette.mode === 'light';

    return {
        MuiSlider: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        [`&.${sliderClasses.disabled}`]: {
                            color: theme.palette.action.disabled,
                        },
                        '@media (pointer: coarse)': {
                            padding: '13px 0px',
                        },
                    },
                },
                rail: {
                    [getStyleQuery()]: {
                        opacity: 0.32,
                    },
                },
                markLabel: {
                    [getStyleQuery()]: {
                        color: theme.palette.text.disabled,
                        fontSize: theme.typography.caption.fontSize,
                    },
                },
                valueLabel: {
                    [getStyleQuery()]: {
                        borderRadius: 8,
                        backgroundColor: theme.palette.grey[lightMode ? 800 : 700],
                    },
                },
            },
        },
    };
}

import { Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

export function bottomNavigation(theme: Theme): Components<Theme> {
    return {
        MuiBottomNavigation: {
            defaultProps: {
                showLabels: true,
            },

            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        backgroundColor: theme?.palette?.primary?.main,
                        color: theme?.palette?.primary?.contrastText,
                    },
                },
            },
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        color: theme.palette.getContrastText(theme.palette.primary.main),
                        fontSize: theme.typography.body2.fontSize,
                    },
                },
                label: {
                    [getStyleQuery()]: {
                        fontSize: theme.typography.body2.fontSize,
                    },
                },
            },
        },
    };
}

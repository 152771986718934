/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configOttoGrimm: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30009,
        enable: true,
        isGruppe: true,
        projectName: 'Autohaus Otto Grimm',
        searchPath: '/fahrzeugsuche.html',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz.html',
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'km', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'standort', 'km', 'ez', 'preis', 'rate', 'aus'],
        },
        hideHeading: false,
        topFilter: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Lato", "Open Sans", "Arial", sans-serif',
        fontSize: 14,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'background': '<colorPrimary> !important',
                            'border': 'none !important',
                            'color': '#fff !important',
                            '&:hover': {
                                background: '<colorSecondary> !important',
                                border: 'none !important',
                                color: '#fff !important',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'background': '<colorSecondary>',
                            'borderRadius': '4px',
                            'border': '2px solid #fff',
                            'color': '#fff',
                            '&:hover': {
                                background: '#efefef',
                                color: '#000',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            'background': '#dee0e1 !important',
                            'borderRadius': '4px !important',
                            'border': '2px solid #fff !important',
                            'color': '<colorPrimary> !important',
                            '&.Mui-disabled, &.Mui-disabled:hover': {
                                background: 'transparent !important',
                                border: '2px solid #efefef !important',
                                color: '#efefef !important',
                            },
                            '&:hover': {
                                border: '2px solid #000 !important',
                                background: '#efefef !important',
                                color: '#000 !important',
                            },
                        },
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            'background': '#dee0e1 !important',
                            'borderRadius': '4px !important',
                            'border': '2px solid #fff !important',
                            'color': '<colorPrimary> !important',
                            '&:hover': {
                                border: '2px solid #000 !important',
                                background: '#efefef !important',
                                color: '#000 !important',
                            },
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            'background': 'transparent !important',
                            'color': '<colorPrimary> !imporant',
                            '&:hover': {
                                background: 'rgba(53, 61, 71, 0.08) !important',
                            },
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            background: 'transparent !important',
                            boxShadow: 'none !important',
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'height': '56px',
                            'width': '56px',
                            '&:hover': {
                                background: '#efefef !important',
                                color: '#000 !important',
                            },
                        },
                    },
                },
            },
            MuiFormControlLabel: { // WIP LABEL FINANZIERUNG
                styleOverrides: {
                    // @ts-ignore
                    labelPlacementEnd: {
                        [getStyleQuery()]: {
                            display: 'flex',
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        [getStyleQuery()]: {
                            width: '100% !important',
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="LocationUserCard"] > button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary': {
                                width: '100% !important',
                            },
                        },
                    },
                },
            },
        }),
        primaryColor: '#353d47',
        secondaryColor: '#2e6e22 ',
    },
};

export default configOttoGrimm;

/**
 * @file Handles all configurations for the homepage tools.
 * @author Arne Rief
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { getStyleQuery } from '../../utils/tools';

const configAMB: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 12290,
        enable: true,
        metaTitleSuffix: 'AMB Autowelt',
        metaTitleSeperator: ' - ',
        projectName: 'AMB',
        searchPath: '/gebrauchtwagen-fahrzeugsuche',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            position: 'left',
        },
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
    },
    module: {
        ...configDefault.module,
        finanzierungsansicht: true,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['typ', 'hersteller', 'modell', 'aufbau', 'standort', 'km', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', 'aufbau', 'motor', 'getriebe', 'standort', 'km', 'preis', 'rate', 'ez', 'aus'],
        },
        hideHeading: false,
        titleWithDesign: true,
        topFilter: true,
        tradeInLink: 'https://www.amb-autowelt.de/amb-fahrzeugankauf/',
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"AMB-Nunito-400", Helvetica, Arial, Lucida, sans-serif',
        fontSize: 16,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'transition': 'background 0.2s ease-in, color 0.2s ease-in',
                            '&:hover': {
                                background: '<colorSecondary>',
                                color: '<colorPrimary>',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'border': 'solid 2px <colorPrimary>',
                            'maxHeight': '45px !important',
                            'transition': 'all 0.2s ease-in',
                            '&:hover': {
                                background: '<colorSecondary> !important',
                                border: 'solid 2px #acb9c6',
                                boxShadow: 'none',
                                color: '<colorPrimary> !important',
                            },
                        },
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            'background': '<colorSecondary> !important',
                            'border': 'solid 2px #acb9c6 !important',
                            'color': '<colorPrimary> !important',
                            'maxHeight': '45px !important',
                            'transition': 'all 0.2s ease-in',
                            '&:hover': {
                                background: '<colorPrimary> !important',
                                border: 'solid 2px <colorPrimary> !important',
                                boxShadow: 'none',
                                color: '#fff !important',
                            },
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            textTransform: 'none !important',
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CarPriceGrid_Container"] p.MuiTypography-h5': {
                                fontWeight: 700,
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    paddingBottom: 0,
                },
            },
            button: {
                [getStyleQuery()]: {
                    fontFamily: '"AMB-Nunito-700", Helvetica, Arial, Lucida, sans-serif !important',
                    fontWeight: 500,
                    lineHeight: '1.7em',
                    padding: '10px 12px',
                    textTransform: 'uppercase',
                },
            },
            caption: {
                [getStyleQuery()]: {
                    padding: 0,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontFamily: '"AMB-Nunito-900", Helvetica, Arial, Lucida, sans-serif',
                    fontWeight: 500,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"AMB-Nunito-700", Helvetica, Arial, Lucida, sans-serif',
                    fontWeight: 500,
                    paddingBottom: 0,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"AMB-Nunito-700", Helvetica, Arial, Lucida, sans-serif',
                    fontWeight: 500,
                    paddingBottom: 0,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"AMB-Nunito-700", Helvetica, Arial, Lucida, sans-serif',
                    fontWeight: 500,
                },
            },
        }),
        primaryColor: '#035595',
        secondaryColor: '#eef1f4',
        // dark grey border around soft button: #acb9c6
    },
};

export default configAMB;

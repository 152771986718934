import { Box, InputAdornment, TextField } from '@mui/material';
import Slider, { SliderMark, SliderMarkLabel, SliderProps } from '@mui/material/Slider';
import { forwardRef, ReactNode, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectOption } from '../../_types/Car';
import { checkUndefinedNullOrEmpty, getStyleQuery } from '../../utils/tools';
import Iconify from '../iconify/component';
import { IconifyProps } from '../iconify/types';

const CustomSlider = forwardRef<HTMLInputElement, SliderProps & { hasIcon?: boolean }>(
    function CustomSlider(props, ref) {
        return (
            <Box ref={ref} sx={{ [getStyleQuery()]: { ml: props?.hasIcon ? '14px' : '20px', my: '12.5px', width: 1, zIndex: 1 } }}>
                <Slider
                    data-testid={props?.['data-testid']}
                    disabled={props?.disabled}
                    getAriaValueText={props?.getAriaValueText}
                    valueLabelDisplay={props?.valueLabelDisplay}
                    valueLabelFormat={props?.valueLabelFormat}
                    onChange={props?.onChange}
                    onChangeCommitted={props?.onChangeCommitted}
                    marks={props?.marks}
                    max={props?.max}
                    min={props?.min}
                    step={props?.step}
                    slots={props?.slots}
                    sx={{ [getStyleQuery()]: { m: 0 } }}
                    value={props?.value}
                />
            </Box>
        );
    },
);

export type RHFSliderProps = SliderProps & {
    einheit?: string,
    helperText?: ReactNode,
    icon?: IconifyProps,
    label: string,
    name: string,
    options: SelectOption[],
};

export function RHFSlider({ disabled, icon, helperText, label, name, options, ...other }: RHFSliderProps) {
    const { control, getValues } = useFormContext();
    const formValue = getValues(name);

    const steps: { label: string, showLabel: boolean, value: number }[] = options?.reduce((prev, curr) => [
        ...prev,
        {
            label: curr?.t,
            showLabel: curr?.showLabel,
            value: curr?.i as number,
        },
    ], []);

    const [sliderValue, setSliderValue] = useState<number | number[]>([null, null]);

    function getValue(val: number): string {
        return options?.find((el) => el.i === val)?.t;
    }

    useEffect(() => {
        if (!checkUndefinedNullOrEmpty(formValue?.[0]) || !checkUndefinedNullOrEmpty(formValue?.[1])) {
            setSliderValue(formValue);
        } else {
            setSliderValue([options?.[0]?.i as number, options?.[options?.length - 1]?.i as number]);
        }
    }, [formValue, options]);

    const max = useMemo(() => (!checkUndefinedNullOrEmpty(steps) ? steps?.[steps?.length - 1]?.value : other?.max), [other?.max, steps]);
    const min = useMemo(() => (!checkUndefinedNullOrEmpty(steps) ? steps?.[0]?.value : other?.min), [other?.min, steps]);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    disabled={disabled}
                    error={!!error}
                    fullWidth
                    helperText={error ? error?.message : helperText}
                    inputProps={{
                        sx: { [getStyleQuery()]: { cursor: 'default' } },
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            {/* <Checkbox checked={field.value} /> */}
                        </InputAdornment>,
                        inputComponent: CustomSlider as any,
                        inputProps: {
                            ...field,
                            'data-testid': `RHFSelect_${name}`,
                            'disabled': disabled || checkUndefinedNullOrEmpty(steps),
                            'getAriaValueText': getValue,
                            'valueLabelDisplay': disabled ? 'off' : 'auto',
                            'valueLabelFormat': getValue,
                            ...other,
                            // @ts-ignore
                            'onChange': (_, val) => setSliderValue(val),
                            'onChangeCommitted': (_, val) => field.onChange({ target: { value: val } }),
                            'marks': steps,
                            'max': max,
                            'min': min,
                            'step': !checkUndefinedNullOrEmpty(steps) ? null : other?.step,
                            'slots': {
                                mark: (props) => props.ownerState.marks[props['data-index']]?.showLabel === true && <SliderMark {...props} />,
                                markLabel: (props) => props.ownerState.marks[props['data-index']]?.showLabel === true && <SliderMarkLabel {...props} sx={{
                                    ...props.sx,
                                    'top': 18.5,
                                    '@media (pointer: coarse)': {
                                        top: 18.5,
                                    },
                                    'WebkitTransform': props['data-index'] === 0 ? 'translateX(-7.5%) !important' : 'translateX(-92.5%) !important',
                                    'MozTransform': props['data-index'] === 0 ? 'translateX(-7.5%) !important' : 'translateX(-92.5%) !important',
                                    'MsTransform': props['data-index'] === 0 ? 'translateX(-7.5%) !important' : 'translateX(-92.5%) !important',
                                    'transform': props['data-index'] === 0 ? 'translateX(-7.5%) !important' : 'translateX(-92.5%) !important',
                                }} />,
                            },
                            'value': [
                                !checkUndefinedNullOrEmpty(sliderValue?.[0]) ? sliderValue?.[0] : min,
                                !checkUndefinedNullOrEmpty(sliderValue?.[1]) ? sliderValue?.[1] : max,
                            ],
                            'hasIcon': !checkUndefinedNullOrEmpty(icon),
                        },
                        readOnly: true,
                        startAdornment: !checkUndefinedNullOrEmpty(icon) ? <InputAdornment position="start" sx={{ pr: 0.5 }}>
                            <Iconify icon={icon} />
                        </InputAdornment> : undefined,
                        sx: { [getStyleQuery()]: { cursor: 'default' } },
                    }}
                    InputLabelProps={{ shrink: true }}
                    label={label}
                    sx={{ [getStyleQuery()]: { cursor: disabled ? 'default' : 'pointer' } }}
                    variant="outlined"
                />
            )}
        />
    );
}

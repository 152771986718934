/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Arne Rief
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configRabus: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54789,
        enable: true,
        metaTitleSeperator: ' - ',
        metaTitleSuffix: 'Autohaus Rabus - Memmingen',
        projectName: 'Autohaus Rabus',
        searchPath: '/fahrzeugsuche',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: false,
        linkDisclaimer: '/datenschutz',
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    erweiterteSchnellsuche: {
        ...configDefault.erweiterteSchnellsuche,
        filter: ['typ', 'hersteller', 'modell', 'aktion'],
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            ...configDefault.fahrzeugsuche.filter,
            sidebar: ['sofort', 'tags', 'hersteller', 'modell', 'standort','aufbau', 'typ', 'km', 'preis', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]tags', '[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'standort', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'ez', 'aus'],
        },
    },
    style: {
        ...configDefault.style,
        background: '#b1b0b5',
        borderRadius: 0,
        fontFamily: '"Open Sans", sans-serif',
        fontSize: 16,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            color: '#fff',
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                backgroundColor: '<colorSecondary>',
                                boxShadow: 'none',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            '&:not([disabled])': {
                                'backgroundColor': '#fff',
                                '&:hover': {
                                    backgroundColor: '#e1f1f2',
                                    borderColor: '<colorPrimary>',
                                    color: '<colorPrimary>',
                                },
                            },
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            'backgroundColor': '#e1f1f2',
                            '&:hover': {
                                backgroundColor: '<colorSecondary>',
                                boxShadow: 'none',
                                color: '#fff',
                            },
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            'color': '<colorPrimary>',
                            '&:hover': {
                                backgroundColor: '#e1f1f2',
                                color: '<colorPrimary>',
                            },
                        },
                    },
                    textError: {
                        [getStyleQuery()]: {
                            'color': '#ff5630',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 86, 48, 0.08)',
                                color: '#ff5630',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                backgroundColor: '<colorSecondary>',
                                boxShadow: 'none',
                            },
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="AusstattungLocationInfo_Card"] .MuiTypography-h5, &[data-testid="AusstattungLocationInfo_Card"] .MuiTypography-h6': {
                                color: '#000',
                            },
                            '&[data-testid="CalcFdRateOptions_Card"] > .MuiTypography-h5': {
                                color: '#000',
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="FahrzeugSucheFilterSideBar_Container"], &[data-testid="FahrzeugSucheFilterTopArea_Container"]': { // filter bars all in white
                                '& .MuiCheckbox-root, .MuiSlider-root, .MuiSlider-markLabel, .MuiSelect-icon': {
                                    color: '#fff',
                                },
                                '& .MuiChip-soft': {
                                    background: 'transparent',
                                    color: '#fff',
                                },
                                '& .MuiFormControl-root': {
                                    border: '1px solid #fff',
                                    color: '#fff',
                                },
                                '& .MuiFormLabel-root, .MuiInputAdornment-root': {
                                    backgroundColor: '#b1b0b5',
                                    color: '#fff !important',
                                },
                                '& .MuiInputBase-input': {
                                    '&[data-testid="RHFTextField_freier_text"]': {
                                        color: '#fff',
                                    },
                                    '&[data-testid="RHFTextField_freier_text"]::placeholder': {
                                        color: '#fff',
                                    },
                                },
                            },
                            '&.FahrzeugSucheFilterSort_Container > button.MuiButton-text': { // "Sortierung" & view text buttons
                                'color': '#000',
                                '&:focus': {
                                    backgroundColor: 'transparent',
                                },
                                '&:hover': {
                                    color: '<colorPrimary>',
                                },
                            },
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:focus': {
                                outline: 'none',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    color: '#fff',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    textTransform: 'none',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    textTransform: 'none',
                },
            },
            subtitle1: {
                [getStyleQuery()]: {
                    color: '#000',
                    textTransform: 'none',
                },
            },
        }),
        primaryColor: '#44a5ae',
        secondaryColor: '#3a3a3a',
    },
};

export default configRabus;

/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Arne Rief
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configHoch: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 53420,
        enable: true,
        metaTitleSeperator: ' | ',
        metaTitleSuffix: 'Autohaus Koch',
        projectName: 'Autohaus Hoch',
        searchPath: '/fahrzeugsuche',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: false,
        linkDisclaimer: '/',
    },
    module: {
        ...configDefault.module,
        erweiterteSchnellsuche: true,
        schnellsuche: true,
    },
    erweiterteSchnellsuche: {
        ...configDefault.erweiterteSchnellsuche,
        filter: ['typ', 'hersteller', 'modell', 'aktion'],
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            ...configDefault.fahrzeugsuche.filter,
            sidebar: ['sofort', 'tags', 'hersteller', 'modell', 'aufbau', 'typ', 'km', 'preis', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]tags','[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'ez', 'aus'],
        },
        // hideHeading: false,
        // topFilter: true,
    },
    style: {
        ...configDefault.style,
        // borderRadius: 0,
        fontFamily: '"Libre Franklin", Helvetica ,Arial ,Lucida ,sans-serif',
        fontSize: 14,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    //
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            //
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            //
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            //
        }),
        primaryColor: '#0b6eb1',
        secondaryColor: '#666666',
    },
};

export default configHoch;

/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import { breakpoints } from '../../theme/breakpoints';
import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configPerski: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 10190,
        enable: true,
        projectName: 'Autohaus Perski',
        searchPath: '/fahrzeugsuche',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/impressum',
    },
    module: {
        ...configDefault.module,
        aktionsslider: true,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        hideHeading: false,
        topFilter: true,
        tradeInLink: 'https://gib-uns-deinen-gebrauchten.de/ti-78239',
        useEyecatcher: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Open Sans", Helvetica, Arial, Lucida, sans-serif',
        fontSize: 16,
        scrollOffset: 150,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    [`@media (max-width: ${breakpoints.sm}px)`]: {
                        'a.et_pb_button.et_pb_button_1_tb_header.et_pb_bg_layout_light': {
                            bottom: '70px !important',
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="Schnellsuche_Container"]': {
                                marginBottom: '20px',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    fontWeight: 300,
                    lineHeight: '1.2em',
                },
            },
            body2: {
                [getStyleQuery()]: {
                    paddingBottom: 0,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    paddingBottom: 0,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    paddingBottom: 0,
                },
            },
            subtitle1: {
                [getStyleQuery()]: {
                    paddingBottom: 0,
                },
            },
        }),
        primaryColor: '#0D377F',
    },
};

export default configPerski;

/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { breakpoints } from '../../theme/breakpoints';
import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configFrankenthal: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54599,
        enable: true,
        metaTitleSeperator: ' - ',
        metaTitleSuffix: 'Autozentrum Frankenthal',
        projectName: 'Autozentrum Frankenthal',
        searchPath: '/fahrzeugbestand-frankenthal',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            position: 'left',
        },
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        limitCols: 2,
        detailsSingleRow: true,
        topFilter: true,
    },
    style: {
        ...configDefault.style,
        background: '#f8f8f8',
        borderRadius: 0,
        fontFamily: '"Open Sans", "Arial", sans-serif',
        fontSize: 14,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: { // fix slow scrolling
                    'html, body': {
                        scrollBehavior: 'unset !important',
                    },
                    [getStyleQuery()]: {
                        '*': {
                            scrollBehavior: 'unset !important',
                        },
                    },
                    [`@media (max-width: ${breakpoints.sm}px)`]: {
                        'div.auws-cta-area.ctabtns': { // "E-Mail Anfrage" & Tel Links
                            bottom: '58px !important',
                        },
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            color: '#fff !important',
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            borderRadius: '2px',
                            padding: '4.2px 14px',
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            borderRadius: '2px',
                            padding: '4.2px 14px',
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            'borderRadius': '2px',
                            'padding': '4.2px 14px',
                            // contact buttons next to fabs
                            '&[data-testid^="UseContact_ContactTextButton_"]': {
                                padding: '6px 8px',
                            },
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        [getStyleQuery()]: {
                            background: 'transparent',
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            button: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", "Helvetica", "Arial", "Lucida", sans-serif',
                },
            },
            caption: {
                [getStyleQuery()]: {
                    paddingBottom: 0,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"Muli",sans-serif',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", "Helvetica", "Arial", "Lucida", sans-serif',
                    marginBottom: 0,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", "Helvetica", "Arial", "Lucida", sans-serif',
                    paddingBottom: 0,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", "Helvetica", "Arial", "Lucida", sans-serif',
                    paddingBottom: 0,
                },
            },
            subtitle1: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", "Helvetica", "Arial", "Lucida", sans-serif',
                    paddingBottom: 0,
                },
            },
        }),
        primaryColor: '#f08505',
    },
};

export default configFrankenthal;

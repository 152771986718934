import checkUndefinedOrNull from "./checkUndefinedOrNull";

let scrollTimer: NodeJS.Timeout;
export default function scrollTo(id: (`#${string}` | `[data-carid="${string}"]`), position: ScrollLogicalPosition): void {
    scrollTimer = setTimeout(function () {
        const container = document.querySelector(id);
        if (!checkUndefinedOrNull(container?.scrollIntoView)) {
            container?.scrollIntoView({
                behavior: 'smooth',
                block: position,
            });
        }
        clearTimeout(scrollTimer);
    }, 200);
}


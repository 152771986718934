import { Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function treeView(theme: Theme): Components<Theme> {
    return {
        // @ts-expect-error ---
        MuiTreeItem: {
            styleOverrides: {
                label: {
                    [getStyleQuery()]: {
                        ...theme.typography.body2,
                    },
                },
                iconContainer: {
                    [getStyleQuery()]: {
                        width: 'auto',
                    },
                },
            },
        },
    };
}

import { filledInputClasses } from '@mui/material/FilledInput';
import { inputBaseClasses } from '@mui/material/InputBase';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { alpha, Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function textField(theme: Theme): Components<Theme> {
    const color = {
        focused: theme.palette.text.primary,
        active: theme.palette.text.secondary,
        placeholder: theme.palette.text.disabled,
    };

    const font = {
        label: theme.typography.body1,
        value: theme.typography.body1,
    };

    return {
        // HELPER
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        marginTop: theme.spacing(1),
                    },
                },
            },
        },

        // LABEL
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        ...font.value,
                        color: color.placeholder,
                        fontSize: theme.typography.body1.fontSize,
                        [`&.${inputLabelClasses.shrink}`]: {
                            ...font.label,
                            fontWeight: 600,
                            color: color.active,
                            [`&.${inputLabelClasses.focused}`]: {
                                color: color.focused,
                            },
                            [`&.${inputLabelClasses.error}`]: {
                                color: theme.palette.error.main,
                            },
                            [`&.${inputLabelClasses.disabled}`]: {
                                color: theme.palette.text.disabled,
                            },
                            [`&.${inputLabelClasses.filled}`]: {
                                transform: 'translate(12px, 6px) scale(0.75)',
                            },
                        },
                    },
                },
            },
        },

        // BASE
        MuiInputBase: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        [`&.${inputBaseClasses.disabled}`]: {
                            '& svg': {
                                color: theme.palette.text.disabled,
                            },
                        },
                        '& > fieldset': {
                            margin: '0 !important',
                        },
                    },
                },
                input: {
                    [getStyleQuery()]: {
                        ...font.value,
                        'border': 'none !important',
                        'boxShadow': 'none !important',
                        'height': 'auto !important',
                        'margin': '0 !important',
                        'padding': '16.5px 14px !important',
                        '&:active, &:focus, &:hover': {
                            border: 'none',
                            boxShadow: 'none',
                        },
                        '&::placeholder': {
                            ...font.value,
                            opacity: 1,
                            color: color.placeholder,
                        },
                    },
                },
            },
        },

        // STANDARD
        MuiInput: {
            styleOverrides: {
                underline: {
                    [getStyleQuery()]: {
                        '&:before': {
                            borderBottomColor: alpha(theme.palette.grey[500], 0.32),
                        },
                        '&:after': {
                            borderBottomColor: color.focused,
                        },
                    },
                },
            },
        },

        // OUTLINED
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        [`&.${outlinedInputClasses.focused}`]: {
                            [`& .${outlinedInputClasses.notchedOutline}`]: {
                                borderColor: color.focused,
                            },
                        },
                        [`&.${outlinedInputClasses.error}`]: {
                            [`& .${outlinedInputClasses.notchedOutline}`]: {
                                borderColor: theme.palette.error.main,
                            },
                        },
                        [`&.${outlinedInputClasses.disabled}`]: {
                            [`& .${outlinedInputClasses.notchedOutline}`]: {
                                borderColor: theme.palette.action.disabledBackground,
                            },
                        },
                    },
                },
                notchedOutline: {
                    [getStyleQuery()]: {
                        'borderColor': alpha(theme.palette.grey[500], 0.2),
                        'transition': theme.transitions.create(['border-color'], {
                            duration: theme.transitions.duration.shortest,
                        }),
                        '& > legend': {
                            fontSize: `calc(${theme.typography?.body1?.fontSize} - 2.5px)`,
                        },
                    },
                },
            },
        },

        // FILLED
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        'borderRadius': theme.shape.borderRadius,
                        'backgroundColor': alpha(theme.palette.grey[500], 0.08),
                        '&:hover': {
                            backgroundColor: alpha(theme.palette.grey[500], 0.16),
                        },
                        [`&.${filledInputClasses.focused}`]: {
                            backgroundColor: alpha(theme.palette.grey[500], 0.16),
                        },
                        [`&.${filledInputClasses.error}`]: {
                            backgroundColor: alpha(theme.palette.error.main, 0.08),
                            [`&.${filledInputClasses.focused}`]: {
                                backgroundColor: alpha(theme.palette.error.main, 0.16),
                            },
                        },
                        [`&.${filledInputClasses.disabled}`]: {
                            backgroundColor: theme.palette.action.disabledBackground,
                        },
                    },
                },
            },
        },
    };
}

/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Daniel Rittrich
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configGebrConrad: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 48731,
        enable: true,
        projectName: 'Gebr. Conrad',
        searchPath: '/Lagerwagen',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: false,
        linkDisclaimer: '/Impressumgruppe/Datenschutz',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            ...configDefault.fahrzeugsuche.filter,
            sidebar: ['sofort', 'typ', 'hersteller', 'modell', 'aufbau', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
        },
    },
    style: {
        ...configDefault.style,
        background: '#373f43',
        borderRadius: 0,
        fontFamily: '"Roboto", sans-serif',
        fontSize: 15,
        muiComponents: () => ({
            MuiButton: {
                styleOverrides: {
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            background: '#f6d7d8',
                        },
                    },
                },
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            background: '#fff',
                        },
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            background: '#fff',
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="LocationUserCard"]': {
                                marginTop: '20px',
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.FahrzeugSucheFilterSort_Container > button.MuiButton-text': {
                                color: '#c9cccd',
                            },
                            '&[data-testid="FahrzeugSucheFilterChips_results"] > h6.MuiTypography-subtitle1': {
                                color: '#c9cccd',
                            },
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.Mui-selected': {
                                color: '<colorPrimary>',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            caption: {
                [getStyleQuery()]: {
                    color: '#c9cccd',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontWeight: 500,
                    textAling: 'left',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontWeight: 500,
                    textAling: 'left',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontWeight: 500,
                    textAling: 'left',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontWeight: 500,
                    textAling: 'left',
                },
            },
            subtitle1: {
                [getStyleQuery()]: {
                    color: '#212b36',
                },
            },
        }),
        primaryColor: '#C70710',
    },
};

export default configGebrConrad;

import { listClasses } from '@mui/material/List';
import { Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';
import { paper } from '../../css';

// ----------------------------------------------------------------------

export function popover(theme: Theme): Components<Theme> {
    return {
        MuiPopover: {
            styleOverrides: {
                paper: {
                    [getStyleQuery()]: {
                        ...paper({ theme, dropdown: true }),
                        [`& .${listClasses.root}`]: {
                            padding: 0,
                        },
                    },
                },
            },
        },
    };
}

import { getStyleQuery } from '../../../utils/tools';
import { device } from '../../breakpoints';

// ----------------------------------------------------------------------

export function container() {
    return {
        MuiContainer: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        ['@media ' + device.xs]: {
                            maxWidth: '100%',
                            paddingLeft: 0,
                            paddingRight: 0,
                        },
                        ['@media ' + device.sm]: {
                            maxWidth: 560,
                        },
                        ['@media ' + device.md]: {
                            maxWidth: 950,
                        },
                        ['@media ' + device.lg]: {
                            maxWidth: 1220,
                        },
                        ['@media ' + device.xl]: {
                            maxWidth: 1440,
                        },
                    },
                },
            },
        },
    };
}

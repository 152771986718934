import { Components, darken, lighten, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function card(theme: Theme): Components<Theme> {
    return {
        MuiCard: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        'position': 'relative',
                        'boxShadow': theme.customShadows.card,
                        'borderRadius': theme.shape.borderRadius * 2,
                        'zIndex': 0, // Fix Safari overflow: hidden with border radius
                        '&.action': {
                            cursor: 'pointer',
                            transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        },
                        '&.flat': {
                            border: `1px solid ${theme.palette.divider}`,
                            boxShadow: 'none',
                            transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        },
                        '&.action:hover': {
                            backgroundColor: theme.palette.mode === 'light' ? lighten(theme.palette.background.paper, 0.15) : darken(theme.palette.background.paper, 0.15),
                            boxShadow: theme.customShadows.z20,
                        },
                        '&.flat.action:hover': {
                            backgroundColor: theme.palette.mode === 'light' ? lighten(theme.palette.background.paper, 0.15) : darken(theme.palette.background.paper, 0.15),
                            boxShadow: theme.customShadows.z8,
                        },
                    },
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        padding: theme.spacing(3, 3, 0),
                    },
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        padding: theme.spacing(3),
                    },
                },
            },
        },
    };
}

/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configASS: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 36189,
        enable: true,
        projectName: 'ASS',
        searchPath: '/fahrzeugsuche',
        showroooom: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: false,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        tradeInLink: '/fahrzeugankauf',
    },
    style: {
        ...configDefault.style,
        fontFamily: '"__Nunito_Sans_63b6cf", "__Nunito_Sans_Fallback_63b6cf", "Helvetica", "Arial", "sans-serif"',
        muiComponents: () => ({
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        [getStyleQuery()]: {
                            '& > legend > span': {
                                fontSize: '1.05em !important',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h1: {
                [getStyleQuery()]: {
                    fontWeight: 900,
                },
            },
            h2: {
                [getStyleQuery()]: {
                    fontWeight: 900,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontWeight: 900,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontWeight: 900,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontWeight: 900,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontWeight: 900,
                },
            },
        }),
        primaryColor: '#0068a9',
    },
};

export default configASS;

import { Theme } from '@mui/material';
import { ConfigObject } from '../_configs/types';
import { getStyleQuery } from '../utils/tools';

export function responsiveFontSizes({ xs, sm, md, lg }: { xs: number; sm: number; md: number; lg: number }) {
    return {
        'fontSize': `${xs}px`,
        '@media (min-width:600px)': {
            fontSize: `${sm}px`,
        },
        '@media (min-width:900px)': {
            fontSize: `${md}px`,
        },
        '@media (min-width:1200px)': {
            fontSize: `${lg}px`,
        },
    };
}

export const typography = (settingsFontFamily: ConfigObject['style']['fontFamily'], settingsFontSize: ConfigObject['style']['fontSize']): Omit<Theme['typography'], 'htmlFontSize' | 'pxToRem'> => {
    const fontFamily = settingsFontFamily || '"Open Sans", "Arial", sans-serif';
    const fontSize = settingsFontSize || 14;

    return {
        fontSize: fontSize,
        fontFamily: fontFamily,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightSemiBold: 600,
        fontWeightBold: 700,
        h1: {
            color: 'text.primary',
            fontWeight: 800,
            lineHeight: 80 / 64,
            margin: 0,
            ...responsiveFontSizes({ xs: fontSize + 24, sm: fontSize + 36, md: fontSize + 42, lg: fontSize + 50 }),
            [getStyleQuery()]: {
                color: 'text.primary',
                fontWeight: 800,
                lineHeight: 80 / 64,
                margin: 0,
                ...responsiveFontSizes({ xs: fontSize + 24, sm: fontSize + 36, md: fontSize + 42, lg: fontSize + 50 }),
            },
        },
        h2: {
            color: 'text.primary',
            fontWeight: 800,
            lineHeight: 64 / 48,
            margin: 0,
            ...responsiveFontSizes({ xs: fontSize + 16, sm: fontSize + 24, md: fontSize + 28, lg: fontSize + 32 }),
            [getStyleQuery()]: {
                color: 'text.primary',
                fontWeight: 800,
                lineHeight: 64 / 48,
                margin: 0,
                ...responsiveFontSizes({ xs: fontSize + 16, sm: fontSize + 24, md: fontSize + 28, lg: fontSize + 32 }),
            },
        },
        h3: {
            color: 'text.primary',
            fontWeight: 700,
            lineHeight: 1.5,
            margin: 0,
            ...responsiveFontSizes({ xs: fontSize + 8, sm: fontSize + 10, md: fontSize + 14, lg: fontSize + 18 }),
            [getStyleQuery()]: {
                color: 'text.primary',
                fontWeight: 700,
                lineHeight: 1.5,
                margin: 0,
                ...responsiveFontSizes({ xs: fontSize + 8, sm: fontSize + 10, md: fontSize + 14, lg: fontSize + 18 }),
            },
        },
        h4: {
            color: 'text.primary',
            fontWeight: 700,
            lineHeight: 1.5,
            margin: 0,
            ...responsiveFontSizes({ xs: fontSize + 4, sm: fontSize + 4, md: fontSize + 8, lg: fontSize + 8 }),
            [getStyleQuery()]: {
                color: 'text.primary',
                fontWeight: 700,
                lineHeight: 1.5,
                margin: 0,
                ...responsiveFontSizes({ xs: fontSize + 4, sm: fontSize + 4, md: fontSize + 8, lg: fontSize + 8 }),
            },
        },
        h5: {
            color: 'text.primary',
            fontWeight: 700,
            lineHeight: 1.5,
            margin: 0,
            ...responsiveFontSizes({ xs: fontSize + 2, sm: fontSize + 3, md: fontSize + 4, lg: fontSize + 4 }),
            [getStyleQuery()]: {
                color: 'text.primary',
                fontWeight: 700,
                lineHeight: 1.5,
                margin: 0,
                ...responsiveFontSizes({ xs: fontSize + 2, sm: fontSize + 3, md: fontSize + 4, lg: fontSize + 4 }),
            },
        },
        h6: {
            color: 'text.primary',
            fontWeight: 700,
            lineHeight: 28 / 18,
            margin: 0,
            ...responsiveFontSizes({ xs: fontSize + 1, sm: fontSize + 2, md: fontSize + 2, lg: fontSize + 2 }),
            [getStyleQuery()]: {
                color: 'text.primary',
                fontWeight: 700,
                lineHeight: 28 / 18,
                margin: 0,
                ...responsiveFontSizes({ xs: fontSize + 1, sm: fontSize + 2, md: fontSize + 2, lg: fontSize + 2 }),
            },
        },
        subtitle1: {
            fontSize: `${fontSize}px`,
            fontWeight: 600,
            lineHeight: 1.5,
            margin: 0,
            [getStyleQuery()]: {
                fontSize: `${fontSize}px`,
                fontWeight: 600,
                lineHeight: 1.5,
                margin: 0,
            },
        },
        subtitle2: {
            fontSize: `${fontSize - 2}px`,
            fontWeight: 600,
            lineHeight: 22 / 14,
            margin: 0,
            [getStyleQuery()]: {
                fontSize: `${fontSize - 2}px`,
                fontWeight: 600,
                lineHeight: 22 / 14,
                margin: 0,
            },
        },
        body1: {
            fontSize: `${fontSize}px`,
            lineHeight: 1.5,
            margin: 0,
            [getStyleQuery()]: {
                fontSize: `${fontSize}px`,
                lineHeight: 1.5,
                margin: 0,
            },
        },
        body2: {
            fontSize: `${fontSize - 2}px`,
            lineHeight: 22 / 14,
            margin: 0,
            [getStyleQuery()]: {
                fontSize: `${fontSize - 2}px`,
                lineHeight: 22 / 14,
                margin: 0,
            },
        },
        button: {
            fontSize: `${fontSize}px`,
            fontWeight: 700,
            lineHeight: 24 / 14,
            margin: 0,
            textTransform: 'none',
            [getStyleQuery()]: {
                fontSize: `${fontSize}px`,
                fontWeight: 700,
                lineHeight: 24 / 14,
                margin: 0,
                textTransform: 'none',
            },
        },
        caption: {
            fontSize: `${fontSize - 2}px`,
            lineHeight: 1.5,
            margin: 0,
            [getStyleQuery()]: {
                fontSize: `${fontSize - 2}px`,
                lineHeight: 1.5,
                margin: 0,
            },
        },
        overline: {
            fontSize: `${fontSize - 2}px`,
            fontWeight: 700,
            lineHeight: 1.5,
            margin: 0,
            textTransform: 'uppercase',
            [getStyleQuery()]: {
                fontSize: `${fontSize - 2}px`,
                fontWeight: 700,
                lineHeight: 1.5,
                margin: 0,
                textTransform: 'uppercase',
            },
        },
    };
};

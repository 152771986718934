/**
 * @file Handles all configurations for the homepage tools.
 * @author Arne Rief
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { getStyleQuery } from '../../utils/tools';

const configHoppeTestDomain: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 53910,
        enable: true,
        projectName: 'Autohaus Hoppe',
        searchPath: '/demo/gebrauchtwagen.html',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz.html',
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'typ', 'km', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'rate', 'ez', 'aus'],
        },
    },
    style: {
        ...configDefault.style,
        borderRadius: 1,
        fontFamily: 'Roboto',
        fontSize: 15,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    [getStyleQuery()]: {
                        'svg': {
                            minWidth: '15px',
                        },
                        '.UseContact_ContactTextButtonContainer': {
                            'borderRadius': '500px !important',
                        },
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                boxShadow: '0 8px 50px -6px rgba(84, 84, 120, 0.26)',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            border: '1px solid transparent',
                            borderRadius: '500px',
                            lineHeight: '42px',
                            padding: '0 20px',
                        },
                    },
                    contained: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                backgroundColor: '#f30a14',
                                boxShadow: '0 8px 50px -6px rgba(84, 84, 120, 0.26)',
                            },
                        },
                    },
                    outlined: {
                        'border': '1px solid <colorPrimary> !important',
                        '&:disabled': {
                            'border': '1px solid rgba(145, 158, 171, 0.8) !important',
                        },
                        '&:hover': {
                            backgroundColor: '<colorPrimary>',
                            color: '#fff',
                        },
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            'backgroundColor': 'transparent',
                            'border': '1px solid <colorPrimary>',
                            'color': '<colorPrimary>',
                            // contact buttons next to fabs
                            '&[data-testid^="UseContact_ContactTextButton_"]': {
                                fontSize: '15px',
                                padding: '0 8px',
                            },
                            '&:hover': {
                                backgroundColor: '<colorPrimary>',
                                color: '#fff',
                            },
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            'color': '<colorPrimary>',
                            'padding': '0 8px !important',
                            '&.MuiLink-root:MuiLink-underlineHover': { // telephone button
                                'padding': '0 8px !important',
                            },
                            '&:hover': {
                                backgroundColor: 'rgba(209, 9, 17, 0.08)',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                backgroundColor: '<colorPrimary>',
                                boxShadow: '0 8px 50px -6px rgba(84, 84, 120, 0.26)',
                            },
                        },
                    },
                },
            },
            MuiGrid2: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CarDetailsGrid_Container"] p.MuiTypography-body1': {
                                color: '#111111',
                            },
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    sizeSmall: {
                        [getStyleQuery()]: {
                            width: '32px',
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        [getStyleQuery()]: {
                            backgroundColor: 'transparent',
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="LocationUserCard"]': {
                                marginBottom: '20px',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    color: '#696969',
                    lineHeight: '1.6',
                },
            },
            button: {
                [getStyleQuery()]: {
                    fontSize: 17,
                    fontWeight: 500,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    color: '#111111',
                    fontFamily: 'URW Aggie W01 Normal',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    color: '#111111',
                    fontFamily: 'Roboto',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    color: '#111111',
                    fontFamily: 'Roboto',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    color: '#111111',
                    fontFamily: 'Roboto',
                },
            },
            subtitle1: {
                [getStyleQuery()]: {
                    letterSpacing: 0,
                },
            },
        }),
        primaryColor: '#d10911',
    },
};

export default configHoppeTestDomain;

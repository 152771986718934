import { getStyleQuery } from '../../../utils/tools';
import { slickSliderStyles } from './slick-slider';

// ----------------------------------------------------------------------

export function cssbaseline() {
    return {
        MuiCssBaseline: {
            styleOverrides: {
                'html, body': {
                    scrollBehavior: 'smooth',
                },
                [getStyleQuery()]: {
                    '*': {
                        boxSizing: 'border-box',
                        scrollBehavior: 'smooth',
                    },
                    'input': {
                        '&[type=number]': {
                            'MozAppearance': 'textfield',
                            '&::-webkit-outer-spin-button': {
                                margin: 0,
                                WebkitAppearance: 'none',
                            },
                            '&::-webkit-inner-spin-button': {
                                margin: 0,
                                WebkitAppearance: 'none',
                            },
                        },
                    },
                    'img': {
                        maxWidth: '100%',
                        display: 'inline-block',
                        verticalAlign: 'bottom',
                    },
                    'button': { // google maps zoom-buttons
                        '&.gm-control-active': {
                            '&[aria-label="Zoom in"]:not([disabled])': {
                                'img': {
                                    filter: 'brightness(0.6)',
                                },
                                '&:hover': {
                                    filter: 'brightness(0.4)',
                                },
                            },
                            '&[aria-label="Zoom out"]:not([disabled])': {
                                'img': {
                                    filter: 'brightness(0.6)',
                                },
                                '&:hover': {
                                    filter: 'brightness(0.4)',
                                },
                            },
                        },
                    },
                },
                ...slickSliderStyles,
            },
        },
    };
}

import { autocompleteClasses } from '@mui/material/Autocomplete';
import { checkboxClasses } from '@mui/material/Checkbox';
import { dividerClasses } from '@mui/material/Divider';
import { menuItemClasses } from '@mui/material/MenuItem';
import { alpha, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../utils/tools';
import { DEV, LOCALSERVER, NEXT, PRE_RELEASE, TEST } from '../vars';

// ----------------------------------------------------------------------

type BgBlurProps = {
    blur?: number;
    opacity?: number;
    color?: string;
    imgUrl?: string;
};

export function bgBlur(props?: BgBlurProps) {
    const color = props?.color || '#000000';
    const blur = props?.blur || 6;
    const opacity = props?.opacity || 0.8;
    const imgUrl = props?.imgUrl;

    if (imgUrl) {
        return {
            'position': 'relative',
            'backgroundImage': `url(${imgUrl})`,
            '&:before': {
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 9,
                content: '""',
                width: '100%',
                height: '100%',
                backdropFilter: `blur(${blur}px)`,
                WebkitBackdropFilter: `blur(${blur}px)`,
                backgroundColor: alpha(color, opacity),
            },
        } as const;
    }

    return {
        backdropFilter: `blur(${blur}px)`,
        WebkitBackdropFilter: `blur(${blur}px)`,
        backgroundColor: alpha(color, opacity),
    };
}

// ----------------------------------------------------------------------

export const paper = ({
    theme,
    bgcolor,
    dropdown,
}: {
    theme: Theme;
    bgcolor?: string;
    dropdown?: boolean;
}) => {
    const baseUrl = LOCALSERVER ? ('https://localhost:' + location.port) : `https://hpt${DEV ? '-dev' : NEXT ? '-next' : PRE_RELEASE ? '-pre-release' : TEST ? '-test' : ''}.autohaus-digital.de`;

    return ({
        ...bgBlur({
            blur: 20,
            opacity: 0.9,
            color: theme.palette.background.paper,
            ...(!!bgcolor && {
                color: bgcolor,
            }),
        }),
        backgroundImage: `url(${baseUrl}/media/cyan-blur.png), url(${baseUrl}/media/red-blur.png)`,
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundPosition: 'top right, left bottom',
        backgroundSize: '50%, 50%',
        ...(theme.direction === 'rtl' && {
            backgroundPosition: 'top left, right bottom',
        }),
        ...(dropdown && {
            padding: theme.spacing(0.5),
            boxShadow: theme.customShadows.dropdown,
            borderRadius: theme.shape.borderRadius * 1.25,
        }),
    });
};

// ----------------------------------------------------------------------

export const menuItem = (theme: Theme) => ({
    [getStyleQuery()]: {
        ...theme.typography.body1,
        'padding': theme.spacing(0.75, 1),
        'borderRadius': theme.shape.borderRadius * 0.75,
        '&:not(:last-of-type)': {
            marginBottom: 4,
        },
        [`&.${menuItemClasses.selected}`]: {
            'fontWeight': theme.typography.fontWeightSemiBold,
            'backgroundColor': alpha(`${theme?.palette?.primary?.lighter}`, 0.24),
            '&:hover': {
                backgroundColor: alpha(`${theme?.palette?.primary?.light}`, 0.24),
            },
        },
        [`& .${checkboxClasses.root}`]: {
            padding: theme.spacing(0.5),
            marginLeft: theme.spacing(-0.5),
            marginRight: theme.spacing(0.5),
        },
        [`&.${autocompleteClasses.option}[aria-selected="true"]`]: {
            'backgroundColor': alpha(`${theme?.palette?.primary?.lighter}`, 0.24),
            '&:hover': {
                backgroundColor: alpha(`${theme?.palette?.primary?.light}`, 0.24),
            },
        },
        [`&+.${dividerClasses.root}`]: {
            margin: theme.spacing(0.5, 0),
        },
        [`& .${checkboxClasses.root}`]: {
            padding: theme.spacing(0.5),
            marginLeft: theme.spacing(-0.5),
            marginRight: theme.spacing(0.5),
        },
        [`&.${autocompleteClasses.option}[aria-selected="true"]`]: {
            'backgroundColor': alpha(`${theme?.palette?.primary?.lighter}`, 0.24),
            '&:hover': {
                backgroundColor: alpha(`${theme?.palette?.primary?.light}`, 0.24),
            },
        },
        [`&+.${dividerClasses.root}`]: {
            margin: theme.spacing(0.5, 0),
        },
    },
});

// ----------------------------------------------------------------------

type BgGradientProps = {
    direction?: string;
    color?: string;
    startColor?: string;
    endColor?: string;
    imgUrl?: string;
};

export function bgGradient(props?: BgGradientProps) {
    const direction = props?.direction || 'to bottom';
    const startColor = props?.startColor;
    const endColor = props?.endColor;
    const imgUrl = props?.imgUrl;
    const color = props?.color;

    if (imgUrl) {
        return {
            background: `linear-gradient(${direction}, ${startColor || color}, ${endColor || color}), url(${imgUrl})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        };
    }

    return {
        background: `linear-gradient(${direction}, ${startColor}, ${endColor})`,
    };
}

// ----------------------------------------------------------------------

export function textGradient(value: string) {
    return {
        background: `-webkit-linear-gradient(${value})`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    };
}

// ----------------------------------------------------------------------

export const hideScroll = {
    x: {
        'msOverflowStyle': 'none',
        'scrollbarWidth': 'none',
        'overflowX': 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    y: {
        'msOverflowStyle': 'none',
        'scrollbarWidth': 'none',
        'overflowY': 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
} as const;

import { Translations } from './translations';

const enUS: Translations = {
    buttons: {
        acceptMaps: 'Click here to agree to the use of Google Maps!',
        advancedSearch: 'Advanced search',
        back: 'Back',
        backToSearch: 'Back to search',
        chooseVehicleHeading: 'Choose vehicle',
        clear: 'Clear',
        close: 'Close',
        collapse: 'Collapse',
        expand: 'Expand',
        filters: 'Filters',
        loadingView: 'Loading view...',
        loadMoreResults: 'Loading more results...',
        loadMoreVehicles: 'Loading new vehicles...',
        nextPage: 'Next',
        no: 'No',
        prevPage: 'Previous',
        rateCalculator: 'Rate calculator',
        resetFilter: 'Reset filter',
        search: 'Search',
        showAllVehicles: 'Show all {0} vehicles',
        vehicle: 'Vehicle',
        vehicles: 'Vehicles',
    },
    car: {
        ez: 'First registration',
        km: 'Mileage',
        ident: 'Order number',
        typ: 'Type',
        types: {
            singular: {
                '-': '(unknown)',
                '1': 'New vehicle',
                '2': 'Used vehicle',
                '4': 'Demonstration vehicle',
                '8': 'Rental vehicle',
                '16': 'Special vehicle',
                '32': 'Day admission',
            },
            plural: {
                '-': '',
                '1': 'New vehicles',
                '2': 'Used vehicles',
                '4': 'Demonstration vehicles',
                '8': 'Rental vehicles',
                '16': 'Special vehicles',
                '32': 'Day admissions',
            },
        },
        sitze: 'Seats',
        tueren: 'Doors',
        engine: {
            effizienz: 'Efficiency',
            hubraum: 'Displacement',
            leistung: 'Power',
            motor: 'Engine',
            verbrauch: {
                '0': 'Consumption comb.',
                '1': 'CO2 emission',
                '2ndDrive': '2nd Drive',
                'combined': 'Combined',
                'effizienz': 'Efficiency',
                'schadstoff': 'Emission class',
                'heading': 'Consumption and emission',
                'inner': 'In town',
                'outer': 'Out of town',
                'slow': 'City',
                'medium': 'Outskirts',
                'fast': 'Country road',
                'veryFast': 'Highway',
                'range': 'Range',
                'city': 'City',
                'protocol': 'Consumption data',
                'protocolBy': 'Consumption data according to {0}',
                'nefz': 'NEDC',
                'wltp': 'WLTP',
                'noNEFZAvailable': 'No NEDC values available',
            },
        },
        garantie: 'Warranty',
        getriebe: 'Transmission',
        siegel: 'Seal',
        standort: 'Location',
        farbe: 'Color',
        antrieb: 'Drive',
        verfuegbarkeit: 'Availability',
        verfuegbarAb: 'Available at',
        aktion: 'campaign vehicle',
        nowAvailable: 'Now available',
        lieferzeit: 'Delivery time',
        preis: {
            inclMwst: 'incl. {0} VAT',
            exclMwst: 'excl. VAT',
        },
        finanzierung: {
            fd: 'Financial service',
            title: 'Financing',
            desc: 'With AutoCredit, the monthly installments are particularly low due to an increased final installment. At the end of the contract, you can return the vehicle in contractual condition to the delivering dealer, who will then settle the final installment for you. This right of return is even explicitly securitized by your dealership - which is why it can only apply there, of course. Alternatively, you can settle the final installment yourself or continue financing.',
            onRequest: 'on request',
            laufzeit: 'Term',
            anzahlung: 'Down payment',
            rate: 'Rate',
            monate: 'months',
            monatlich: 'mtl.',
            effJahreszins: 'Annual percentage rate',
            sollzinssatz: 'Nominal interest rate',
            schlussrate: 'Ending rate',
            ksb: 'Credit protection insurance',
            ksb_betrag: 'Amount Credit protection insurance',
            laufleistung: 'Annual mileage',
            nettokreditbetrag: 'Net loan amount',
            bruttokreditbetrag: 'Gross rate',
            inklTransfer: 'incl. transfer costs',
            netto: 'net',
            brutto: 'gross',
            angebot: 'This is an offer from',
            noProducts: 'There are no offers for this vehicle',
            noOffer: 'No offer found',
            angebotAnfragen: 'Request offer',
            individuellBerechnen: 'Calculate individually now',
            bonitat: 'Creditworthiness required',
        },
        leasing: {
            title: 'Leasing',
            desc: 'New car without long-term commitment and financial risk? - Calculable leasing rates for your desired leasing period of 12-48 months.',
            onRequest: 'on request',
            notAvailable: 'Not available',
            typ: 'Type',
            commercial: 'Commercial',
            commercialDesc: 'Why put capital into a car that can be invested much more lucratively in the business purpose? Business vehicle leasing is an attractive offer for commercial customers who want low monthly installments and want to use the car rather than purchase it. You "rent" a vehicle of your choice for a fixed period and pay low leasing rates. At the end of the contract, you simply return your car to your dealer after using it in accordance with the contract and lease a new one.',
            private: 'Private',
            privateDesc: 'Private leasing is an attractive offer for customers who want low monthly installments and want to use the car rather than purchase it. The low monthly leasing rate leaves financial leeway for other wishes. At the end of the contract, you simply return your car to your dealer after using it in accordance with the contract and lease a new one.',
            laufzeit: 'Term',
            monate: 'months',
            monatlich: 'mtl.',
            rate: 'Rate',
            effJahreszins: 'Annual percentage rate',
            sollzinssatz: 'Nominal interest rate',
            anzahlung: 'Special payment',
            laufleistung: 'Annual mileage',
            zulassungskosten: 'Registration fee',
            transferkosten: 'Destination charge',
            mehrkilometer: 'Extra mileage costs',
            minderkilometer: 'Low mileage compensation',
            nettokreditbetrag: 'Net loan amount',
            bruttokreditbetrag: 'Total amount',
            zzglTransfer: 'plus transfer costs',
            lrv: 'LRV',
            netto: 'net',
            brutto: 'gross',
            angebot: 'This is an offer from',
            bonitat: 'Creditworthiness required',
        },
        aus: {
            categories: {
                kopf: '',
                highlights: 'Highlights',
                technik: 'Technology and safety',
                multimedia: 'Multimedia',
                assistenz: 'Assistance',
                innen: 'Interior',
                sitze: 'Seats and Pads',
                aussen: 'Exterior',
                scheinwerfer: 'Headlights and lamps',
                raeder: 'Wheels and tires',
                pakete: 'Packets',
                sonstiges: 'Other',
                fussnoten: 'Foot notes',
                fuss: '',
            },
            assistenz: {
                title: 'Assistance',
                25: 'Cruise control',
                26: 'Parking sensors',
                48: 'Self-steering park system',
                332: 'Adaptive cruise control',
                402: 'Rear camera',
                514: 'Lane change assistant',
                515: 'Lane keeping assistant',
                1145: 'Traffic sign recognition',
            },
            aussen: {
                title: 'Exterior',
                34: 'Sunroof',
                41: 'Xenon headlights',
                71: 'Alloy wheels',
                88: 'Hitch',
                238: 'Panoramic/glass roof',
                1140: 'LED headlights',
            },
            innen: {
                title: 'Interior',
                22: 'Automatic air conditioning',
                23: 'Auxiliary heating',
                24: 'Navigation system',
                27: 'Electric windows',
                29: 'Heated seats',
                36: 'Electric seat adjustment',
                81: 'Leather interior',
                278: 'Sport seats',
                1119: 'Bluetooth',
            },
            sonstiges: {
                title: 'Other',
                61: 'Four-wheel drive',
            },
        },
    },
    carSearch: {
        foundCar: 'vehicle found',
        foundCars: 'vehicles found',
        forFilters: 'for filters',
        gridView: 'Grid',
        heading: 'New and used vehicles',
        heading2: 'Used vehicles',
        listView: 'List',
        page: 'Page',
        sort: 'Sort',
        view: 'View',
    },
    carView: {
        accessoires: {
            special: 'Special accessoires',
            standard: 'Standard accessoires',
        },
        contactTab: {
            approach: 'Approach',
            call: 'Call',
            mail: 'Mail',
            message: 'Request',
            phone: 'Phone',
        },
        contact: {
            abort: 'Abort',
            title: 'Contact',
            contactPerson: 'Your contact person',
            copyTel: {
                error: 'Error',
                success: 'Copied',
            },
            fd: 'Your financial service',
            requestType: 'Request type',
            requestTypes: {
                '0': 'Send request',
                '0a': 'Send request to',
                '10': 'Request appointment',
                '10a': 'Request appointment at',
                '12': 'Request call back',
                '12a': 'Request call back at',
                '20': 'Request test drive',
                '20a': 'Request test drive at',
                '27': 'Trade in',
                '27a': 'Trade in at',
                '0Short': 'request',
                '10Short': 'appointment',
                '12Short': 'call back',
                '20Short': 'test drive',
                '27Short': 'Trade in',
            },
            name: 'Name',
            mail: 'Mail',
            phone: 'Phone',
            date: 'Desired date',
            message: 'Your request',
            disclaimer: 'I have read the {0}',
            disclaimerLink: 'Disclaimer',
            reset: 'Start over',
            send: 'Send request',
            sending: 'Sending...',
            errorSending: 'Error sending request',
            successSending: 'Request sent successfully',
            tryAgain: 'Please try again in some minutes',
            subjectPrefix: 'Vehicle request',
            subjectPrefixAbo: 'AutoAbo request',
            errors: {
                reCaptcha: 'ReCAPTCHA verification failed',
                requestType: 'Request type missing',
                name: 'Name missing',
                mailMissing: 'Mail missing',
                mailInvalid: 'Mail in wrong format',
                phone: 'Phone missing',
                date: 'Date missing',
                message: 'Request missing',
                acceptDisclaimer: 'Please accept disclaimer',
            },
        },
        finLeas: {
            errors: {
                downPayment: 'Down payment missing',
                duration: 'Term missing',
                mileage: 'Annual mileage missing',
                rsv: 'LRV missing',
            },
        },
        newTabs: {
            0: {
                title: 'Accessoires',
                url: 'accessoires',
            },
            1: {
                title: 'Description',
                url: 'description',
            },
            2: {
                title: 'Consumption data',
                url: 'consumption-data',
            },
            3: {
                title: 'Location',
                url: 'location',
            },
        },
        calculator: {
            button: 'Request now for {0} / month',
            fields: {
                annualPercentageRate: 'Annual percentage rate',
                carPrice: 'Vehicle price',
                damage_participation: 'Deductible',
                delivery_option: {
                    faaren_delivery: 'Delivery by Faaren',
                    no_delivery: 'No delivery',
                    partner_delivery: 'Delivery by provider',
                    title: 'Delivery options',
                },
                delivery_time_after_booking: 'Delivery time after order',
                downPaymentFinancing: 'Down payment',
                downPaymentLeasing: 'Special payment',
                duration: 'Term',
                extraMileageCosts: 'Extra mileage costs',
                fixed_duration: 'monthly changeable',
                lastRate: 'Final payment',
                leasePaymentInsurance: {
                    NONE: 'no',
                    RSV: 'LRV',
                    RSVPLUS: 'LRV Plus',
                    title: 'Lease rate insurance',
                },
                lowMileageCompensation: 'Low mileage compensation',
                mileage: {
                    autoAbo: 'Monthly mileage',
                    other: 'Annual mileage',
                },
                min_license_duration: 'Minimum driving license period',
                minimum_age: 'Minimum age',
                monthly: 'mtl.',
                monthlyRate: 'Monthly rate gross',
                monthlyRateNet: 'Monthly rate net',
                netLoan: 'Net loan amount',
                nominalInterestRate: 'Nominal interest rate',
                paymentProtectionInsurance: {
                    NONE: 'no',
                    RSV: 'CPI',
                    RSVPLUS: 'CPI Plus',
                    title: 'Credit protection insurance',
                },
                paymentProtectionInsuranceAmount: 'Amount credit protection insurance',
                pick_up_option: {
                    faaren_delivery: 'Pick up from Faaren',
                    no_delivery: 'No delivery',
                    partner_delivery: 'Pick up from provider',
                    title: 'Pick up options',
                },
                privateLeasing: 'private',
                businessLeasing: 'business',
                transfer: 'Transport costs',
                total: 'Total amount',
                type: {
                    autoAbo: {
                        detail0: 'All inclusive except for fuel',
                        detail1: 'Can be canceled monthly at the end of the minimum term',
                        title: 'Auto-Abo',
                        prevText: 'rent'
                    },
                    financing: {
                        detail0: 'Low monthly rate',
                        detail1: 'Final installment with several closing options',
                        title: 'Financing',
                        prevText: 'finance',
                    },
                    leasing: {
                        business: 'Business leasing',
                        detail0: 'Fixed monthly installment',
                        detail1: 'Return of the vehicle at the end of the term',
                        private: 'Private leasing',
                        title: 'Leasing',
                        prevText: 'lease'
                    },
                    title: 'Type',
                },
                upe: 'Vehicle new price',
            },
            title: {
                block: 'Simple and flexible financing or leasing',
                blockWithAbo: 'Simple and flexible financing, leasing or subscribe',
                rateSettings: 'Your rate settings',
                summary: {
                    financing: 'Your financing offer',
                    leasing: 'Your leasing offer',
                    autoAbo: 'Your Auto-Abo offer',
                },
                typeChooser: 'Pick your poison',
            },
        },
        downloadExposee: 'Download',
        exposeeError: 'An error occurred during generation!\nPlease try again later',
        exposeeLoading: 'One moment please, the exposé is generated',
        exposeeReady: 'The exposé is now ready for download',
        locationUsers: 'Your contact persons',
        locationUsersContact: 'Contact',
        share: {
            copy: 'Copy link',
            mail: 'Mail',
            title: 'Share',
        },
        showExposee: 'Exposé',
        similarCars: 'Similar cars',
        tabs: {
            0: 'Vehicle data',
            1: 'Description',
            2: 'Location',
        },
        tradeIn: {
            heading: 'Trade-in?',
            content: 'Would you like to trade in your current vehicle and quickly find out the non-binding market value?',
            button: 'Calculate now',
        },
        verbrauch: {
            combined: 'Combined',
            combinedWeighted: 'Combined (weighted)',
            combinedWeightedShort: 'Comb. wei.',
            co2class: 'CO2 class',
            co2classWeighted: 'CO2 class (weighted)',
            co2costs: 'Possible CO2 costs over the next 10 years (at 15,000 km / year):',
            co2costsLow: 'low CO2 price',
            co2costsMedium: 'medium CO2 price',
            co2costsHigh: 'high CO2 price',
            discharged: 'at discharged\nbattery',
            energycosts: 'Energy costs per year (at 15,000 km / year)',
            emission: 'Emission',
            emissionWeighted: 'Emission (weighted)',
            emissionWeightedShort: 'Emiss. wei.',
            euronorm: 'Emission class',
            extraHigh: 'Highway',
            header: 'Fuel Consumption',
            headerDischarged: 'Fuel consumption with discharged battery',
            headerElectro: 'Power consumption',
            headerPlugIn: 'Power consumption in all-electric mode',
            high: 'Country road',
            medium: 'Outskirts',
            motor: 'Motor',
            low: 'City',
            range: 'Electrical Range',
            title: 'Consumption and emission',
            weighted: 'weighted',
        },
    },
    common: {
        and: 'and',
        at: 'at',
        brutto: 'gross',
        in: 'in',
        netto: 'net',
        notAvailable: 'Not available',
    },
    errorHandler: {
        moduleDisabled: {
            heading: 'Sorry, this module is not available right now!',
            subtitle: 'Are you the operator? Please contact the administrator.',
        },
        noResults: {
            headingAktionsslider: 'Currently no campaign vehicles are available!',
            heading: 'Currently no vehicles available for this search!',
            subtitle: 'Please try again with other search parameters.',
        },
        error: {
            heading: 'Sorry, an error occured!',
            subtitle: 'Please try again later.',
            tooltip: 'Show error details',
            actions: {
                exitError: {
                    tooltip: 'Hide error',
                },
                goHome: {
                    tooltip: 'Go back to homepage',
                },
                reloadPage: {
                    tooltip: 'Reload page',
                },
            },
        },
        sIntErrors: {
            'webcalc': {
                title: 'Error',
                details: 'Unfortunately, an error occurred when requesting the calculation data. Please try again later!',
            },
            'unknown': {
                title: 'Unknown error',
                details: 'No error details available.',
            },
            'totalausfall': {
                title: 'Malfunction',
                details: 'Dear visitors, currently there is a malfunction.\nPlease try again later.',
            },
            'networkError': {
                title: 'Network error',
                details: 'A network error occured. Please check your internet connection!',
            },
            'runtimeError': {
                title: 'Runtime error',
                details: 'A runtime error occured. Please try again later!',
            },
            '0x400': {
                title: 'Bad request',
                details: '',
            },
            '0x401': {
                title: 'Unauthorized',
                details: '',
            },
            '0x403': {
                title: 'Forbidden',
                details: '',
            },
            '0x404': {
                title: 'Not Found',
                details: '',
            },
            '0x405': {
                title: 'Method Not Allowed',
                details: '',
            },
            '0x408': {
                title: 'Request Timeout',
                details: '',
            },
            '0x409': {
                title: 'Conflict',
                details: '',
            },
            '0x410': {
                title: 'Gone',
                details: '',
            },
            '0x414': {
                title: 'URI Too Long',
                details: '',
            },
            '0x415': {
                title: 'Unsupported Media Type',
                details: '',
            },
            '0x423': {
                title: 'Locked',
                details: '',
            },
            '0x425': {
                title: 'Too Early',
                details: '',
            },
            '0x429': {
                title: 'Too Many Requests',
                details: '',
            },
            '1x404': {
                title: 'Company data not found',
                details: '',
            },
            '1x500': {
                title: 'Server error while getting company data',
                details: '',
            },
            '2x404': {
                title: 'No vehicles found',
                details: '',
            },
            '2x500': {
                title: 'Server error while getting vehicle list',
                details: '',
            },
            '3x204': {
                title: 'Vehicle not found',
                details: '',
            },
            '3x404': {
                title: 'Vehicle not found',
                details: '',
            },
            '3x500': {
                title: 'Server error while getting vehicle',
                details: '',
            },
            '3x501': {
                title: 'Server error while getting vehicle',
                details: '',
            },
            '4x000': {
                title: 'The module couldn\'t be found',
                details: '',
            },
            '4x001': {
                title: 'Field \'idFirma\' is missing in configuration',
                details: '',
            },
            '4x002': {
                title: 'The contact mail adress is not configured.',
                details: 'Please contact the customer support.',
            },
            '4x003': {
                title: 'Field \'contactLeadSystem\' is missing in configuration',
                details: '',
            },
            '4x004': {
                title: 'Field \'linkDisclaimer\' is missing in configuration',
                details: '',
            },
            '4x005': {
                title: 'HOMEPAGE-TOOL disabled',
                details: '',
            },
            '4x006a': {
                title: 'Campaign slider module disabled',
                details: '',
            },
            '4x006b': {
                title: 'Financing view module disabled',
                details: '',
            },
            '4x006c': {
                title: 'Quicksearch module disabled',
                details: '',
            },
            '4x006d': {
                title: 'Vehicle search module disabled',
                details: '',
            },
            '4x006e': {
                title: 'Extended quicksearch disabled',
                details: '',
            },
            '4x006f': {
                title: 'Vehicle slider disabled',
                details: '',
            },
            '4x006g': {
                title: 'Vehicle list disabled',
                details: '',
            },
            '4x006h': {
                title: 'Locations disabled',
                details: '',
            },
            '4x007': {
                title: 'Host is missing in URL',
                details: '',
            },
            '0815x001': {
                title: 'Testing error goBack',
                details: '',
            },
            '0815x002': {
                title: 'Testing error reloadPage',
                details: '',
            },
            '0815x003': {
                title: 'Testing error exitError',
                details: '',
            },
        },
    },
    filter: {
        aktion: 'campaign vehicles',
        aktionActive: 'Only campaign vehicles',
        farbe: 'Color',
        hersteller: 'Manufacturer',
        modell: 'Model',
        typ: 'Type',
        stime: 'Storage time',
        standort: 'Location',
        standortMap: {
            buttonText: 'Map view',
            dialog: {
                filterButton: 'Show {0} vehicles',
                filterAllButton: 'Show all vehicles',
                title: 'Filter Locations',
                selectAll: 'Select all',
            },
        },
        aufbau: 'Assembling',
        siegel: 'Seal',
        preis: 'Price',
        preisVon: 'Price from',
        preisBis: 'Price till',
        rate: 'Monthly rate',
        rateVon: 'Monthly rate from',
        rateBis: 'Monthly rate till',
        ez: 'First registration',
        ezVon: 'First registration from',
        ezBis: 'First registration till',
        km: 'Mileage',
        kmVon: 'Mileage from',
        kmBis: 'Mileage till',
        leistung: 'Power',
        leistungVon: 'Power from',
        leistungBis: 'Power till',
        motor: 'Engine',
        getriebe: 'Transmission',
        sofort: 'Now available',
        sofortActive: 'Only now available',
        tags: 'Campaign',
        fd: 'Financial services',
        aus: 'Features',
        ausHeading: 'Choose Features',
        volltext: 'Full-text search',
        availabileDate: 'Date of availability',
        asc: 'ascendent',
        desc: 'descendent',
        Alle: 'All',
        alle: 'all',
        from: 'from',
        to: 'to',
        moreThan: 'More than',
    },
    footerTextNEFZ: 'Further information on the official fuel consumption and the official specific CO2 emissions of new passenger cars can be found in the \'Guide to fuel consumption and CO2 emissions of new passenger cars\', which is available free of charge from all sales outlets and from {0}.',
    footerTextWLTP: 'The information is provided in accordance with the Passenger Car Energy Consumption Labeling Ordinance. The stated values were determined in accordance with the prescribed measurement procedure WLTP (World Harmonized Light Vehicles Test Procedure). The fuel consumption and C02 emissions of a car depend not only on the efficient use of fuel by the car, but also on driving style and other non-technical factors. C02 is the main greenhouse gas responsible for global warming. A guide to the fuel consumption and C02 emissions of all new passenger car models offered in Germany is available free of charge in electronic form at any point of sale in Germany where new passenger cars are displayed or offered for sale. The guide is also available at the Internet address {0}. Only the C02 emissions caused by the operation of the car are stated. C02 emissions caused or avoided by the production and provision of the passenger car and the fuel or energy source are not taken into account when determining C02 emissions in accordance with the WLTP.',
    opening: {
        closed: 'closed',
        openTill: 'open until {0}',
        openAtToday: 'closed, opens at {0}',
        openAtTomorrow: 'closed, opens tomorrow at {1}',
        openAtOtherDay: 'closed, opens {0} at {1}',
        verkauf: 'Sale',
        service: 'Service',
        werkstatt: 'Workshop',
    },
    time: {
        days: {
            0: 'Sunday',
            1: 'Monday',
            2: 'Tuesday',
            3: 'Wednesday',
            4: 'Thursday',
            5: 'Friday',
            6: 'Saturday',
            singular: 'day',
            plural: 'days',
        },
        month: {
            plural: 'months',
            singular: 'month',
        },
        week: {
            plural: 'weeks',
            singular: 'week',
        },
        year: {
            plural: 'years',
            singular: 'year',
        },
    },
    validation: {
        invalid: 'Invalid!',
        invalidEntry: 'Invalid value! Allowed: {0}',
        maxCarPrice: 'The price is greater than the vehicle price',
        minPrice: 'Price too low',
        required: 'Required',
        wholeNumber: 'Only whole numbers allowed',
    },
};

export default enUS;

import { checkUndefinedNullOrEmpty } from './tools';

export function stringToSlug(str = ''): string {
    if (checkUndefinedNullOrEmpty(str)) {
        return null;
    }
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.replace(/ß/g, 'ss'); // replace ß
    str = str.replace(/[?!]/g, ''); // remove special characters
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = 'àáãäâèéëêìíïîòóöôùúüûñçš';
    const to = 'aaaaaeeeeiiiioooouuuuncs';

    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^A-z0-9/]/g, '-') // remove all except alphanumerics & replace all with '-'
        .replace(/(?!^)\//g, '-') // replace non-leading slashes
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

import { alpha, Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function backdrop(theme: Theme): Components<Theme> {
    return {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        backgroundColor: alpha(theme.palette.grey[900], 0.8),
                    },
                },
                invisible: {
                    [getStyleQuery()]: {
                        background: 'transparent',
                    },
                },
            },
        },
    };
}

/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configGlockner: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30170,
        isGruppe: true,
        enable: true,
        projectName: 'Glöckner Automobile',
        searchPath: '/sofort-verfuegbare-fahrzeuge.html',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz.html',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'standort', 'preis', 'rate', 'ez', 'aus'],
        },
        hideHeading: false,
    },
    api: {
        ...configDefault.api,
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Open Sans", sans-serif',
        fontSize: 15,
        scrollOffset: 90,
        muiComponents: () => ({
            MuiButton: {
                styleOverrides: {
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            '&#detailview2_button_exposee': {
                                backgroundColor: '#e1e3e8',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            height: '56px',
                            width: '56px',
                        },
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            background: '#fff',
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            background: '#fff',
                        },
                    },
                    notchedOutline: {
                        [getStyleQuery()]: {
                            width: '100% !important',
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.LocationUserCard > button.MuiButton-contained': {
                                width: '100%',
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.FahrzeugDetailsToolbar_Container': {
                                maxWidth: '130px',
                            },
                            '&[data-testid="FahrzeugSucheFilterChips_results"] h6.MuiTypography-subtitle1': {
                                color: '#242424',
                            },
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.Mui-selected': {
                                'outline': 'none !important',
                                '&:focus, &:hover': {
                                    textDecoration: 'none',
                                },
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    margin: 0,
                },
            },
            body2: {
                [getStyleQuery()]: {
                    fontWeight: 600,
                },
            },
            button: {
                [getStyleQuery()]: {
                    textTransform: 'uppercase',
                },
            },
            caption: {
                [getStyleQuery()]: {
                    margin: 0,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontWeight: 600,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontWeight: 600,
                    margin: 0,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontWeight: 600,
                    margin: '5px 0px',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontWeight: 600,
                    margin: 0,
                },
            },
        }),
        primaryColor: '#46516F',
    },
};

export default configGlockner;

import { Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';
import { menuItem } from '../../css';

// ----------------------------------------------------------------------

export function menu(theme: Theme): Components<Theme> {
    return {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        ...menuItem(theme),
                    },
                },
            },
        },
    };
}

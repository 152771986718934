/**
 * @file Handles all configurations for the homepage tools.
 * @author Arne Rief
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { getStyleQuery } from '../../utils/tools';

const configKuhnWitte: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30156,
        isGruppe: true,
        enable: true,
        metaTitleSuffix: 'Autohaus Kuhn+Witte',
        metaTitleSeperator: ' - ',
        projectName: 'Autohaus Kuhn+Witte',
        searchPath: '/fahrzeuge',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            offsetX: 2,
            offsetY: 12,
            position: 'right',
        },
        isLeadSystem: true,
        linkDisclaimer: '/privacy',
    },
    api: {
        ...configDefault.api,
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'typ', 'km', 'standort', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'siegel', 'tags', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'km', 'standort', 'preis', 'rate', 'ez', 'siegel', 'tags', 'aus'],
        },
        hideHeading: false,
        topFilter: true,
        useEyecatcher: true,
    },
    style: {
        ...configDefault.style,
        background: '#fafafa',
        borderRadius: 1,
        fontFamily: '"Audi Type", "Manrope", "Helvetica", sans-serif',
        fontSize: 14,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'fontSize': '16px !important',
                            'fontWeight': 600,
                            'lineHeight': '1.5em',
                            '&:hover': {
                                background: '#36bcfa',
                            },
                            '& > svg': {
                                width: '21px',
                                height: '21px',
                            },
                        },
                    },
                    label: {
                        [getStyleQuery()]: {
                            fontSize: '14px',
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            fontWeight: 600,
                            letterSpacing: 'normal',
                            lineHeight: '1.5em',
                            padding: '12px 24px',
                        },
                    },
                    contained: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                backgroundColor: '#36bcfa !important',
                                lineHeight: '1.5em',
                                padding: '12px 24px',
                            },
                        },
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            'color': '#fff !important',
                            'backgroundColor': '#000',
                            // contact buttons next to fabs
                            '&[data-testid^="UseContact_ContactTextButton_"]': {
                                'backgroundColor': 'rgba(0, 135, 208, 0.16) !important',
                                'border': 'none !important',
                                'color': '<colorPrimary> !important',
                                'fontSize': '14px !important',
                                'height': '30px !important',
                                'padding': '13px 8px !important',
                                'textTransform': 'none !important',
                                '& > span': {
                                    height: '30px !important',
                                },
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 135, 208, 0.32) !important',
                                    border: 'none !important',
                                    boxShadow: '0 8px 16px 0 rgba(0, 135, 208, 0.24) !important',
                                },
                            },
                            '&:hover': {
                                backgroundColor: '#555555',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                background: '#36bcfa !important',
                            },
                            '& > svg': {
                                width: '21px',
                                height: '21px',
                            },
                        },
                    },
                },
            },
            MuiInputAdornment: {
                styleOverrides: {
                    root: {
                        'height': 'unset !important',
                        '& svg': {
                            maxWidth: 'unset !important',
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        [getStyleQuery()]: {
                            textIndent: 'unset !important',
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    lineHeight: '1.5em',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    color: '#272727',
                    fontWeight: 600,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    color: '#272727',
                    fontWeight: 600,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    color: '#272727',
                    fontWeight: 600,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    color: '#272727',
                    fontWeight: 600,
                },
            },
        }),
        primaryColor: '#0087D0',
    },
};

export default configKuhnWitte;

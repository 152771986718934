/**
 * @file Handles all configurations for the homepage tools.
 * @author Arne Rief
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { getStyleQuery } from '../../utils/tools';

const configAZF: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54781,
        enable: true,
        metaTitleSuffix: 'AZF Unternehmensgruppe',
        metaTitleSeperator: ' | ',
        projectName: 'AZF',
        searchPath: '/fahrzeuge',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/kontakt/datenschutzerklaerung',
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['hersteller', 'modell', 'typ', 'tags', 'sofort', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'rate', 'ez', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]tags', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'rate', 'ez', 'aus'],
        },
        tradeInLink: 'https://www.azf-gruppe.de/fahrzeuge/ankauf',
    },
    schnellsuche: {
        ...configDefault.schnellsuche,
        noAnimations: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Barlow", sans-serif',
        fontSize: 16,
        scrollOffset: 80,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'background': '<colorPrimary>',
                            'color': '#fff',
                            'fontWeight': 700,
                            'transition': 'opacity 0.2s ease-in',
                            '&[data-testid="UseContact_ContactButton_tel"]::before': { // phone button broken otherwise
                                content: 'none',
                            },
                            '&:hover': {
                                backgroundColor: '#a20028',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'padding': '6px 12px',
                            'transition': 'opacity 0.2s',
                            '&#detailview2_button_share': {
                                padding: '6px 12px',
                            },
                            '&[data-testid="UseContact_Dialog_Submit"]': {
                                padding: '6px 12px',
                            },
                            '&:hover': {
                                boxShadow: 'none',
                                opacity: 0.8,
                            },
                        },
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                boxShadow: 'none',
                            },
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            'color': '<colorPrimary>',
                            '&::before': {
                                content: 'none',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'transition': 'opacity 0.2s ease-in',
                            'padding': '0 !important',
                            '&[data-testid="UseContact_ContactButton_tel"] > svg': {
                                color: '#fff',
                            },
                            '&::before': { // phone button broken otherwise
                                content: 'none',
                            },
                            '&:hover': {
                                opacity: 1,
                            },
                        },
                    },
                },
            },
            MuiGrid2: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& p.MuiTypography-body1': {
                                fontWeight: 400,
                            },
                        },
                    },
                },
            },
            MuiInputAdornment: {
                styleOverrides: {
                    positionEnd: {
                        [getStyleQuery()]: {
                            paddingRight: '14px',
                        },
                    },
                    positionStart: {
                        [getStyleQuery()]: {
                            paddingLeft: '14px',
                        },
                    },
                },
            },
            MuiListItemText: {
                styleOverrides: {
                    secondary: {
                        [getStyleQuery()]: {
                            '&[data-testid$="_Title_Secondary"]': { // Car Name
                                color: '<colorPrimary>',
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="Schnellsuche_Container"]': {
                                background: '#fff',
                            },
                            '&[data-testid="CarPriceGrid_Container"] p.MuiTypography-h6, &[data-testid="CarPriceGrid_Container"] p.MuiTypography-h5': {
                                fontWeight: '700',
                                padding: 0,
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    padding: 0,
                },
            },
            body2: {
                [getStyleQuery()]: {
                    lineHeight: 1.5,
                    padding: 0,
                },
            },
            button: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
            caption: {
                [getStyleQuery()]: {
                    padding: 0,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontWeight: 400,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontWeight: 400,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontWeight: 400,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontWeight: 400,
                },
            },
        }),
        primaryColor: '#b4002d',
    },
};

export default configAZF;

import { useCallback } from 'react';
import { ConfigObject } from '../_configs/types';
import { actions as settingsFluxSetActions } from '../_flux/slices/Common';
import { useAppDispatch, useAppSelector } from '../_flux/Store';
import { RootState } from '../_flux/Store';

type UseSettingsReturn = {
    setSettings: (newSettings: ConfigObject) => void,
    settings: ConfigObject,
};

export function useSettings(): UseSettingsReturn {
    const settings = useAppSelector(function (state: RootState) { return state?.common?.settings; });
    const dispatch = useAppDispatch();

    const setSettings = useCallback((newSettings: ConfigObject) => {
        dispatch(settingsFluxSetActions.setSettings(newSettings));
    }, [dispatch]);

    return {
        settings,
        setSettings,
    };
}

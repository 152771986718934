import { tabClasses } from '@mui/material/Tab';
import { Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function tabs(theme: Theme): Components<Theme> {
    return {
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    [getStyleQuery()]: {
                        backgroundColor: theme.palette.text.primary,
                    },
                },
                scrollButtons: {
                    [getStyleQuery()]: {
                        width: 48,
                        borderRadius: '50%',
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        'padding': 0,
                        'opacity': 1,
                        'minWidth': 48,
                        'minHeight': 48,
                        'fontWeight': theme.typography.fontWeightSemiBold,
                        '&:not(:last-of-type)': {
                            marginRight: theme.spacing(3),
                            [theme.breakpoints.up('sm')]: {
                                marginRight: theme.spacing(5),
                            },
                        },
                        [`&:not(.${tabClasses.selected})`]: {
                            color: theme.palette.text.secondary,
                        },
                    },
                },
            },
        },
    };
}

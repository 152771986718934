/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { getStyleQuery } from '../../utils/tools';

const configKoch: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 53920,
        enable: true,
        metaTitleSeperator: '-',
        metaTitleSuffix: 'Autohaus Koch',
        projectName: 'Autohaus Koch',
        searchPath: '/gebrauchtwagen',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            offsetY: 14,
        },
        isLeadSystem: false,
        linkDisclaimer: '/datenschutz',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        topFilter: true,
        tradeInLink: 'https://gib-uns-deinen-gebrauchten.de/ahkoch',
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"open-sans", Helvetica, sans-serif !important',
        fontSize: 15,
        scrollOffset: 230,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            color: '#fff',
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            color: '#fff',
                        },
                    },
                },
            },
        }),
        primaryColor: '#969696',
    },
};

export default configKoch;

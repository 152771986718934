import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { Lang } from '../_configs/types';
import { LOCAL_STORAGE_LANG } from '../vars';
import deLocalesCustomer from './customerTexts/de-DE';
import enLocalesCustomer from './customerTexts/en-US';
import frLocalesCustomer from './customerTexts/fr-FR';
import { TranslationsCustomer } from './customerTexts/translationsCustomer';
import deLocales from './de-DE';
import enLocales from './en-US';
import frLocales from './fr-FR';
import { Translations } from './translations';

declare module 'i18next' {
    interface CustomTypeOptions {
        defaultNS: 'common';
        resources: {
            common: Translations,
            customer: TranslationsCustomer,
        };
    }
}

i18n.use(LanguageDetector).use(initReactI18next).init({
    resources: {
        'de-DE': { common: deLocales, customer: deLocalesCustomer },
        'en-US': { common: enLocales, customer: enLocalesCustomer },
        'fr-FR': { common: frLocales, customer: frLocalesCustomer },
    },
    lng: typeof window !== 'undefined' ? window?.localStorage?.getItem(LOCAL_STORAGE_LANG) || Lang.deDE : Lang.deDE,
    fallbackLng: Lang.deDE,
    ns: ['common'],
    defaultNS: 'common',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;

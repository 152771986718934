import { Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

declare module '@mui/material/styles' {
    interface TypographyVariants {
        fontWeightSemiBold: React.CSSProperties['fontWeight'];
    }
}

// NEW VARIANT
declare module '@mui/material/Link' {
    interface LinkPropsVariantOverrides {
        body1: true;
        body2: false;
        button: false;
        caption: false;
        h1: false;
        h2: false;
        h3: false;
        h4: false;
        h5: false;
        h6: false;
        inherit: false;
        subtitle1: false;
        subtitle2: false;
        overline: false;
    }
}

// NEW VARIANT
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        body1: true;
        body2: true;
        button: false;
        caption: true;
        h1: false;
        h2: false;
        h3: true;
        h4: true;
        h5: true;
        h6: true;
        inherit: false;
        subtitle1: true;
        subtitle2: false;
        overline: false;
    }
}

// ----------------------------------------------------------------------

export function typography(theme: Theme): Components<Theme> {
    return {
        MuiTypography: {
            styleOverrides: {
                paragraph: {
                    [getStyleQuery()]: {
                        marginBottom: theme.spacing(2),
                    },
                },
                gutterBottom: {
                    [getStyleQuery()]: {
                        marginBottom: theme.spacing(1),
                    },
                },
            },
        },
    };
}

/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configSchulzStraube: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 48921,
        enable: true,
        projectName: 'Autohaus Schulz & Straube',
        searchPath: '/fahrzeugverkauf/autohaus-fahrzeugboerse',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            position: 'left',
        },
        isLeadSystem: false,
        linkDisclaimer: '/datenschutz',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'aktion', 'hersteller', 'modell', 'aufbau', 'typ', 'km', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus', 'tags'],
            top: ['[SM]hersteller', '[SM]modell', '[SM]sofort', '[SM]aktion', '[LG]typ', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'rate', 'ez', 'aus', 'tags'],
        },
        hideHeading: false,
    },
    module: {
        ...configDefault.module,
        aktionsslider: true,
        schnellsuche: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Roboto Condensed", "Open Sans", "Arial", sans-serif',
        fontSize: 16,
        scrollOffset: 120,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    'div.UseContact_ContactTextButtonContainer': { // anti-transparency box around contact buttons
                        borderRadius: '5px',
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                background: '<colorSecondary>',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'borderRadius': '5px',
                            '&:hover': {
                                background: '<colorSecondary>',
                                boxShadow: 'none',
                                color: '#fff',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            borderRadius: '5px',
                        },
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            borderRadius: '5px',
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    color: '<colorSecondary>',
                },
            },
        }),
        primaryColor: '#ee042e',
        secondaryColor: '#1e283a',
    },
};

export default configSchulzStraube;


import { DialogProps } from '@mui/material/Dialog';
import { Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function dialog(theme: Theme): Components<Theme> {
    return {
        MuiDialog: {
            styleOverrides: {
                paper: ({ ownerState }: { ownerState: DialogProps }) => ({
                    [getStyleQuery()]: {
                        boxShadow: theme.customShadows.dialog,
                        borderRadius: theme.shape.borderRadius * 2,
                        ...(!ownerState.fullScreen && {
                            margin: theme.spacing(2),
                        }),
                    },
                }),
                paperFullScreen: {
                    [getStyleQuery()]: {
                        borderRadius: 0,
                    },
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        padding: theme.spacing(3),
                    },
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        padding: theme.spacing(0, 3),
                    },
                },
                dividers: {
                    [getStyleQuery()]: {
                        borderTop: 0,
                        borderBottomStyle: 'dashed',
                        paddingBottom: theme.spacing(3),
                    },
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        'padding': theme.spacing(3),
                        '& > :not(:first-of-type)': {
                            marginLeft: theme.spacing(1.5),
                        },
                    },
                },
            },
        },
    };
}

/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configSchmeinkDev: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        enable: true,
        idFirma: 54632,
        metaTitleSuffix: 'Schmeink Gruppe',
        metaTitleSeperator: ' - ',
        projectName: 'Schmeink Gruppe',
        searchPath: '/fahrzeugsuche',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: false,
        linkDisclaimer: '/',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'preis', 'fd', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'standort', 'preis', 'fd', 'rate', 'ez', 'aus'],
        },
        hideHeading: false,
        listView: 'list',
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 1.75,
        fontFamily: '"Roboto", "Open Sans", "Arial", sans-serif',
        fontSize: 15,
        scrollOffset: 180,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                backgroundColor: '#30353f',
                            },
                        },
                    },
                },
            },
            MuiCssBaseline: {
                styleOverrides: {
                    'a': {
                        '&#back_to_top.on': { // Scroll-to-top-Button
                            visibility: 'hidden',
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        [getStyleQuery()]: {
                            '& > legend > span': {
                                fontSize: '1em !important',
                            },
                        },
                    },
                },
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'div.MuiGrid-root > div.MuiPaper-root > div.MuiStack-root > div.MuiStack-root': {
                                width: '100%',
                            },
                        },
                    },
                },
            },
        }),
        primaryColor: '#363B47',
    },
};

export default configSchmeinkDev;

/**
 * @file Handles all configurations for the homepage tools.
 * @author Daniel Rittrich
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configThomasExclusive: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30181,
        isGruppe: true,
        enable: true,
        metaTitlePrefix: 'Thomas Exclusive Cars',
        projectName: 'Thomas Exclusive Cars',
        searchPath: '/fahrzeugboerse',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            offsetY: 6,
        },
        isLeadSystem: false,
        linkDisclaimer: '/datenschutzerklaerung',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        hideContactPersons: true,
        hideHeading: false,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Optima", sans-serif',
        fontSize: 15,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                background: '<colorSecondary>',
                            },
                        },
                    },
                },
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin': {
                                color: '#fff',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                background: '<colorSecondary>',
                            },
                        },
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            // contact buttons next to fabs
                            '&[data-testid^="UseContact_ContactTextButton_"]': {
                                fontSize: '15px !important',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                background: '<colorSecondary>',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            button: {
                [getStyleQuery()]: {
                    fontWeight: 400,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
        }),
        primaryColor: '#000',
        secondaryColor: '#4a4a4a',
    },
};

export default configThomasExclusive;

import { ratingClasses } from '@mui/material/Rating';
import { alpha, Components, Theme } from '@mui/material/styles';
import { svgIconClasses } from '@mui/material/SvgIcon';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function rating(theme: Theme): Components<Theme> {
    return {
        MuiRating: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        [`&.${ratingClasses.disabled}`]: {
                            opacity: 0.48,
                        },
                    },
                },
                iconEmpty: {
                    [getStyleQuery()]: {
                        color: alpha(theme.palette.grey[500], 0.48),
                    },
                },
                sizeSmall: {
                    [getStyleQuery()]: {
                        [`& .${svgIconClasses.root}`]: {
                            width: 20,
                            height: 20,
                        },
                    },
                },
                sizeMedium: {
                    [getStyleQuery()]: {
                        [`& .${svgIconClasses.root}`]: {
                            width: 24,
                            height: 24,
                        },
                    },
                },
                sizeLarge: {
                    [getStyleQuery()]: {
                        [`& .${svgIconClasses.root}`]: {
                            width: 28,
                            height: 28,
                        },
                    },
                },
            },
        },
    };
}

/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject, Lang } from '../types';

const configBrass: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54440,
        enable: true,
        language: Lang.frFr,
        metaTitlePrefix: 'Auto Brass Allemande',
        metaTitleSeperator: '-',
        projectName: 'Auto Brass',
        searchPath: '/decouvrir-les-occasions',
        useOldRouter: true,
    },
    cartile: {
        ...configDefault.cartile,
        infoGrid: {
            ...configDefault.cartile.infoGrid,
            KRAD: ['ez', 'km', '[L]ident', 'engine.leistung', 'engine.motor', 'getriebe', '[L]farbe', '[L]antrieb', 'spacer'],
            default: ['ez', 'km', '[L]ident', 'engine.leistung', 'engine.motor', 'getriebe', '[L]farbe', '[L]antrieb', 'spacer'],
        },
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            offsetY: 12,
        },
        isLeadSystem: true,
        linkDisclaimer: '/',
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugdetail: {
        ...configDefault.fahrzeugdetail,
        infoGrid: {
            ...configDefault.fahrzeugdetail.infoGrid,
            KRAD: ['ident', 'ez', 'km', 'farbe', 'engine.motor', 'getriebe', 'engine.leistung', 'antrieb'],
            default: ['ident', 'ez', 'km', 'farbe', 'engine.motor', 'getriebe', 'engine.leistung', 'antrieb'],
        },
        additionalInfo: [
            {
                content: 'customer:54440.additionalInfo.0',
                icon: 'mdi:shield-half-full',
            },
            {
                content: 'customer:54440.additionalInfo.1',
                icon: 'mdi:piggy-bank',
            },
            {
                content: 'customer:54440.additionalInfo.2',
                icon: 'mdi:cash-remove',
            },
        ],
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            ...configDefault.fahrzeugsuche.filter,
            sidebar: ['hersteller', 'modell', 'aufbau', 'preis', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]motor', '[LG]getriebe', 'aufbau', 'preis', 'ez', 'aus'],
        },
        hideHeading: false,
        topFilter: true,
    },
    api: {
        ...configDefault.api,
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    style: {
        ...configDefault.style,
        background: '#f6f6f6',
        borderRadius: 2.25, // = 10px
        fontFamily: '"Futura Now Text", Helvetica, sans-serif',
        fontSize: 16,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    [getStyleQuery()]: {
                        'div[data-testid="FahrzeugSucheFilterTopArea_Container"] label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': { // input labes filter topbar
                            background: '#f6f6f6 !important',
                        },
                    },
                    'button.cmplz-btn.cmplz-manage-consent.cmplz-show': { // cookie button
                        bottom: '62px !important',
                    },
                    'div.FahrzeugSucheFilterSort_Container > button.MuiButton-text.MuiButton-textInherit': { // sort & view buttons
                        color: '#222733',
                    },
                    'div.UseContact_ContactTextButtonContainer': { // anti-transparency box around contact buttons
                        borderRadius: '30px !important',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'borderRadius': '30px',
                            'color': '#fff !important',
                            '&.MuiButton-containedSizeLarge': {
                                padding: '18px 16px',
                            },
                            '&.MuiButton-containedSizeMedium': {
                                padding: '8px 12px 6px 12px',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            'background': 'transparent',
                            'border': '1px solid #909fb0 !important',
                            'borderRadius': '30px',
                            'color': '<colorPrimary>',
                            '&.MuiButton-outlinedSizeMedium': {
                                padding: '8px 12px 6px 12px',
                            },
                            '&.Mui-disabled, &.Mui-disabled:hover': {
                                background: 'transparent !important',
                                border: '1px solid #7a7e83 !important',
                                color: '#7a7e83 !important',
                            },
                            '&:hover': {
                                background: '#e6e9eb',
                                border: '1px solid <colorPrimary> !important',
                                boxShadow: 'none !important',
                            },
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            'borderRadius': '30px',
                            '&.MuiButton-softSizeMedium': {
                                padding: '8px 12px 6px 12px',
                            },
                            '&.MuiButton-softSizeSmall': {
                                padding: '8px 8px 6px 8px',
                            },
                        },
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            marginTop: '0 !important',
                            marginBottom: '0 !important',
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'background': 'none',
                            'padding': '10px',
                            '&:hover': {
                                background: 'rgba(99, 115, 129, 0.08)',
                            },
                        },
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    outlined: {
                        [getStyleQuery()]: {
                            background: '#fff !important',
                            transform: 'translate(14px, -13px) scale(0.75) !important',
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"Futura Now Headline", ' + '<fontFamily>',
                    fontWeight: 500,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"Futura Now Headline", ' + '<fontFamily>',
                    fontWeight: 500,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"Futura Now Headline", ' + '<fontFamily>',
                    fontWeight: 500,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"Futura Now Headline", ' + '<fontFamily>',
                    fontWeight: 500,
                },
            },
        }),
        primaryColor: '#2c4b6c',
    },
};

export default configBrass;

/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Daniel Rittrich
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configBestSR: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30180,
        isGruppe: true,
        enable: true,
        projectName: 'Best',
        searchPath: '/fahrzeugsuche',
        showroooom: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
    },
    aktionsslider: {
        ...configDefault.aktionsslider,
        forcedFilter: {
            ...configDefault.aktionsslider.forcedFilter,
            ignoreFirma: [30997, 51530, 52590, 52890, 54622, 54623, 54624],
        },
    },
    erweiterteSchnellsuche: {
        ...configDefault.erweiterteSchnellsuche,
        forcedFilter: {
            ...configDefault.erweiterteSchnellsuche.forcedFilter,
            ignoreFirma: [30997, 51530, 52590, 52890, 54622, 54623, 54624],
        },
    },
    fahrzeugslider: {
        ...configDefault.fahrzeugslider,
        forcedFilter: {
            ...configDefault.fahrzeugslider.forcedFilter,
            ignoreFirma: [30997, 51530, 52590, 52890, 54622, 54623, 54624],
        },
    },
    finanzierungsansicht: {
        ...configDefault.finanzierungsansicht,
        forcedFilter: {
            ...configDefault.finanzierungsansicht.forcedFilter,
            ignoreFirma: [30997, 51530, 52590, 52890, 54622, 54623, 54624],
        },
    },
    schnellsuche: {
        ...configDefault.schnellsuche,
        forcedFilter: {
            ...configDefault.schnellsuche.forcedFilter,
            ignoreFirma: [30997, 51530, 52590, 52890, 54622, 54623, 54624],
        },
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        forcedFilter: {
            ...configDefault.fahrzeugsuche.forcedFilter,
            ignoreFirma: [30997, 51530, 52590, 52890, 54622, 54623, 54624],
        },
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus', 'tags'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'standort', 'preis', 'rate', 'ez', 'aus', 'tags'],
        },
        titleWithDesign: true,
    },
    style: {
        ...configDefault.style,
        fontFamily: '"avemo-light-woff2", sans-serif',
        primaryColor: '#000',
        secondaryColor: '#f07800',
        muiComponents: () => ({
            MuiButton: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'borderRadius': 40,
                            '&:hover': {
                                backgroundColor: '<colorSecondary> !important',
                                color: '#fff !important',
                            },
                        },
                    },
                    contained: {
                        [getStyleQuery()]: {
                            'border': '1px solid <colorPrimary>',
                            '&:hover': {
                                'border': '1px solid <colorSecondary>',
                            },
                        },
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            'border': '1px solid black !important',
                        },
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                background: '<colorSecondary> !important',
                            },
                        },
                    },
                },
            },
            MuiCssBaseline: {
                styleOverrides: {
                    [getStyleQuery()]: {
                        '.UseContact_ContactTextButtonContainer': {
                            'borderRadius': '40px !important',
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                backgroundColor: '<colorSecondary> !important',
                                color: '#fff !important',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h1: {
                [getStyleQuery()]: {
                    color: '#333',
                    fontFamily: '"avemo-bold-woff2", sans-serif',
                    fontWeight: 600,
                },
            },
            h2: {
                [getStyleQuery()]: {
                    color: '#333',
                    fontFamily: '"avemo-bold-woff2", sans-serif',
                    fontWeight: 600,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    color: '#333',
                    fontFamily: '"avemo-bold-woff2", sans-serif',
                    fontWeight: 600,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    color: '#333',
                    fontFamily: '"avemo-bold-woff2", sans-serif',
                    fontWeight: 600,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    color: '#333',
                    fontFamily: '"avemo-bold-woff2", sans-serif',
                    fontWeight: 600,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    color: '#333',
                    fontFamily: '"avemo-bold-woff2", sans-serif',
                    fontWeight: 600,
                },
            },
            button: {
                [getStyleQuery()]: {
                    fontFamily: '"avemo-bold-woff2", sans-serif',
                },
            },
        }),
    },
};

export default configBestSR;

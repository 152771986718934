/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { getStyleQuery } from '../../utils/tools';

const configSturm: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54040,
        enable: true,
        projectName: 'Autozentrale Sturm',
        searchPath: '/fahrzeuge/gebrauchtwagen.html',
        useOldRouter: true,
    },
    module: {
        ...configDefault.module,
        fahrzeugslider: true,
        finanzierungsansicht: true,
        schnellsuche: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/en/metanav/datenschutzerklaerung.html',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        hideHeading: false,
    },
    api: {
        ...configDefault.api,
        tracking: {
            ...configDefault.api.tracking,
            enabled: false,
        },
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Open Sans", sans-serif',
        fontSize: 14,
        primaryColor: '#ce1923',
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& > svg': {
                                height: '21px',
                                width: '21px',
                            },
                        },
                    },
                    label: {
                        [getStyleQuery()]: {
                            fontWeight: 700,
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                color: '#fff',
                            },
                        },
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    label: {
                        [getStyleQuery()]: {
                            fontWeight: 700,
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& > svg': {
                                height: '21px',
                                width: '21px',
                            },
                        },
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    // @ts-ignore
                    labelPlacementEnd: {
                        [getStyleQuery()]: {
                            marginBottom: 0,
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CarPriceGrid_Container"] p.MuiTypography-h5, &[data-testid="CarPriceGrid_Container"] p.MuiTypography-h6': {
                                fontWeight: 700,
                            },
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.Mui-selected': {
                                color: '<colorPrimary>',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontWeight: 400,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontWeight: 400,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontWeight: 400,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    'color': '<colorPrimary>',
                    'fontWeight': 400,
                    '&.MuiListItemText-secondary': {
                        fontWeight: 700,
                    },
                },
            },
        }),
    },
};

export default configSturm;

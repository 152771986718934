/**
 * @file Handles all configurations for the homepage tools.
 * @author Daniel Rittrich
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { getStyleQuery } from '../../utils/tools';

const configMeyer: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 53770,
        enable: true,
        projectName: 'Auto Meyer',
        searchPath: '/gebrauchtwagen.html',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutzerklaerung.html',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        topFilter: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Open Sans", sans-serif',
        fontSize: 14,
        scrollOffset: 90,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& > svg': {
                                height: '21px',
                                width: '21px',
                            },
                            '&:hover': {
                                backgroundColor: '<colorSecondary>',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                backgroundColor: '#575756 !important',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& > svg': {
                                height: '21px',
                                width: '21px',
                            },
                            '&:hover': {
                                backgroundColor: '<colorSecondary>',
                            },
                        },
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            marginBottom: 0,
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                },
            },
            button: {
                [getStyleQuery()]: {
                    fontFamily: '"Open Sans", Arial, sans-serif',
                    fontWeight: 400,
                },
            },
            caption: {
                [getStyleQuery()]: {
                    color: '#637371',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontFamily: '"Open Sans", Arial, sans-serif',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontFamily: '"Open Sans", Arial, sans-serif',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontFamily: '"Open Sans", Arial, sans-serif',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontFamily: '"Open Sans", Arial, sans-serif',
                },
            },
        }),
        primaryColor: '#004899',
        secondaryColor: '#2b56a3',
    },
};

export default configMeyer;

import { Breakpoint, Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

export function grid(theme: Theme): Components<Theme> {
    const widthClasses = {};
    (['xs', 'sm', 'md', 'lg', 'xl'] as Breakpoint[]).map((b) => {
        for (let i = 1; i <= 12; i++) {
            widthClasses[`grid-${b}-${i}`] = {
                [getStyleQuery()]: {
                    flexBasis: `${(100 / 12) * i}%`,
                    flexGrow: 0,
                    maxWidth: `${(100 / 12) * i}%`,
                },
                [getStyleQuery()]: {
                    [theme?.breakpoints?.up(b)]: {
                        flexBasis: 'unset',
                        flexGrow: 'unset',
                        maxWidth: 'unset',
                    },
                },
                [getStyleQuery()]: {
                    [theme?.containerQueries?.up(b)]: {
                        flexBasis: `${(100 / 12) * i}%`,
                        flexGrow: 0,
                        maxWidth: `${(100 / 12) * i}%`,
                    },
                },
            };
        }
    });

    return {
        MuiGrid: {
            styleOverrides: {
                ...widthClasses,
                container: {
                    containerType: 'inline-size',
                },
            },
        },
    };
}

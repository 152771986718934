import { Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function radio(theme: Theme): Components<Theme> {
    return {
        // CHECKBOX, RADIO, SWITCH
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    [getStyleQuery()]: {
                        fontWeight: 700,
                    },
                },
            },
        },

        MuiRadio: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        'padding': theme.spacing(1),
                        '& > input.PrivateSwitchBase-input': {
                            opacity: '0 !important',
                        },
                    },
                },
            },
        },
    };
}

import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function select() {
    return {
        MuiSelect: {
            styleOverrides: {
                icon: {
                    [getStyleQuery()]: {
                        right: 10,
                        width: 18,
                        height: 18,
                        top: 'calc(50% - 9px)',
                    },
                },
                nativeInput: {
                    [getStyleQuery()]: {
                        opacity: '0 !important',
                    },
                },
            },
        },
        MuiNativeSelect: {
            styleOverrides: {
                icon: {
                    [getStyleQuery()]: {
                        right: 10,
                        width: 18,
                        height: 18,
                        top: 'calc(50% - 9px)',
                    },
                },
            },
        },
    };
}

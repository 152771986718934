/**
 * @file Handles all configurations for the homepage tools.
 * @author Arne Rief
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configManz: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 11910,
        enable: true,
        metaTitleSuffix: 'Autohaus Manz',
        metaTitleSeperator: ' - ',
        projectName: 'Autohaus Manz',
        searchPath: '/gebrauchtwagen',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: false,
        linkDisclaimer: '/datenschutz.html',
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        hideHeading: false,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: 'regular',
        fontSize: 14,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'color': '#fff',
                            '&:hover': {
                                background: 'linear-gradient(to left, #f3f3f3, <colorPrimary>)',
                            },
                            '& > svg': {
                                height: '21px',
                                width: '21px',
                            },
                        },
                    },
                    label: {
                        fontFamily: '"700", "500", ' + '<fontFamily>',
                        fontWeight: 700,
                    },
                },
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'outline': 'none !important',
                            '&.MuiFab-root.MuiFab-circular.MuiFab-primary': {
                                '&:hover': {
                                    background: 'linear-gradient(to left, #f3f3f3, <colorPrimary>) !important',
                                },
                            },
                            '&.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin': {
                                '&:hover': {
                                    background: 'linear-gradient(to left, #f3f3f3, <colorPrimary>) !important',
                                },
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'background': '<colorPrimary> !important',
                            'borderRadius': '0 !important',
                            'color': '#fff !important',
                            '&:hover': {
                                background: 'linear-gradient(to left, #f3f3f3, <colorPrimary>) !important',
                            },
                        },
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    // @ts-ignore
                    labelPlacementEnd: {
                        [getStyleQuery()]: {
                            marginBottom: 0,
                        },
                    },
                },
            },
            MuiInputAdornment: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            display: 'flex !important',
                            alignItems: 'center !important',
                            justifyContent: 'center !important',
                        },
                        '& svg': {
                            maxHeight: '21px !important',
                        },
                    },
                },
            },
            MuiGrid2: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                           '&[data-testid="CarDetailsGrid_Container"] > div.MuiStack-root.MuiGrid2-root': {
                                textAlign: 'left',
                           },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"700", "500", ' + '<fontFamily>',
                    color: '<colorPrimary>',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"700", "500", ' + '<fontFamily>',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"700", "500", ' + '<fontFamily>',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"700", "500", ' + '<fontFamily>',
                },
            },
        }),
        primaryColor: '#b3ccd1',
    },
};

export default configManz;

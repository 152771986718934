import { getStyleQuery } from '../../../utils/tools';

export const slickSliderStyles = {
    [getStyleQuery('.slick-slider', true)]: {
        position: 'relative',
        display: 'block',
        boxSizing: 'border-box',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        MsUserSelect: 'none',
        userSelect: 'none',
        WebkitTouchCallout: 'none',
        KhtmlUserSelect: 'none',
        MsTouchAction: 'pan-y',
        touchAction: 'pan-y',
        WebkitTapHighlightColor: 'transparent',
    },
    [getStyleQuery('.slick-list', true)]: {
        position: 'relative',
        display: 'block',
        overflow: 'hidden',
        margin: 0,
        padding: 0,
    },
    [getStyleQuery('.slick-list:focus', true)]: {
        outline: 'none',
    },
    [getStyleQuery('.slick-list.dragging', true)]: {
        cursor: 'hand',
    },
    [getStyleQuery('.slick-slider .slick-track', true)]: {
        WebkitTransform: 'translate3d(0, 0, 0)',
        MozTransform: 'translate3d(0, 0, 0)',
        MsTransform: 'translate3d(0, 0, 0)',
        OTransform: 'translate3d(0, 0, 0)',
        transform: 'translate3d(0, 0, 0)',
    },
    [getStyleQuery('.slick-slider .slick-list', true)]: {
        WebkitTransform: 'translate3d(0, 0, 0)',
        MozTransform: 'translate3d(0, 0, 0)',
        MsTransform: 'translate3d(0, 0, 0)',
        OTransform: 'translate3d(0, 0, 0)',
        transform: 'translate3d(0, 0, 0)',
    },
    [getStyleQuery('.slick-track', true)]: {
        position: 'relative',
        top: 0,
        left: 0,
        display: 'block',
        margin: 0,
    },
    [getStyleQuery('.slick-track:before', true)]: {
        display: 'table',
        content: '""',
    },
    [getStyleQuery('.slick-track:after', true)]: {
        display: 'table',
        content: '""',
    },
    [getStyleQuery('.slick-track:after', true)]: {
        clear: 'both',
    },
    [getStyleQuery('.slick-loading .slick-track', true)]: {
        visibility: 'hidden',
    },
    [getStyleQuery('.slick-slide', true)]: {
        display: 'none',
        float: 'left',
        height: '100%',
        minHeight: 1,
    },
    [getStyleQuery('[dir=\'rtl\'] .slick-slide', true)]: {
        float: 'right',
    },
    [getStyleQuery('.slick-slide img', true)]: {
        display: 'block',
    },
    [getStyleQuery('.slick-slide.slick-loading img', true)]: {
        display: 'none',
    },
    [getStyleQuery('.slick-slide.dragging img', true)]: {
        pointerEvents: 'none',
    },
    [getStyleQuery('.slick-initialized .slick-slide', true)]: {
        display: 'block',
    },
    [getStyleQuery('.slick-loading .slick-slide', true)]: {
        visibility: 'hidden',
    },
    [getStyleQuery('.slick-vertical .slick-slide', true)]: {
        display: 'block',
        height: 'auto',
        border: '1px solid transparent',
    },
    [getStyleQuery('.slick-arrow.slick-hidden', true)]: {
        display: 'none',
    },
    [getStyleQuery('.slick-loading .slick-list', true)]: {
        background: '#fff url(\'./ajax-loader.gif\') center center no-repeat',
    },
    '@font-face': {
        fontFamily: 'slick',
        fontWeight: 'normal',
        fontStyle: 'normal',
        src: 'url(\'./fonts/slick.eot?#iefix\') format(\'embedded-opentype\'), url(\'./fonts/slick.woff\') format(\'woff\'), url(\'./fonts/slick.ttf\') format(\'truetype\'), url(\'./fonts/slick.svg#slick\') format(\'svg\')',
    },
    [getStyleQuery('.slick-prev', true)]: {
        fontSize: 0,
        lineHeight: 0,
        position: 'absolute',
        top: '50%',
        display: 'block',
        width: 20,
        height: 20,
        padding: 0,
        WebkitTransform: 'translate(0, -50%)',
        MsTransform: 'translate(0, -50%)',
        transform: 'translate(0, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    },
    [getStyleQuery('.slick-next', true)]: {
        fontSize: 0,
        lineHeight: 0,
        position: 'absolute',
        top: '50%',
        display: 'block',
        width: 20,
        height: 20,
        padding: 0,
        WebkitTransform: 'translate(0, -50%)',
        MsTransform: 'translate(0, -50%)',
        transform: 'translate(0, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    },
    [getStyleQuery('.slick-prev:hover', true)]: {
        color: 'transparent',
        outline: 'none',
        background: 'transparent',
    },
    [getStyleQuery('.slick-prev:focus', true)]: {
        color: 'transparent',
        outline: 'none',
        background: 'transparent',
    },
    [getStyleQuery('.slick-next:hover', true)]: {
        color: 'transparent',
        outline: 'none',
        background: 'transparent',
    },
    [getStyleQuery('.slick-next:focus', true)]: {
        color: 'transparent',
        outline: 'none',
        background: 'transparent',
    },
    [getStyleQuery('.slick-prev:hover:before', true)]: {
        opacity: 1,
    },
    [getStyleQuery('.slick-prev:focus:before', true)]: {
        opacity: 1,
    },
    [getStyleQuery('.slick-next:hover:before', true)]: {
        opacity: 1,
    },
    [getStyleQuery('.slick-next:focus:before', true)]: {
        opacity: 1,
    },
    [getStyleQuery('.slick-prev.slick-disabled:before', true)]: {
        opacity: 0.25,
    },
    [getStyleQuery('.slick-next.slick-disabled:before', true)]: {
        opacity: 0.25,
    },
    [getStyleQuery('.slick-prev:before', true)]: {
        fontFamily: 'slick',
        fontSize: 20,
        lineHeight: 1,
        opacity: 0.75,
        color: 'white',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
    },
    [getStyleQuery('.slick-next:before', true)]: {
        fontFamily: 'slick',
        fontSize: 20,
        lineHeight: 1,
        opacity: 0.75,
        color: 'white',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
    },
    [getStyleQuery('.slick-prev', true)]: {
        left: -25,
    },
    [getStyleQuery('[dir=\'rtl\'] .slick-prev', true)]: {
        right: -25,
        left: 'auto',
    },
    [getStyleQuery('.slick-prev:before', true)]: {
        content: '"←"',
    },
    [getStyleQuery('[dir=\'rtl\'] .slick-prev:before', true)]: {
        content: '"→"',
    },
    [getStyleQuery('.slick-next', true)]: {
        right: -25,
    },
    [getStyleQuery('[dir=\'rtl\'] .slick-next', true)]: {
        right: 'auto',
        left: -25,
    },
    [getStyleQuery('.slick-next:before', true)]: {
        content: '"→"',
    },
    [getStyleQuery('[dir=\'rtl\'] .slick-next:before', true)]: {
        content: '"←"',
    },
    [getStyleQuery('.slick-dotted.slick-slider', true)]: {
        marginBottom: 30,
    },
    [getStyleQuery('.slick-dots', true)]: {
        position: 'absolute',
        bottom: -25,
        display: 'block',
        width: '100%',
        padding: 0,
        margin: 0,
        listStyle: 'none',
        textAlign: 'center',
    },
    [getStyleQuery('.slick-dots li', true)]: {
        position: 'relative',
        display: 'inline-block',
        width: 20,
        height: 20,
        margin: '0 5px',
        padding: 0,
        cursor: 'pointer',
    },
    [getStyleQuery('.slick-dots li button', true)]: {
        fontSize: 0,
        lineHeight: 0,
        display: 'block',
        width: 20,
        height: 20,
        padding: 5,
        cursor: 'pointer',
        color: 'transparent',
        border: 0,
        outline: 'none',
        background: 'transparent',
    },
    [getStyleQuery('.slick-dots li button:hover', true)]: {
        outline: 'none',
    },
    [getStyleQuery('.slick-dots li button:focus', true)]: {
        outline: 'none',
    },
    [getStyleQuery('.slick-dots li button:hover:before', true)]: {
        opacity: 1,
    },
    [getStyleQuery('.slick-dots li button:focus:before', true)]: {
        opacity: 1,
    },
    [getStyleQuery('.slick-dots li button:before', true)]: {
        fontFamily: 'slick',
        fontSize: 6,
        lineHeight: 20,
        position: 'absolute',
        top: 0,
        left: 0,
        width: 20,
        height: 20,
        content: '"•"',
        textAlign: 'center',
        opacity: 0.25,
        color: 'black',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
    },
    [getStyleQuery('.slick-dots li.slick-active button:before', true)]: {
        opacity: 0.75,
        color: 'black',
    },
};

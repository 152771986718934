/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configElbtor: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54150,
        enable: true,
        projectName: 'ELBTOR',
        searchPath: '/service/gebrauchtwagenexperten/fahrzeugsuche',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            offsetY: 12,
        },
        isLeadSystem: false,
        linkDisclaimer: '/datenschutz.html',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        topFilter: true,
    },
    module: {
        ...configDefault.module,
        aktionsslider: true,
        schnellsuche: true,
    },
    api: {
        ...configDefault.api,
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Poppins", "Arial", "Helvetica", sans-serif',
        fontSize: 17,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'color': '#fff',
                            'fontSize': '16px',
                            'lineHeight': '1.6em',
                            '&:hover': {
                                boxShadow: 'none !important',
                                color: '<colorSecondary>',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            'fontSize': '16px',
                            'lineHeight': '1.6em',
                            '&:hover': {
                                border: '1px solid <colorPrimary>',
                                color: '<colorPrimary>',
                            },
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            'fontSize': '16px',
                            // contact buttons next to fabs
                            '&[data-testid^="UseContact_ContactTextButton_"]': {
                                fontSize: '15px',
                            },
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                color: '<colorPrimary>',
                            },
                        },
                    },
                    textError: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                color: '#ff5630 !important',
                            },
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:focus, &:hover': {
                                color: '<colorPrimary>',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                boxShadow: 'none !important',
                                color: '<colorSecondary> !important',
                            },
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.Mui-selected': {
                                color: '<colorPrimary>',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    color: '#3d4450',
                    lineHeight: '1.7em',
                },
            },
        }),
        primaryColor: '#006eb7',
        secondaryColor: '#fcaf3b',
    },
};

export default configElbtor;

import { accordionClasses } from '@mui/material/Accordion';
import { accordionSummaryClasses } from '@mui/material/AccordionSummary';
import { typographyClasses } from '@mui/material/Typography';
import { Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function accordion(theme: Theme): Components<Theme> {
    return {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        backgroundColor: 'transparent',
                        boxShadow: theme.customShadows.z1,
                        [`&.${accordionClasses.expanded}`]: {
                            boxShadow: theme.customShadows.z8,
                            borderRadius: theme.shape.borderRadius,
                            backgroundColor: theme.palette.background.paper,
                        },
                        [`&.${accordionClasses.disabled}`]: {
                            backgroundColor: 'transparent',
                        },
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        paddingLeft: theme.spacing(2),
                        paddingRight: theme.spacing(1),
                        [`&.${accordionSummaryClasses.disabled}`]: {
                            opacity: 1,
                            color: theme.palette.action.disabled,
                            [`& .${typographyClasses.root}`]: {
                                color: 'inherit',
                            },
                        },
                    },
                },
                expandIconWrapper: {
                    [getStyleQuery()]: {
                        color: 'inherit',
                    },
                },
            },
        },
    };
}

/**
 * @file Handles all configurations for the homepage tools.
 * @author Arne Rief
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configSteinhoff: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54782,
        enable: true,
        projectName: 'Autohaus Steinhoff',
        searchPath: '/fahrzeugsuche',
        showroooom: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
    },
    module: {
        ...configDefault.module,
        aktionsslider: true,
        erweiterteSchnellsuche: true,
        fahrzeugslider: true,
        finanzierungsansicht: true,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'typ', 'km', 'preis', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'ez', 'aus'],
        },
    },
    style: {
        ...configDefault.style,
        fontFamily: '"Nunito Sans", "Open Sans", Arial, sans-serif',
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            color: '#fff',
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'color': '#fff !important',
                        },
                    },
                },
            },
        }),
        primaryColor: '#35c3f4',
    },
};

export default configSteinhoff;

/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import configDefault from './_defaultValues';
import { ConfigObject } from './types';

const configLocal: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 10370,
        enable: true,
        isGruppe: true,
        metaTitleSuffix: 'HOMEPAGE-TOOL',
        searchPath: '/fahrzeugsuche',
        searchPathDark: '/dark/fahrzeugsuche',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
    },
    cartile: {
        ...configDefault.cartile,
        badges: ['availableAt', 'aktion'],
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'standort', 'fd', 'aufbau', 'typ', 'km', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'standort', 'fd', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'rate', 'ez', 'aus'],
        },
    },
    module: {
        ...configDefault.module,
        aktionsslider: true,
        erweiterteSchnellsuche: true,
        fahrzeugliste: true,
        fahrzeugslider: true,
        finanzierungsansicht: true,
        schnellsuche: true,
    },
    style: {
        ...configDefault.style,
        background: location?.pathname?.startsWith('/dark') ? '#373f43' : '#fff',
        darkMode: location?.pathname?.startsWith('/dark'),
        primaryColor: location?.pathname?.startsWith('/dark') ? '#65b2e8' : '#0d1b48',
    },
};

export default configLocal;

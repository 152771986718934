import { buttonClasses } from '@mui/material/Button';
import { listClasses } from '@mui/material/List';
import { listItemIconClasses } from '@mui/material/ListItemIcon';
import { paperClasses } from '@mui/material/Paper';
import { tablePaginationClasses } from '@mui/material/TablePagination';
import { Components, Theme, alpha } from '@mui/material/styles';

import { getStyleQuery } from '../../../utils/tools';
import { paper } from '../../css';

// ----------------------------------------------------------------------

export function dataGrid(theme: Theme): Components<Theme> {
    const paperStyles = paper({ theme, dropdown: true });

    return {
        // @ts-expect-error ---
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        borderRadius: 0,
                        borderWidth: 0,
                        [`& .${tablePaginationClasses.root}`]: {
                            borderTop: 0,
                        },
                        [`& .${tablePaginationClasses.toolbar}`]: {
                            height: 'auto',
                        },
                    },
                },
                cell: {
                    [getStyleQuery()]: {
                        borderBottom: `1px dashed ${theme.palette.divider}`,
                    },
                },
                selectedRowCount: {
                    [getStyleQuery()]: {
                        whiteSpace: 'nowrap',
                    },
                },
                columnSeparator: {
                    [getStyleQuery()]: {
                        color: theme.palette.divider,
                    },
                },
                toolbarContainer: {
                    [getStyleQuery()]: {
                        padding: theme.spacing(2),
                        borderBottom: `1px dashed ${theme.palette.divider}`,
                        backgroundColor: theme.palette.background.neutral,
                    },
                },
                paper: {
                    [getStyleQuery()]: {
                        ...paperStyles,
                        padding: 0,
                    },
                },
                menu: {
                    [getStyleQuery()]: {
                        [`& .${paperClasses.root}`]: {
                            ...paperStyles,
                        },
                        [`& .${listClasses.root}`]: {
                            padding: 0,
                            [`& .${listItemIconClasses.root}`]: {
                                minWidth: 0,
                                marginRight: theme.spacing(2),
                            },
                        },
                    },
                },
                columnHeaders: {
                    [getStyleQuery()]: {
                        borderRadius: 0,
                        backgroundColor: theme.palette.background.neutral,
                    },
                },
                panelHeader: {
                    [getStyleQuery()]: {
                        padding: theme.spacing(2),
                    },
                },
                panelFooter: {
                    [getStyleQuery()]: {
                        padding: theme.spacing(2),
                        justifyContent: 'flex-end',
                        borderTop: `dashed 1px ${theme.palette.divider}`,
                        [`& .${buttonClasses.root}`]: {
                            '&:first-of-type': {
                                border: `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
                            },
                            '&:last-of-type': {
                                marginLeft: theme.spacing(1.5),
                                color: theme.palette.background.paper,
                                backgroundColor: theme.palette.text.primary,
                            },
                        },
                    },
                },
                filterForm: {
                    [getStyleQuery()]: {
                        padding: theme.spacing(2),
                    },
                },
                filterFormValueInput: {
                    [getStyleQuery()]: {
                        marginLeft: theme.spacing(2),
                    },
                },
                filterFormColumnInput: {
                    [getStyleQuery()]: {
                        marginLeft: theme.spacing(2),
                    },
                },
                filterFormOperatorInput: {
                    [getStyleQuery()]: {
                        marginLeft: theme.spacing(2),
                    },
                },
            },
        },
    };
}

/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configWerner: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 12040,
        enable: true,
        metaTitlePrefix: 'Automobile Werner in Mittweida & Taura (Burgstädt)',
        metaTitleSeperator: ' - ',
        projectName: 'Automobile Werner',
        searchPath: '/fahrzeuge/gebrauchtwagen/volkswagen-audi',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/j/privacy',
    },
    module: {
        ...configDefault.module,
        aktionsslider: true,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        useEyecatcher: true,
        filter: {
            sidebar: ['sofort', 'aktion', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus', 'tags'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'standort', 'preis', 'rate', 'ez', 'aktion', 'aus', 'tags'],
        },
        idExposee: 1573,
        topFilter: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Open Sans", sans-serif, "google"',
        fontSize: 18,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    'div.UseContact_ContactTextButtonContainer': {
                        display: 'flex',
                    },
                    'div[data-testid="fahrzeugsuche"], div[data-testid="vehicleEinzelansicht"]': {
                        marginTop: '16px !important',
                    },
                    'svg': {
                        minHeight: '18px !important',
                        minWidth: '18px !important',
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    label: {
                        [getStyleQuery()]: {
                            fontSize: '14px',
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            color: '#fff',
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            color: '<colorPrimary>',
                        },
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            borderBottom: '1px dashed rgba(145, 158, 171, 0.2) !important',
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        '& > svg': {
                            height: '21px',
                            width: '21px',
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="backToList"]': {
                                color: '<colorPrimary>',
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="FahrzeugSucheFilterChips_results"] > h6.MuiTypography-subtitle1': { // "xyz Fahrzeuge gefunden"
                                color: '<colorPrimary>',
                            },
                            '&[data-testid="CarPriceGrid_Container"] > hr.MuiDivider-vertical': { // PriceGrid vertical divider
                                borderRight: '1px dashed rgba(145, 158, 171, 0.2)',
                            },
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.Mui-selected': {
                                color: '<colorPrimary>',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    lineHeight: '1.75em',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    textAlign: 'left',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                },
            },
        }),
        primaryColor: '#001E50',
    },
};

export default configWerner;

import { LoadingButtonProps, loadingButtonClasses } from '@mui/lab/LoadingButton';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function loadingButton() {
    return {
        MuiLoadingButton: {
            styleOverrides: {
                root: ({ ownerState }: { ownerState: LoadingButtonProps }) => ({
                    [getStyleQuery()]: {
                        ...(ownerState.variant === 'soft' && {
                            [`& .${loadingButtonClasses.loadingIndicatorStart}`]: {
                                left: 10,
                            },
                            [`& .${loadingButtonClasses.loadingIndicatorEnd}`]: {
                                right: 14,
                            },
                            ...(ownerState.size === 'small' && {
                                [`& .${loadingButtonClasses.loadingIndicatorStart}`]: {
                                    left: 10,
                                },
                                [`& .${loadingButtonClasses.loadingIndicatorEnd}`]: {
                                    right: 10,
                                },
                            }),
                        }),
                    },
                }),
            },
        },
    };
}

import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Modules } from '../_json/modules';
import { checkUndefined, checkUndefinedNullOrEmpty } from '../utils/tools';
import { reducer as aktionssliderReducer } from './slices/Aktionsslider';
import { reducer as commonReducer } from './slices/Common';
import { reducer as erweiterteSchnellsucheReducer } from './slices/ErweiterteSchnellsuche';
import { reducer as fahrzeuglisteReducer } from './slices/Fahrzeugliste';
import { reducer as fahrzeugsliderReducer } from './slices/Fahrzeugslider';
import { reducer as fahrzeugsucheReducer } from './slices/Fahrzeugsuche';
import { reducer as finanzierungsansichtReducer } from './slices/Finanzierungsansicht';
import { reducer as schnellsucheReducer } from './slices/Schnellsuche';
import { FluxStoreCommonContent, FluxStoreModuleContent } from './types';

export const store = configureStore<Omit<Record<Modules, FluxStoreModuleContent>, Modules.ConfigListing | Modules.Contact | Modules.Install | Modules.Print | Modules.Standorte> & { common: FluxStoreCommonContent }>({
    reducer: {
        aktionsslider: aktionssliderReducer,
        common: commonReducer,
        erweiterteSchnellsuche: erweiterteSchnellsucheReducer,
        fahrzeugliste: fahrzeuglisteReducer,
        fahrzeugslider: fahrzeugsliderReducer,
        fahrzeugsuche: fahrzeugsucheReducer,
        finanzierungsansicht: finanzierungsansichtReducer,
        schnellsuche: schnellsucheReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export function getFilter(state: RootState, module: Modules): FluxStoreModuleContent['filter'] { return state?.[module]?.filter; }
export function getFacets(state: RootState, module: Modules): FluxStoreModuleContent['facets'] { return state?.[module]?.facets; }
export function getList(state: RootState, module: Modules): FluxStoreModuleContent['list'] { return state?.[module]?.list; }
export function getLoading(state: RootState, module: Modules): FluxStoreModuleContent['loading'] {
    return (
        (!checkUndefinedNullOrEmpty(state?.[module]?.viewOptions?.car) && checkUndefined(state?.[module]?.car))
        || (checkUndefinedNullOrEmpty(state?.[module]?.viewOptions?.car) && checkUndefined(state?.[module]?.list))
    );
}
export function getNumFound(state: RootState, module: Modules): FluxStoreModuleContent['numFound'] { return state?.[module]?.numFound; }
export function getViewOptions(state: RootState, module: Modules): FluxStoreModuleContent['viewOptions'] { return state?.[module]?.viewOptions; }

export function useAppDispatch() { return useDispatch<AppDispatch>(); }
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

import { Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function list(theme: Theme): Components<Theme> {
    return {
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        color: 'inherit',
                        minWidth: 'auto',
                        marginRight: theme.spacing(2),
                    },
                },
            },
        },
        MuiListItemAvatar: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        minWidth: 'auto',
                        marginRight: theme.spacing(2),
                    },
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        margin: 0,
                    },
                },
                multiline: {
                    [getStyleQuery()]: {
                        margin: 0,
                    },
                },
            },
        },
    };
}

import { Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function stepper(theme: Theme): Components<Theme> {
    return {
        MuiStepConnector: {
            styleOverrides: {
                line: {
                    [getStyleQuery()]: {
                        borderColor: theme.palette.divider,
                    },
                },
            },
        },
    };
}

import { tableCellClasses } from '@mui/material/TableCell';
import { tableRowClasses } from '@mui/material/TableRow';
import { Components, Theme, alpha } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function table(theme: Theme): Components<Theme> {
    return {
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    position: 'relative',
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        [`&.${tableRowClasses.selected}`]: {
                            'backgroundColor': alpha(theme.palette.primary.dark, 0.04),
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.dark, 0.08),
                            },
                        },
                        '&:last-of-type': {
                            [`& .${tableCellClasses.root}`]: {
                                borderColor: 'transparent',
                            },
                        },
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        borderBottomStyle: 'dashed',
                    },
                },
                head: {
                    [getStyleQuery()]: {
                        fontSize: 14,
                        color: theme.palette.text.secondary,
                        fontWeight: theme.typography.fontWeightSemiBold,
                        backgroundColor: theme.palette.background.neutral,
                    },
                },
                stickyHeader: {
                    [getStyleQuery()]: {
                        backgroundColor: theme.palette.background.paper,
                        backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
                    },
                },
                paddingCheckbox: {
                    [getStyleQuery()]: {
                        paddingLeft: theme.spacing(1),
                    },
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    [getStyleQuery()]: {
                        width: '100%',
                    },
                },
                toolbar: {
                    [getStyleQuery()]: {
                        height: 64,
                    },
                },
                actions: {
                    [getStyleQuery()]: {
                        marginRight: 8,
                    },
                },
                select: {
                    [getStyleQuery()]: {
                        'paddingLeft': 8,
                        '&:focus': {
                            borderRadius: theme.shape.borderRadius,
                        },
                    },
                },
                selectIcon: {
                    [getStyleQuery()]: {
                        right: 4,
                        width: 16,
                        height: 16,
                        top: 'calc(50% - 8px)',
                    },
                },
            },
        },
    };
}

import { Components, Theme } from '@mui/material/styles';
import { getStyleQuery } from '../../../utils/tools';

// ----------------------------------------------------------------------

export function breadcrumbs(theme: Theme): Components<Theme> {
    return {
        MuiBreadcrumbs: {
            styleOverrides: {
                separator: {
                    [getStyleQuery()]: {
                        marginLeft: theme.spacing(2),
                        marginRight: theme.spacing(2),
                    },
                },
                li: {
                    [getStyleQuery()]: {
                        'display': 'inline-flex',
                        'margin': theme.spacing(0.25, 0),
                        '& > *': {
                            ...theme.typography.body2,
                        },
                    },
                },
            },
        },
    };
}

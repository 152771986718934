/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configDippel: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 53040,
        enable: true,
        projectName: 'Autopark Dippel',
        searchPath: '/gebrauchtfahrzeuge',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: false,
        linkDisclaimer: '/datenschutzerklaerung',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        hideHeading: false,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Open Sans", Tahoma, Verdana, Segoe, sans-serif, Tahoma, Verdana, Segoe, sans-serif, Tahoma, Verdana, Segoe, sans-serif',
        fontSize: 16,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    'div.UseContact_ContactTextButtonContainer': { // anti-transparency box around contact buttons
                        borderRadius: '3px !important',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'borderRadius': '3px',
                            'fontSize': '22px !important',
                            'padding': '12px 24px',
                            '&:focus': {
                                outline: 'none',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            'borderRadius': '3px',
                            'padding': '12px 24px',
                            '&:focus': {
                                outline: 'none',
                            },
                            '&:hover': {
                                backgroundColor: '#d6e4e6',
                                borderColor: '<colorPrimary>',
                                color: '<colorPrimary>',
                            },
                        },
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            'borderRadius': '3px',
                            'padding': '12px 24px',
                            // contact buttons next to fabs
                            '&[data-testid^="UseContact_ContactTextButton_"]': {
                                fontSize: '18px',
                                padding: '6px 8px',
                            },
                            '&:focus': {
                                outline: 'none',
                            },
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            'borderRadius': '3px',
                            'fontFamily': '<fontFamily>',
                            'fontSize': '16px',
                            '&:focus': {
                                outline: 'none',
                            },
                            '&:hover': {
                                color: '<colorPrimary>',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    color: '#181818',
                },
            },
            button: {
                [getStyleQuery()]: {
                    fontFamily: '"Teko", Sans-serif, ' + '<fontFamily>',
                    fontSize: '22px',
                    letterSpacing: '0.02857em !important',
                    lineHeight: 1,
                },
            },
            caption: {
                [getStyleQuery()]: {
                    letterSpacing: '0.03333em !important',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"Teko", Sans-serif, ' + '<fontFamily>',
                    fontWeight: 500,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"Teko", Sans-serif, ' + '<fontFamily>',
                    fontSize: '30px !important',
                    fontWeight: 500,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"Teko", Sans-serif, ' + '<fontFamily>',
                    fontSize: '28px !important',
                    fontWeight: 500,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"Teko", Sans-serif, ' + '<fontFamily>',
                    fontSize: '26px !important',
                    fontWeight: 500,
                },
            },
            overline: {
                [getStyleQuery()]: {
                    letterSpacing: '0.08333em !important',
                },
            },
        }),
        primaryColor: '#005A65',
    },
};

export default configDippel;

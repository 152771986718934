/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Arne Rief
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configMarnet: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54619,
        enable: true,
        projectName: 'Autohaus Marnet',
        searchPath: '/fahrzeugboerse',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            offsetX: 2,
            offsetY: 2,
            position: 'left',
        },
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'km', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'standort', 'km', 'preis', 'rate', 'ez', 'aus'],
        },
        hideHeading: false,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"HelveticaNeueLTStd-Roman", "Helvetica", "Arial", sans-serif',
        fontSize: 16,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                backgroundColor: '#b7002d',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'color': '#fff',
                            '&:hover': {
                                color: '#fff',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'backgroundImage': 'url("https://www.marnet.de/wp-content/uploads/2020/01/marnet_bg.svg")',
                            'backgroundRepeat': 'no-repeat',
                            '&[data-testid="UseContact_ContactButton_tel"] > svg': {
                                color: '#fff',
                            },
                            '& + .MuiButton-root.MuiButton-containedPrimary.MuiButton-sizeSmall.MuiButton-containedSizeSmall.MuiButton-disableElevation': {
                                backgroundImage: 'url("https://www.marnet.de/wp-content/uploads/2020/01/marnet_bg.svg")',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                color: '#fff',
                            },
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CalcFdSummary_Card"]': {
                                backgroundImage: 'url("https://www.marnet.de/wp-content/uploads/2020/01/marnet_bg.svg")',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                            },
                            '& > .MuiCardHeader-root > .MuiCardHeader-content > .MuiTypography-root.MuiTypography-h6.MuiCardHeader-title': {
                                color: '#fff',
                            },
                            '& .MuiCardHeader-root + .MuiStack-root .MuiTypography-root.MuiTypography-h5': {
                                color: '#fff',
                            },
                            '& .MuiCardHeader-root + .MuiStack-root .MuiTypography-root.MuiTypography-h6': {
                                color: '#fff',
                            },
                            '.MuiCardHeader-root + .MuiStack-root .MuiTypography-root.MuiTypography-subtitle2': {
                                color: '#fff',
                            },
                            '.MuiCardHeader-root + .MuiStack-root .MuiTypography-root.MuiTypography-caption': {
                                color: '#fff',
                            },
                            '&[role="dialog"]': {
                                zIndex: 10015,
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            // @ts-ignore
            'body2.MuiListItemText-secondary': {
                [getStyleQuery()]: {
                    fontWeight: 600,
                },
            },
            'h3': {
                [getStyleQuery()]: {
                    fontWeight: 600,
                },
            },
            'h5': {
                [getStyleQuery()]: {
                    color: '<colorSecondary>',
                    fontFamily: '"HelveticaNeueLTStd-Th", "Arial", sans-serif',
                    fontWeight: 600,
                },
            },
            'h6': {
                [getStyleQuery()]: {
                    color: '<colorSecondary>',
                    fontFamily: '"HelveticaNeueLTStd-Th", "Arial", sans-serif',
                    fontWeight: 600,
                },
            },
        }),
        primaryColor: '#cc0033',
        secondaryColor: '#868d91',
    },
};
export default configMarnet;
